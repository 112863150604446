import { useDispatch } from 'react-redux'
// actions
import { setRole, setTouched, setTouchedByList, setField, setFields } from '../../../actions/update-record'
// lodash
import get from 'lodash/get'
import keys from 'lodash/keys'
const _ = {
  get,
  keys,
}

const useUpdateForm = () => {
  const dispatch = useDispatch()
  const getValueFromType = (target, type) => {
    let value
    switch (type) {
      case 'radio': {
        if (_.get(target, 'checked')) {
          value = _.get(target, 'value')
        }
        break
      }
      case 'checkbox': {
        value = _.get(target, 'checked', false)
        break
      }
      case 'number': {
        const parsed = parseFloat(_.get(target, 'value'))
        value = isNaN(parsed) ? '' : parsed
        break
      }
      case 'text':
      default: {
        value = _.get(target, 'value')
        break
      }
    }
    return value
  }

  const handleChange = (e) => {
    const target = _.get(e, 'target')
    const field = _.get(target, 'name') || _.get(target, 'id')
    if (!field) {
      console.error('`handleChange` was invoked but no `target.name` or `target.id` was specified. Cannot update the form store. Please check the target element:', target) // eslint-disable-line no-console
      return
    }
    const type = _.get(target, 'type')
    const value = getValueFromType(target, type)
    dispatch(setField({ field, value }))
    dispatch(setTouched(field))
  }

  const handleChangeWithDetail = (getDetailsFn) => (e) => {
    const target = _.get(e, 'target')
    const field = _.get(target, 'name') || _.get(target, 'id')
    if (!field) {
      console.error('`handleChangeWithDetail` was invoked but no `target.name` or `target.id` was specified. Cannot update the form store. Please check the target element:', target) // eslint-disable-line no-console
      return
    }
    const details = getDetailsFn({ target, field })
    const touchedFields = _.keys(details)
    touchedFields.push(field)
    dispatch(setFields(details))
    dispatch(setTouchedByList(touchedFields))
  }

  const handleRoleChange = (e) => {
    const target = _.get(e, 'target')
    if (_.get(target, 'checked')) {
      const value = _.get(target, 'value')
      dispatch(setRole(value))
    }
  }

  return { handleChange, handleChangeWithDetail, handleRoleChange }
}

export default useUpdateForm
