import { Confirm } from './confirm' 
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'

class NavigationPrompt extends React.PureComponent {
  static defaultProps = {
    redirectToAfterConfirm: null,
    navigationActionsToHandle: [ 'POP' ],
  }

  static propTypes = {
    // toPrompt is a function returns boolean value stands for
    // whether to prompt users. 
    toPrompt: PropTypes.func,
    actionBeforeUnmount: PropTypes.func.isRequired,
    // 1. If `redirectToAfterConfirm` is set, 
    // the user will be redirected to `redirectToAfterConfirm` 
    // after clicking the confirm button of navigation prompt.
    // 2. if `redirectToAfterConfirm` is not set (`null`),
    // the redirection will be decided according to history entries.
    redirectToAfterConfirm: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    // history is provided by `BrowserRouter` of react-router-dom
    history: PropTypes.object.isRequired,
    // navigation actions to the current location which should be handled  
    navigationActionsToHandle: PropTypes.arrayOf(PropTypes.oneOf([ 'POP', 'REPLACE', 'PUSH' ])),
  }

  constructor(props) {
    super(props)
    this.state = {
      isConfirmVisible: false,
      nextLocation: null,
    }
    this.promptUserOnPageUnload = this._promptUserOnPageUnload.bind(this)
    this.onConfirm = this._navigateToNextLocation.bind(this)
    this.onCancel = this._onCancel.bind(this)
  }

  componentDidMount() {
    const { redirectToAfterConfirm, navigationActionsToHandle } = this.props
    this.unblock = this.props.history.block((nextLocation, action) => {
      if (navigationActionsToHandle.includes(action)) {
        if (typeof this.props.toPrompt === 'function') {
          const showPrompt = this.props.toPrompt()
          if (showPrompt) {
            this.setState({
              isConfirmVisible: true, 
              nextLocation: redirectToAfterConfirm ? redirectToAfterConfirm : nextLocation,
            })
          }
          return !showPrompt 
        }
      }
      return true
    })
    window.addEventListener('beforeunload', this.promptUserOnPageUnload)
  }

  componentWillUnmount() {
    this.props.actionBeforeUnmount()
    this.unblock = null
    window.removeEventListener('beforeunload', this.promptUserOnPageUnload)
  }

  _promptUserOnPageUnload(event) {
    const { toPrompt } = this.props
    const { isConfirmVisible } = this.state
    const promptUser =  typeof toPrompt === 'function' ? toPrompt() : true
    if (promptUser && !isConfirmVisible) {
      // Cancel the event as stated by the standard.
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = '您的資料尚未送出，確定要離開？'
    }
  }

  _navigateToNextLocation() {
    this.unblock()
    this.props.history.push(this.state.nextLocation)
  }

  _onCancel() {
    this.setState({ 
      isConfirmVisible: false,
      nextLocation: null,
    })
  }

  render() {
    const { isConfirmVisible } = this.state
    return (
      <Confirm
        show={isConfirmVisible}
        handleConfirmed={this.onConfirm}
        handleCanceled={this.onCancel}
        title="確定要離開？"
        message="您的資料尚未送出，確定要離開？"
      />
    )
  }
}

export default withRouter(NavigationPrompt)
