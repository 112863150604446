import React from 'react'
import PropTypes from 'prop-types'
// constants
import siteMap from '../../../constants/site-map'
import routes from '../../../constants/routes'
// enum
import { SigninType } from '../../../enum'
// components
import Auth from '../../auth'

const SignIn = ({
  type = SigninType.LOGIN,
  destination = siteMap.main.url,
}) => {
  const isLoginMode = type === SigninType.LOGIN
  const destinationSearch = `?${new URLSearchParams({ destination }).toString()}`
  const textLinkData = {
    text: isLoginMode ? '我還沒有《報導者》帳號，現在去註冊' : '已經有《報導者》帳號了，我要登入',
    to: isLoginMode ? `/register${destinationSearch}` : `/signin${destinationSearch}`,
  }

  const dashboardProps = { 
    title: isLoginMode ? '登入' : '註冊',
    subtitle: isLoginMode ? [] : [ '免費獲得《報導者》電子報', '儲存喜愛的深度報導' ],
    textLinkData,
  }
  const emailProps = {
    typeWording: isLoginMode ? '登入' : '註冊',
  } 
  const onboardingUrl = `${siteMap.accounts.url}${routes.onboarding}?type=${type}`
  return (
    <Auth
      onboardingUrl={onboardingUrl}
      destination={destination}
      dashboardProps={dashboardProps}
      emailProps={emailProps}
      headerType={Auth.HeaderType.UNIVERSAL}
      withOnboarding={true}
    />
  )
}
SignIn.propTypes = {
  type: PropTypes.oneOf(Object.values(SigninType)),
  destination: PropTypes.string,
}
SignIn.Type = SigninType

export default SignIn
