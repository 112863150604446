import PropTypes from 'prop-types'
import React from 'react'

/*
  TODO: Show notification if any error occurs inside the app.
*/
export default class AppErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error('An error was catched in React App:', error, info) // eslint-disable-line no-console
  }

  render() {
    return this.props.children
  }
}
