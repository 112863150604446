import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// @twreporter
import { H5 } from '@twreporter/react-components/lib/text/headline'
import { P1 } from '@twreporter/react-components/lib/text/paragraph'
import { colorBrand, colorGrayscale } from '@twreporter/core/lib/constants/color'

const ListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-bottom: 32px;
  &:last-child {
    padding-bottom: 0px;
  }
`

const Index = styled(H5)`
  color: ${colorBrand.heavy};
`

const Title = styled(H5)`
  color: ${colorGrayscale.gray800};
  padding-bottom: 8px;
`

const Message = styled(P1)`
  color: ${colorGrayscale.gray800};
`

const List = ({ index, title, message }) => {
  return (
    <ListContainer>
      <Index text={index} />
      <div>
        <Title text={title} />
        <Message text={message} />
      </div>
    </ListContainer>
  )
}

List.propTypes = {
  index: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default List
