import PropTypes from 'prop-types'
import React from 'react'
// lodash
import map from 'lodash/map'

const _ = {
  map, 
}

const nullString = '無'

const StructuredValues = ({ values = [], components }) => {
  const { Title, Label, Value, Item } = components
  return _.map(values, ({ value, label, title, handler }, key) => {
    let titleJSX
    let labelJSX
    let valueJSX
    if (title) {
      titleJSX = (
        <Title>{title}</Title>
      )
    }
    if (label) {
      labelJSX = (
        <Label>{label}</Label>
      )
    }
    if (typeof value !== 'undefined') {
      let processedValue = typeof handler === 'function' ? handler(value) : value
      valueJSX = (
        <Value>{processedValue || nullString}</Value>
      )
    }
    return (
      <Item key={`structured-values-item-${key}`}>
        {titleJSX}
        {labelJSX}
        {valueJSX}
      </Item>
    )
  })
}

StructuredValues.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.bool,
    ]),
    handler: PropTypes.func,
  })),
  components: PropTypes.shape({
    Title: PropTypes.elementType,
    Label: PropTypes.elementType,
    Value: PropTypes.elementType,
    Item: PropTypes.elementType,
  }).isRequired,
}

export default StructuredValues
