import globalEnv from '../global-env'
import siteMap from './site-map'
// @twreporter
import releaseBranchConst from '@twreporter/core/lib/constants/release-branch'

const env = {
  [releaseBranchConst.master]: {
    app: {
      url: 'http://localhost:3000',
    },
  },
  [releaseBranchConst.dev]: {
    app: {
      url: siteMap.support.url,
    },
  },
  [releaseBranchConst.staging]: {
    app: {
      url: siteMap.support.url,
    },
  },
  [releaseBranchConst.release]: {
    app: {
      url: siteMap.support.url,
    },
  },
}[globalEnv.releaseBranch]

const payMethod ={
  creditCard: 'credit_card',
  line: 'line',
}

const cardType = {
  unknown: 'unknown',
  jcb: 'jcb',
  mastercard: 'mastercard',
  visa: 'visa',
}

const merchantID = {
  [releaseBranchConst.master]: {
    [payMethod.creditCard]: 'GlobalTesting_CTBC',
    [payMethod.line]: 'GlobalTesting_LINEPAY',
  },
  [releaseBranchConst.dev]: {
    [payMethod.creditCard]: 'twreporter_CTBC',
    [payMethod.line]: 'twreporter_LINEPAY',
  },
  [releaseBranchConst.test]: {
    [payMethod.creditCard]: 'GlobalTesting_CTBC',
    [payMethod.line]: 'GlobalTesting_LINEPAY',
  },
  [releaseBranchConst.staging]: {
    [payMethod.creditCard]: 'twreporter_CTBC',
    [payMethod.line]: 'twreporter_LINEPAY',
  },
  [releaseBranchConst.release]: {
    [payMethod.creditCard]: 'twreporter_00002',
    [payMethod.line]: 'twreporter_00003',
  },
}[globalEnv.releaseBranch]

export const CARD_ERROR = {
  regrex: /Card error/,
  key: 'CARD_ERROR',
}

export default {
  env,
  apiTimeout: 45000, // ms
  contribute: {
    frequency: {
      yearly: 'yearly',
      monthly: 'monthly',
      oneTime: 'one_time',
    },
    frequencyLabel: {
      yearly: '每年定額',
      monthly: '每月定額',
      oneTime: '單筆贊助',
    },
    amount: {
      lowerLimit: 100,
    },
    currency: {
      twd: 'TWD',
    },
    merchantID,
    currencyStr: {
      ntd: 'NTD',
    },
    errorType: {
      updateContributeRecord: 'update-contribute-record',
      updateContributeRecordPreRequestValidation: 'update-contribute-record-pre-request-validation',
      contribute: 'contribute',
      getContributeRecord: 'get-contribute-record',
    },
    renderedStep: {
      contribute: 'contribute',
      redirectOnContribute: 'redirect-on-contribute',
      initiateUpdateContributeRecordForm: 'initiate-update-contribute-record-form',
      updateContributeRecordForm: 'update-contribute-record-form',
      successMessage: 'success-message',
      errorMessage: 'error-message',
    },
    sendReceipt: {
      no: 'no_receipt',
      monthly: 'paperback_receipt_by_month',
      yearly: 'paperback_receipt_by_year',
      digital: 'digital_receipt_by_year',
    },
    sendReceiptLabel: {
      no: {
        label: '我不需要收據', 
      },
      yes: {
        label: '需要',
      },
      yearly: {
        label: '請一年開立一份',
        notation: '（將於次年四月寄出）',
      },
      monthly: {
        label: '請按月開立',
        notation: '（將於扣款後的兩個月內寄出）',
      },
      paper: {
        label: '紙本收據',
      },
      digital: {
        label: '電子收據',
      },
    },
    role: {
      person: 'person',
      organization: 'organization',
    },
    roleLabel: {
      person: '個人',
      organization: '組織',
    },
    payMethod,
    cardType,
    payMethodLabel: {
      creditCard: '信用卡',
      line: 'Line Pay',
    },
    isAnonymousLabel: {
      true: '我希望完全匿名（例：無名氏）',
      false: '我同意公開部分姓名（例：王Ｏ明）',
    },
    gender: {
      female: 'F',
      male: 'M',
      others: 'X',
      unwilling_to_disclose: 'U', 
      unselected: null,
    },
    genderLabel: {
      female: '女',
      male: '男',
      others: '其他',
      unwilling_to_disclose: '不方便透露', 
    },
    age: {
      'unselected': null,
      'less_than_18': 'less_than_18',
      // keep the value of `19_to_24` as `18_to_24`
      // ref: https://github.com/twreporter/go-api/pull/483#issuecomment-1097683497
      '19_to_24': '18_to_24',
      '25_to_34': '25_to_34',
      '35_to_44': '35_to_44',
      '45_to_54': '45_to_54',
      '55_to_64': '55_to_64',
      'above_65': 'above_65',
    },
    ageLabel: {
      'less_than_18': '18歲以下',
      '19_to_24': '19-24歲',
      '25_to_34': '25-34歲',
      '35_to_44': '35-44歲',
      '45_to_54': '45-54歲',
      '55_to_64': '55-64歲',
      'above_65': '65歲以上',
    },
    readPreference: {
      international: 'international',
      cross_straits: 'cross_straits',
      human_right: 'human_right',
      society: 'society',
      environment: 'environment',
      education: 'education',
      politics: 'politics',
      economy: 'economy',
      culture: 'culture',
      art: 'art',
      life: 'life',
      health: 'health',
      sport: 'sport',
      all: 'all',
    },
    readPreferenceLabel: {
      international: '國際',
      cross_straits: '兩岸',
      human_right: '人權',
      society: '社會',
      environment: '環境',
      education: '教育',
      politics: '政治',
      economy: '經濟',
      culture: '文化',
      art: '藝術',
      life: '生活',
      health: '醫療',
      sport: '體育',
      all: '以上皆為我關心的議題',
    },
  },
  status: {
    transactionStatus: {
      paying: 'paying',
      paid: 'paid',
      fail: 'fail',
    },
    tappayRecordStatus: {
      // 銀行已授權交易，但尚未請款
      auth: 0,

      // 交易完成
      ok: 1,

      // 交易錯誤
      error: -1,
    },
    tappayAPIStatus: {
      success: '0',
    }, 
  },
}
