import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// context
import { AuthContext } from '../../contexts'
// util
import urlUtils from '../../utils/url'
// constant
import colors from '../../constants/colors'
// @twreporter
import { PillButton, LinkButton, InheritLinkButton } from '@twreporter/react-components/lib/button'
import { P1, P2 } from '@twreporter/react-components/lib/text/paragraph'
import { H3 } from '@twreporter/react-components/lib/text/headline'
import { SocialMedia, Letter } from '@twreporter/react-components/lib/icon'
import { colorGrayscale, COLOR_SEMANTIC } from '@twreporter/core/lib/constants/color'

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TitleBox = styled(FlexColumn)`
  margin-bottom: 64px;
  color: ${colorGrayscale.gray800};
`
const Subtitle = styled(FlexColumn)`
  text-align: center;
  margin-top: 8px;
`
const Option = styled.div`
  max-width: 280px;
  width: 100%;
  margin-bottom: 16px;
  &:last-child: margin-bottom: 0;
`
const Button = styled(PillButton)`
  width: auto;
  display: flex;
  justify-content: center;
`
const Facebook = styled(SocialMedia)`
  background-color: ${colors.facebook} !important;
`
const Google = styled(SocialMedia)`
`
const TextLinkBox = styled.div`
  margin-top: 48px;
`
const TextLink = styled(LinkButton)`
  &, &:visited {
    color: ${COLOR_SEMANTIC.info} !important;
  }
  &:hover {
    text-decoration-line: underline !important;
  }
`
const Privacy = styled.div`
  margin-top: 64px;
`
const PrivacyBox = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${colorGrayscale.gray600};
`
const PrivacyLink = styled(InheritLinkButton)`
  &, &:visited {
    color: inherit !important;
  }
`

const SignInDashboard = ({
  title = '',
  subtitle = [],
  showSignInByEmailPage,
  textLinkData = {},
  ...props
}) => {
  const { destination, releaseBranch, withOnboarding, onboardingUrl } = useContext(AuthContext)
  const subtitleJSX = subtitle ? (
    <Subtitle>
      {subtitle.map((text, index) => <P1 key={`sub-${index}`} text={text} />)}
    </Subtitle>
  ) : null
  const textLinkJSX = textLinkData.text ? (
    <TextLinkBox>
      <TextLink text={textLinkData.text} TextComponent={P2} link={{ to: textLinkData.to }} />
    </TextLinkBox>
  ) : null
  const onSSO = (platform) => {
    const oAuthParams = { destination }
    if (withOnboarding) {
      oAuthParams.onboarding = onboardingUrl
    }
    window.location.href = urlUtils.formAPIURL('v2', `/auth/${platform}`, oAuthParams)
  }

  return (
    <FlexColumn {...props}>
      <TitleBox>
        <H3 text={title} />
        {subtitleJSX}
      </TitleBox>
      <Option id="sso-google">
        <Button
          leftIconComponent={<Google mediaType="google" type="raw" releaseBranch={releaseBranch} />}
          text="使用Google帳號"
          size={PillButton.Size.L}
          style={PillButton.Style.LIGHT}
          onClick={() => onSSO('google')}
        />
      </Option>
      <Option id="sso-facebook">
        <Button
          leftIconComponent={<Facebook mediaType="facebook" releaseBranch={releaseBranch} />}
          text="使用Facebook帳號"
          size={PillButton.Size.L}
          style={PillButton.Style.LIGHT}
          onClick={() => onSSO('facebook')}
        />
      </Option>
      <Option id="otp-email">
        <Button
          leftIconComponent={<Letter releaseBranch={releaseBranch} />}
          text="使用電子信箱"
          size={PillButton.Size.L}
          style={PillButton.Style.LIGHT}
          onClick={showSignInByEmailPage}
        />
      </Option>
      {textLinkJSX}
      <Privacy>
        <PrivacyBox>
          <P2 text="完成《報導者》帳號登入及註冊代表你同意" />
        </PrivacyBox>
        <PrivacyBox>
          <P2>
            我們的
            <PrivacyLink text="隱私權政策" type={InheritLinkButton.Type.UNDERLINE} link={{ to: 'https://www.twreporter.org/a/privacy-footer', target: '_blank' }} />
          </P2>
        </PrivacyBox>
      </Privacy>
    </FlexColumn>
  )
}
SignInDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.arrayOf(PropTypes.string),
  showSignInByEmailPage: PropTypes.func.isRequired,
  textLinkData: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
}

export default SignInDashboard
