import axios from 'axios'
import env from '../global-env'
// constants
import supportConst from '../constants/support'
// @twreporter
import requestOrigin from '@twreporter/core/lib/constants/request-origins'

export const logging = async (formData) => {
  const url = `${requestOrigin.forServerSideRendering[env.releaseBranch].support}/log`

  const options = {
    timeout: supportConst.apiTimeout,
  }
  formData.severity = 'ERROR'

  try {
    const res = await axios.post(url, formData, options)
    return res
  } catch (err) {
    /* eslint-disable-next-line no-console */
    console.error('log fail', err)
  }
}

export default logging
