export default {
  mobile: {
    width: 320,
    height: 568,
  },
  tablet: {
    width: 768,
  },
  desktop: {
    width: 1024,
  },
  hd: {
    width: 1440,
    height: 900,
  },
}
