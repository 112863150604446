import React from 'react'
import styled from 'styled-components'

// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { H2, H4 } from '@twreporter/react-components/lib/text/headline'
import { TabletAndAbove, MobileOnly } from '@twreporter/react-components/lib/rwd'
import { colorGrayscale, colorOpacity } from '@twreporter/core/lib/constants/color'

// utils
import { getImageUrl } from '../../../../utils/get-gcs-images'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-image: linear-gradient(${colorOpacity['black_0.2']}, ${colorOpacity['black_0.2']}), url(${getImageUrl('bottom-banner.jpg')});
  background-position: center;
  background-size: cover;
  ${mq.mobileOnly`
    height: 320px;
    padding-right: 24px;
    padding-left: 24px;
  `}
  ${mq.tabletOnly`
    height: 640px;
    padding-right: 32px;
    padding-left: 32px;
  `}
  ${mq.desktopAndAbove`
    height: 800px;
    padding-right: 96px;
    padding-left: 96px;
  `}
`

const TextContainer = styled.div`
  border-left: 2px solid ${colorGrayscale.white};
  padding-left: 24px;
  color: ${colorGrayscale.white};
  text-shadow: 0px 0px 4px ${colorOpacity['black_0.5']};
  ${mq.tabletAndAbove`
    padding-left: 32px;
  `}
`

const BottomBanner = () => {
  return (
    <Container>
      <TextContainer>
        <TabletAndAbove>
          <H2 text={'獨立報導是民主社會的基石，'} />
          <H2 text={'細水長流的贊助能為報導者鋪就一條永續的路，'} />
          <H2 text={'請支持我們用真相開啟台灣社會的多元對話。'} />
        </TabletAndAbove>
        <MobileOnly>
          <H4 text={'獨立報導是民主社會的基石，細水長流的贊助能為報導者鋪就一條永續的路，請支持我們用真相開啟台灣社會的多元對話。'} />
        </MobileOnly>
      </TextContainer>
    </Container>
  )
}

export default BottomBanner
