import globalEnv, { envConsts } from '../global-env'
// @twreporter
import releaseBranchConst from '@twreporter/core/lib/constants/release-branch'

const appConsts = {
  [envConsts.development]: {
    appID: 11327,
    appKey: 'app_whdEWBH8e8Lzy4N6BysVRRMILYORF6UxXbiOFsICkz0J9j1C0JUlCHv1tVJC',
  },
  [envConsts.production]: {
    appID: 12039,
    appKey: 'app_58KKa6birF8aRXO9Dt1B8o9m5YcsdZ0MKga7rHGmOsQK8GNoE9Sma7giLKSC',
  },
}[globalEnv.nodeEnv]

const serverType = {
  [releaseBranchConst.master]: 'sandbox',
  [releaseBranchConst.test]: 'sandbox',
  [releaseBranchConst.dev]: 'sandbox',
  [releaseBranchConst.staging]: 'sandbox',
  [releaseBranchConst.release]: 'production',
}[globalEnv.releaseBranch]

export default {
  ...appConsts,
  serverType,
}
