import globalEnv from '../global-env'
// @twreporter
import releaseBranchConst from '@twreporter/core/lib/constants/release-branch'

export default {
  [releaseBranchConst.master]: 'http://localhost:8080',
  [releaseBranchConst.test]: 'http://localhost:8080',
  [releaseBranchConst.dev]: 'https://staging-go-api.twreporter.org',
  [releaseBranchConst.staging]: 'https://staging-go-api.twreporter.org',
  [releaseBranchConst.release]: 'https://go-api.twreporter.org',
}[globalEnv.releaseBranch]
