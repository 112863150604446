import React from 'react'
import styled from 'styled-components'

// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import { PillButton } from '@twreporter/react-components/lib/button'
import { P1, P2 } from '@twreporter/react-components/lib/text/paragraph'

const Block = styled.div`
  height: ${props => props.$height}px;
`

const Container = styled.div`
  width: 100%;
`

const P2Gray600 = styled(P2)`
  color: ${colorGrayscale.gray600};
`

const P1Gray800 = styled(P1)`
  color: ${colorGrayscale.gray800};
`
const SupportButton = styled(PillButton)`
  width: 100%;
  justify-content: center;
  border-radius: 2px;
`

const PayByFax = () => {
  const globalGivingUrl = 'https://www.globalgiving.org/donate/102665/the-reporter-cultural-foundation'
  const onClickSupport = () => {
    window.open(globalGivingUrl, '_blank')
  }
  return (
    <Container>
      <P2Gray600 text={'合作平台'} weight={P2.Weight.BOLD} />
      <Block $height={16} />
      <P1Gray800 text={'GlobalGiving'} weight={P1.Weight.BOLD} />
      <Block $height={16} />
      <P1Gray800 text={'• 信用卡發卡銀行如非在台灣，建議使用此管道。'} />
      <P1Gray800 text={'• 可獲得抵稅捐款證明之國家：美國、英國。'} />
      <Block $height={24} />
      <SupportButton text={'前往贊助'} size={PillButton.Size.L} onClick={onClickSupport}/>
    </Container>
  )
}

export default PayByFax
