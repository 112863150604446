import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
// env
import env from '../../global-env'
// constants
import mockupScreen from '../../constants/mockup'
import typography from '../../constants/typography'
// components
import bsc from '../basic-styled-components'
import ScreenScrollLock from '../screen-scroll-lock'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { colorGrayscale, colorSupportive } from '@twreporter/core/lib/constants/color'
import { IconButton } from '@twreporter/react-components/lib/button'
import { Cross } from '@twreporter/react-components/lib/icon'
// lodash
import map from 'lodash/map'
const _ = {
  map,
}

const mockup = {
  container: {
    mobile: {
      height: 485,
    },
    hd: {
      height: 539,
      width: 740,
    },
  },
  content: {
    mobile: {
      width: 280,
      height: 376,
    },
    hd: {
      width: 610,
      height: 387,
    },
  },
}

const content = [
  {
    type: 'h2',
    content: [
      '《報導者》使用您個人資料方式',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '本政策涵蓋的內容包括：《報導者》如何處理蒐集或收到的個人資料（包括與您過去使用《報導者》服務相關的資料）。個人資料是指得以識別您的身分且未公開的資料，如姓名、地址、電子郵件地址或電話號碼。',
    ],
  },
  {
    type: 'h2',
    content: [
      '機密性和安全性',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '在您使用《報導者》線上贊助系統，《報導者》不會儲存任何信用卡資料。付款流程採用第三方支付服務提供商TapPay金流交易系統，贊助者刷卡時將透過TapPay於銀行系統中交易。資料傳輸過程採用嚴密的SSL 2048bit加密技術保護 ，TapPay並通過PCI-DSS國際信用卡組織安全稽核，周全保障您的權益。',
    ],
  },
  {
    type: 'h2',
    content: [
      '資料蒐集及使用',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '在您使用《報導者》線上贊助系統，《報導者》將會蒐集您的個人資料。包含詢問您的姓名、電子郵件地址、性別等資料。在您使用某些特定服務時，我們可能還會詢問您的住址、身分證號碼相關資料。您得自由選擇是否提供個人資料給我們，但若特定資料欄位係屬必填欄位者，您若不提供該等資料則無法使用相關的服務。',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '《報導者》也自動接收並記錄您電腦和瀏覽器上的資料，包括IP位址、twreporter cookie中的資料、軟體和硬體屬性以及您瀏覽的網頁紀錄。',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '《報導者》蒐集、處理及利用個人資料之特定目的如下：行銷、客戶管理與服務、資訊通訊服務。我們會將有權存取您個人資料之員工，限於我們合理相信是為了向您提供服務或為了完成工作而需要取得這些資料的員工。',
    ],
  },
  {
    type: 'h2',
    content: [
      '資料分享與揭露',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '除非是為了向您提供您所要求的服務、獲得您的授權，或有以下情形，否則《報導者》不會向任何其他人士或非關係企業提供您的個人資料，或與之分享。',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '我們向已簽署保密協議書之代表《報導者》執行業務，或與我們共事的值得信賴的合作夥伴提供這些資料。這些公司可能會將您的個人資料用於協助《報導者》就我們和行銷夥伴所提供產品及服務的事宜，與您進行溝通。但這些公司無獨立權利分享這些資料。',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '我們對傳票、法院命令或法律程序作出回應、取得或行使法律權利，或對訴訟上之請求提出防禦。',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '我們認為為了調查和防止非法活動、涉嫌詐欺、對人身安全有潛在威脅的狀況、對《報導者》服務條款的違反，或為了對上述情形採取應對措施，或法律另有規定，而有必要分享您的個人資料時。',
    ],
  },
  {
    type: 'h2',
    content: [
      'Cookie',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '《報導者》 會在您的電腦上設定並存取 twreporter cookie 。',
    ],
  },
  {
    type: 'h2',
    content: [
      '隱私權保護政策修訂',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '《報導者》可不時修訂本政策。當我們在個人資料的處理上有重大變更時，會將通知在我們的網站上張貼告示。',
    ],
  },
  {
    type: 'h2',
    content: [
      '本隱私權保護政策只適用於《報導者》',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '本政策不適用於非《報導者》所有或控制的公司，也不適用於非《報導者》僱用或管理之人。',
    ],
  },
  {
    type: 'h2',
    content: [
      '問題和建議',
    ],
  },
  {
    type: 'paragraph',
    content: [
      '如果您有任何問題或建議，請填寫 意見反應表，或透過《報導者》電子信箱 <a href="mailto:events@twreporter.org" target="_blank">events@twreporter.org</a> 與我們聯繫。',
    ],
  },
]

const CrossButton = styled(IconButton)`
  position: absolute;
  right: 35px;
  top: 35px;
  &, & > svg {
    width: 18px;
    height: 18px;
  }
  ${mq.tabletAndBelow`
    right: 25px;
    top: 35px;
    &, & > svg {
      width: 24px;
      height: 24px;
    }
  `}
`

const H1 = styled.h1`
  color: ${colorGrayscale.gray800};
  font-size: 34px;
  margin: 40px auto 26px auto;
  text-align: center;
  line-height: 1;
  ${mq.tabletAndBelow`
    text-align: left;
    font-soze: 28px;
    margin: 25px 0 28px 0;
  `}
`

const H2 = styled.h2`
  color: ${colorGrayscale.gray800};
  font-size: ${typography.font.size.base};
  margin: 3em 0 1em 0;
  &:first-of-type {
    margin-top: 0;
  }
`

const P = styled.p`
  color: ${colorGrayscale.gray800};
  font-size: ${typography.font.size.base};
  margin: 0 0 1em 0;
`

const Link = styled.span`
  color: ${colorSupportive.heavy};
  margin-left: 5px;
  font-weight: ${typography.font.weight.bold};
  cursor: pointer;
`

const Container = styled.div`
  background: ${colorGrayscale.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: ${mockup.container.mobile.height / mockupScreen.mobile.height * 100}%;
  ${mq.desktopAndAbove`
    width: ${mockup.container.hd.width}px;
    height: ${mockup.container.hd.height / mockupScreen.hd.height * 100}%;
    min-height: 400px;
    max-height: ${mockup.container.hd.height};
  `}
  ${mq.tabletAndBelow`
    padding: 0 10px 0 20px;
  `}
`

const Content = styled.div`
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  height: ${mockup.content.mobile.height / mockup.container.mobile.height * 100}%;
  ${mq.tabletAndBelow`
    padding-right: 10px;
  `}
  ${mq.desktopAndAbove`
    height: ${mockup.content.hd.height / mockup.container.hd.height * 100}%;
    width: ${mockup.content.hd.width}px;
    padding-right: 10px;
    transform: translateX(10px);
  `}
`

const contentJSX = _.map(content, (element, i) => {
  const { content, type } = element
  const commonProps = {
    key: `element-${i}`,
  }
  switch (type) {
    case 'paragraph': {
      return (<P dangerouslySetInnerHTML={{ __html: content[0] }} {...commonProps} />)
    }
    case 'h1': {
      return (<H1 {...commonProps} >{content[0]}</H1>)
    }
    case 'h2': {
      return (<H2 {...commonProps} >{content[0]}</H2>)
    }
    default:
      return null
  }
})

const TermsAndConditions = ({ children }) => {
  const [ show, setShow ] = useState(false)
  const open = () => setShow(true)
  const close = () => setShow(false)
  const stopPropagation = (e) => e.stopPropagation()

  return (
    <React.Fragment>
      <Link onClick={open}>{children}</Link>
      <bsc.Dimmer
        $dark
        $show={show}
        onClick={close}
      >
        <Container onClick={stopPropagation}>
          <H1>隱私權保護政策</H1>
          <Content>
            {contentJSX}
          </Content>
          <CrossButton
            iconComponent={<Cross releaseBranch={env.releaseBranch} />}
            theme={IconButton.THEME.normal}
            onClick={close}
          />
        </Container>
      </bsc.Dimmer>
      <ScreenScrollLock lock={show} />
    </React.Fragment>
  )
}
TermsAndConditions.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TermsAndConditions
