import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
// constants
import colors from '../../../constants/colors'
import typography from '../../../constants/typography'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { colorGrayscale, colorBrand, COLOR_SEMANTIC } from '@twreporter/core/lib/constants/color'

const mockup = {
  formGroupWidth: {
    mobile: '100%',
    tablet: '670px',
    desktop: '800px',
  },
  formPadding: {
    mobile: '30px 35px 30px 35px',
    tablet: '79px 30px 40px 30px',
    desktop: '79px 60px 40px 60px',
    hd: '79px 60px 40px 60px',
  },
  containerWidth: {
    mobile: '100%',
    tablet: '620px',
    desktop: '740px',
    hd: '740px',
  },
}

const fieldExpandTransition = css`
  transition: 500ms ease-in-out;
  opacity: ${props => props.$hide ? 0 : 1};
  max-height: ${props => props.$hide ? 0 : 'fit-content'};
  ${props => props.$hide ? `
    pointer-events: none;
  ` : ''}
`

export const FieldContainer = styled.div`
  margin-top: ${props => props.$hide ? 0 : '40px'};
  &:first-of-type {
    margin-top: 0;
  }
  ${fieldExpandTransition}
`

export const NextLevelRadio = styled.div`
  margin-left: 40px;
  ${fieldExpandTransition}
`

export const RequiredSign = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(0, -100%);
  font-size: 16px;
  font-weight: ${typography.font.weight.bold};
  letter-spacing: 0.9px;
  &>span:first-of-type {
    color: ${COLOR_SEMANTIC.danger};
    margin-left: 0.6em;
  }
  &>span:last-of-type {
    color: ${colorGrayscale.black};
    opacity: 0.3;
  }
  ${mq.mobileOnly`
    right: 35px;
  `}
`

export const Fields = styled.div`
  background-color: ${colors.gray50};
  position: relative;
  width: ${mockup.containerWidth.mobile};
  padding: ${mockup.formPadding.mobile};
  margin: 50px auto 0 auto;
  ${mq.mobileOnly`
    margin: 30px auto 0 auto;
  `}
  ${mq.tabletOnly`
    width: ${mockup.containerWidth.tablet};
    padding: ${mockup.formPadding.tablet};
  `}
  ${mq.desktopOnly`
    width: ${mockup.containerWidth.desktop};
    padding: ${mockup.formPadding.desktop};
  `}
  ${mq.hdOnly`
    width: ${mockup.containerWidth.hd};
    padding: ${mockup.formPadding.hd};
  `}
`

export const FormGroup = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
  width: ${mockup.formGroupWidth.desktop};
  ${mq.tabletOnly`
    width: ${mockup.formGroupWidth.tablet};
  `}
  ${mq.mobileOnly`
    width: ${mockup.formGroupWidth.mobile};
  `}
`

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${mq.tabletAndAbove`
    height: 128px;
    border-bottom: solid 1px ${colorGrayscale.black};
  `}
  ${mq.mobileOnly`
    width: calc(100% - 44px);
    margin: 0 22px;
    padding: 20px 0 0 15px;
    ${FormGroup}:first-child & {
      padding: 40px 0 0 15px;
    }
    ${FormGroup}:not(:first-child) & {
      margin-top: 30px;
      border-top: solid 1px ${colorGrayscale.black};
    }
    ${FormGroup}:last-child & {
      padding: 20px 0 20px 15px;
      border-bottom: solid 1px ${colorGrayscale.black};
    }
  `}
`

export const Title = styled.div`
  position: relative;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: 2px;
  text-align: left;
  margin-left: 20px;
  font-family: ${typography.font.fontFamily.title}; 
  font-weight: ${typography.font.weight.bold};
  ${mq.mobileOnly`
    margin-left: 16px;
    font-size: 22px;
  `}
  ${props => (!props.$required ? '' : `&::after { content: '*'; color: ${colorBrand.heavy}; font-size: 16px; position: absolute; top: -.6em; right: -1.2em;}`)}
`

export const Part = styled.div`
  width: 58px;
  height: 58px;
  border: solid 1px ${colorGrayscale.gray900};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-family: ${typography.font.fontFamily.title}; 
  font-weight: ${typography.font.weight.regular};
  ${mq.mobileOnly`
    width: 44px;
    height: 44px;
  `}
`

export const NameWrapper = styled.div`
  ${mq.mobileOnly`
    >* {
      margin-bottom: 15px;
    }
    >*:last-child {
      margin-bottom: 0
    }
  `}
  ${mq.tabletAndAbove`
    display: flex;
    justify-content: space-between;
    >*:first-child {
      width: 228px;
      margin-right: 10px;
    }
    >*:last-child {
      width: 386px;
    }
  `}
`

export const CheckboxContainer = styled.div`
  font-size: 16px;
  span {
    line-height: 23px;
    letter-spacing: 1px;
    font-weight: ${typography.font.weight.bold};
  }
  p {
    line-height: 30px;
    font-weight: ${typography.font.weight.regular};
  }
  &:first-child {
    margin-top: 0;
  }
  ${mq.tabletAndAbove`
    p {
      margin-top: 12px;
      padding-left: 35px;
    }
  `}
  ${mq.mobileOnly`
    margin-top: 30px;
    p {
      margin-top: 12px;
    }
  `}
`

export const ReadPreferenceContainer = styled.div`
  ${mq.tabletAndAbove`
    display: grid;
    grid-template-columns: 21% 21% 21% 37%;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
  `}
  ${mq.tabletOnly`
    grid-template-columns: 20% 20% 20% 40%;
  `}
`

export const PreferenceWrapper = styled.div`
  margin-top: 19px;
`

export const WordsFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const WordsCount = styled.p`
  font-size: 14px;
  font-weight: ${typography.font.weight.bold};
  line-height: 17px;
  letter-spacing: 0.875px;
  color: ${colorGrayscale.gray500};
`

export const OnlyDisplayOnMobile = styled.div` 
  ${mq.tabletAndAbove`
    display: none;
  `}
`

export const OnlyDisplayOnTabletAbove = styled.div` 
  ${mq.mobileOnly`
    display: none;
  `}
`

export const FormTypeOptions = styled.div`
  display: flex;
  ${mq.mobileOnly`
    flex-direction: column;
    margin: 0 22px;
    padding: 30px 0 0 15px;
  `}
  ${mq.tabletAndAbove`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    flex-direction: row;
    * > :first-child {
      margin-right: 40px;
    }
  `}
`

export function Header({ groupIndex = 1, title = '', required = false }) {
  return (
    <HeaderContainer>
      <Part>{groupIndex}</Part>
      <Title $required={required}>{title}</Title>
    </HeaderContainer>
  )
}

Header.propTypes = {
  groupIndex: PropTypes.number,
  title: PropTypes.string,
  required: PropTypes.bool,
}
