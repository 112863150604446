import PropTypes from 'prop-types'
import React from 'react'
// constants
import supportConsts from '../../../../constants/support' 
import colors from '../../../../constants/colors'
// components
import ConfirmWithButton from '../../confirm'
import NextStep from '../../next-step'
// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

export const PayByCreditCardNextStep = ({ disabled, onConfirm }) => {
  const ButtonComponent = (
    <NextStep
      message={
        `使用${supportConsts.contribute.payMethodLabel.creditCard}支付`
      }
      disabled={disabled}
      textAlign="center"
      backgroundColor={colorGrayscale.black}
    />
  )
  return (
    <ConfirmWithButton
      ButtonComponent={ButtonComponent}
      title="您已確認金額與資料無誤，並決定送出？"
      message="按下確定後，即完成信用卡授權，並將立即扣款。若有任何需要修改的資料，請按取消返回。"
      confirmBtnText="確定"
      cancelBtnText="取消"
      handleConfirmed={onConfirm}
      disabled={disabled}
    />  
  )
}

PayByCreditCardNextStep.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export const PayByLinePayNextStep = ({ disabled, onConfirm }) => {
  const ButtonComponent = (
    <NextStep
      message={
        `使用${supportConsts.contribute.payMethodLabel.line}支付`
      }
      disabled={disabled}
      textAlign="center"
      backgroundColor={colors.line}
    />
  )
  return (
    <ConfirmWithButton
      ButtonComponent={ButtonComponent}
      title="您已確認金額與資料無誤，並決定送出？"
      message="按下確定後，頁面將會連結至Line pay付款頁進行支付步驟。若有任何需要修改的資料，請按取消返回。"
      confirmBtnText="確定"
      cancelBtnText="取消"
      handleConfirmed={onConfirm}
      disabled={disabled}
    /> 
  )
}

PayByLinePayNextStep.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
}
