import React from 'react'
import { useSelector } from 'react-redux'
// hooks
import useUpdateForm from './use-update-form'
// utils
import verifySecurityId from '../../../utils/verify-security-id'
import { preparePersonalSubmittingValues } from './prepare-submitting-values'
// constants
import supportConsts from '../../../constants/support'
import taiwanDistricts from './taiwan-districts'
import options from './options'
// components
import Address from './address'
import Checkbox from '../../styled-checkbox'
import CircleCheckbox from './circle-checkbox'
import ConfirmWithButton from '../confirm'
import FieldLabel from './field-label'
import Input from './input'
import InvalidMessage from './invalid-message'
import NextStep from '../next-step'
import RadioGroup from '../radio-group'
import SubmittingValues from './submitting-values'
import Textarea from './textarea'
import {
  CheckboxContainer,
  FieldContainer,
  Fields,
  FormGroup,
  Header,
  NameWrapper,
  OnlyDisplayOnMobile,
  OnlyDisplayOnTabletAbove,
  PreferenceWrapper,
  ReadPreferenceContainer,
  RequiredSign,
  WordsCount,
  WordsFieldWrapper,
  FormTypeOptions,
  NextLevelRadio,
} from './form-components'
// lodash
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import keys from 'lodash/keys'
import map from 'lodash/map'
const _ = {
  forEach,
  get,
  keys,
  map,
}

const wordsCountLimit = 250

const PersonForm = ({ frequency, userData, updateRecord }) => {
  const values = useSelector((state) => _.get(state, 'updateForm.update'))
  const touched = useSelector((state) => _.get(state, 'updateForm.touched'))
  const { periodic, oneTime } = options.receiptOptions
  const isPeriodic = frequency !== supportConsts.contribute.frequency.oneTime
  const sendReceiptOptions = isPeriodic ? periodic : oneTime
  const { handleChange, handleChangeWithDetail, handleRoleChange } = useUpdateForm()

  // Build updates
  const { address, receipt_address } = values

  // read_preference
  const readPreferences = _.keys(values.read_preference)
  if (readPreferences.length > 0) {
    _.map(readPreferences, (key, index) => {
      if (!values.read_preference[key]) {
        readPreferences.splice(index, 1)
      }
    })
  }

  const getAutoFillDetails = () => {
    return ({ target }) => {
      const isChecked = _.get(target, 'checked')
      // to be unchecked
      if (!isChecked) {
        return {
          autofill_with_donor_info: isChecked,
        }
      }
      const receiptHeader = `${_.get(values, 'last_name', '')}${_.get(values, 'first_name', '')}`
      return {
        autofill_with_donor_info: isChecked,
        receipt_header: receiptHeader,
        receipt_email: userData.email,
        receipt_address: {
          country: _.get(values, 'address.country'),
          state: _.get(values, 'address.state'),
          city: _.get(values, 'address.city'),
          zipCode: _.get(values, 'address.zip_code'),
          elseString: _.get(values, 'address.elseString'),
        },
      }
    }
  }

  const getPreferenceDetails = () => {
    return ({ target, field }) => {
      const consts = supportConsts.contribute.readPreference
      const isChecked = _.get(target, 'checked')
      const name = field.split('.')[1]
      let readPreference = {}
      switch (name) {
        case consts.all: {
          // when target name 'read_preference.all' is checked/unchecked,
          // all of the other preferences should be checked/unchecked
          // at the same time
          _.forEach(consts, (value, key) => {
            readPreference[key] = isChecked
          })
          break
        }
        default: {
          // when any of read preferences is unchecked,
          // the target name 'read_preference.all' should be unchecked
          // at the same time
          if (!isChecked) {
            readPreference[consts.all] = false
          }
          readPreference[name] = isChecked
        }
      }
      return {
        'read_preference': readPreference,
      }
    }
  }

  // Receipt fields control
  const sendReceipt = _.get(values, 'send_receipt')
  const isReceiptRequired = sendReceipt !== supportConsts.contribute.sendReceipt.no
  const isReceiptAddressRequired = sendReceipt === supportConsts.contribute.sendReceipt.yearly || sendReceipt === supportConsts.contribute.sendReceipt.monthly
  const isReceiptEmailRequired = sendReceipt === supportConsts.contribute.sendReceipt.digital
  const isReceiptSecurityIdRequired = isReceiptRequired || _.get(values, 'auto_tax_deduction')
  const isReceiptLevel3Required = !isPeriodic && isReceiptRequired && !isReceiptEmailRequired
  const sendReceiptCheckedFunc = (value, level) => {
    if (level === 1) {
      if (value === supportConsts.contribute.sendReceipt.no) {
        return sendReceipt === value
      } else {
        return sendReceipt !== supportConsts.contribute.sendReceipt.no
      }
    } else if (level === 2) {
      if (value === supportConsts.contribute.sendReceipt.digital) {
        return sendReceipt === value
      } else {
        return sendReceipt === supportConsts.contribute.sendReceipt.yearly || sendReceipt === supportConsts.contribute.sendReceipt.monthly
      }
    } else {
      return sendReceipt === value
    }
  }

  // Fields validation:
  let isFormValid = true
  const requiredError = '此為必填項目'
  const formatError = '請檢查格式是否正確'
  let receiptEmailValidateMessage

  // validate receipt header
  // receipt header is a required field only when users need receipts
  // `isReceiptHeaderValid` is for form validity
  // `showReceiptHeaderInvalid` is for showing field invalid status
  // Declare two seperate variables above only when the field could be required.
  let isReceiptHeaderValid = true
  let showReceiptHeaderInvalid = false
  const receiptHeader = _.get(values, 'receipt_header')
  const isReceiptHeaderTouched = _.get(touched, 'receipt_header')
  if (isReceiptSecurityIdRequired && !receiptHeader) {
    isReceiptHeaderValid = false
    if (isReceiptHeaderTouched) {
      showReceiptHeaderInvalid = true
    }
  }
  // validate name
  // name is a required field
  let showFirstnameInvalid = false
  let showLastnameInvalid = false
  const isFirstnameValid = Boolean(_.get(values, 'first_name'))
  const isLastnameValid = Boolean(_.get(values, 'last_name'))
  const isNameTouched = Boolean(_.get(touched, 'first_name')) || Boolean(_.get(touched, 'last_name'))
  showFirstnameInvalid = !isFirstnameValid && isNameTouched
  showLastnameInvalid = !isLastnameValid && isNameTouched
  // validate phone_number
  // phone_number is not a required field, only validate when it is not empty
  let isPhoneValid = true
  const phoneValues = _.get(values, 'phone_number')
  const isPhoneTouched = _.get(touched, 'phone_number')
  if (isPhoneTouched) {
    // The regular expression is used here to validate phone number, for example:
    // 0987654323 -> valid, numbers
    // +886987654 -> valid, the leading character is '+'
    // ++88678987 -> invalid, only one '+' as the leading character is acceptable
    // 876        -> invalid, should more than 6 digits
    // 0225602929 ext.100 -> valid, extension numbers are acceptable after 6 concecutive digits
    // 02-25602929 ext.100 -> valid
    const reg = /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})|^\+?[\d]{6,}/
    if (Boolean(phoneValues) && !reg.test(phoneValues)) {
      isPhoneValid = false
    }
  }

  // validate address when it is required
  // `isAddressValid` is for form validity
  // `showIsAddressInvalid` is for showing field invalid status
  // Declare two seperate variables above only when the field could be required.
  let isAddressValid = true
  let showIsAddressInvalid = false
  if (isReceiptAddressRequired) {
    isAddressValid = Boolean(_.get(values, 'receipt_address.elseString'))
    if (!isAddressValid && _.get(touched, 'receipt_address')) {
      showIsAddressInvalid = true
    }
  }

  // validate receipt security id
  // `isReceiptSecurityIdValid` is for form validity
  // `showReceiptSecurityIdInvalid` is for showing field invalid status
  // Declare two seperate variables above only when the field could be required.
  let isReceiptSecurityIdValid = true
  let showReceiptSecurityIdInvalid = false
  const receiptSecurityId = _.get(values, 'person_receipt_security_id')
  const isReceiptSecurityIdTouched = _.get(touched, 'person_receipt_security_id')
  if (isReceiptSecurityIdRequired && (!receiptSecurityId || !verifySecurityId(receiptSecurityId))) {
    isReceiptSecurityIdValid = false
    if (isReceiptSecurityIdTouched) {
      showReceiptSecurityIdInvalid = true
    }
  }

  // validate receipt email when it is required
  // `isReceiptEmailValid` is for form validity
  // `showIsReceiptEmailInvalid` is for showing field invalid status
  // Declare two seperate variables above only when the field could be required.
  let isReceiptEmailValid = true
  let showIsReceiptEmailInvalid = false
  if (isReceiptEmailRequired) {
    const emailRegrex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    const receiptEmail = _.get(values, 'receipt_email')
    isReceiptEmailValid = emailRegrex.test(receiptEmail)
    if (!isReceiptEmailValid && _.get(touched, 'receipt_email')) {
      showIsReceiptEmailInvalid = true
      receiptEmailValidateMessage = receiptEmail ? formatError : requiredError
    }
  }

  isFormValid = isFormValid &&
                isReceiptHeaderValid &&
                isFirstnameValid &&
                isLastnameValid &&
                isPhoneValid &&
                isAddressValid &&
                isReceiptSecurityIdValid &&
                isReceiptEmailValid

  const renderReadPreferenceCheckboxes = _.map(options.readPreference, (option) => {
    const namespace = 'read_preference'
    const name = `${namespace}.${option.name}`
    return (
      <PreferenceWrapper key={name}>
        <Checkbox
          handleClick={handleChangeWithDetail(getPreferenceDetails())}
          isChecked={_.get(values, name)}
          name={name}
          label={option.label}
        />
      </PreferenceWrapper>
    )
  }
  )

  let wordsCount = 0
  const wordsForTwreporter = _.get(values, 'words_for_twreporter', '')
  if (wordsForTwreporter) {
    wordsCount = wordsForTwreporter.length
  }

  const updates = {
    donor: {
      email: userData.email,
      first_name: values.first_name,
      last_name: values.last_name,
      phone_number: values.phone_number,
      nickname: values.nickname,
      gender: values.gender,
      age_range: values.age,
      read_preference: readPreferences.join(','),
      words_for_twreporter: values.words_for_twreporter,
      address_country: _.get(address, 'country', taiwanDistricts.countries[0]),
      address_state: _.get(address, 'state'),
      address_city: _.get(address, 'city'),
      address_detail: _.get(address, 'elseString'),
      address_zip_code: _.get(address, 'zip_code'),
    },
    send_receipt: values.send_receipt,
    is_anonymous: values.is_anonymous,
    receipt: {
      header: isReceiptSecurityIdRequired ?  values.receipt_header : '',
      security_id: isReceiptSecurityIdRequired ?  values.person_receipt_security_id: '',
      email: userData.email,
      ...(
        sendReceipt === supportConsts.contribute.sendReceipt.monthly || sendReceipt === supportConsts.contribute.sendReceipt.yearly ?
          {
            address_country: _.get(receipt_address, 'country', taiwanDistricts.countries[0]),
            address_state: _.get(receipt_address, 'state'),
            address_city: _.get(receipt_address, 'city'),
            address_detail: _.get(receipt_address, 'elseString'),
            address_zip_code: _.get(receipt_address, 'zip_code'),
          } : {}
      ),
    },
    auto_tax_deduction: values.auto_tax_deduction,
  }

  const submit = (e) => {
    if (e && typeof e.preventDefault === 'function') e.preventDefault()
    return updateRecord(updates)
  }


  return (
    <React.Fragment>
      <form onSubmit={submit}>
        <FormGroup>
          <Header groupIndex={1} required title="贊助者身份" />
          <RequiredSign>
            <span>*</span>
            <span>為必填欄位</span>
          </RequiredSign>
          <FormTypeOptions>
            <RadioGroup
              handleChange={handleRoleChange}
              options={options.role}
              name="role"
              checkedValue={supportConsts.contribute.role.person}
            />
          </FormTypeOptions>
        </FormGroup>
        <FormGroup>
          <Header groupIndex={2} required title="贊助者資訊" />
          <Fields>
            <FieldContainer>
              <FieldLabel required >姓名</FieldLabel>
              <NameWrapper>
                <Input
                  handleChange={handleChange}
                  placeholder="姓氏"
                  value={values.last_name}
                  name="last_name"
                  invalid={showLastnameInvalid}
                  maxLength={30}
                />
                <Input
                  handleChange={handleChange}
                  placeholder="名字"
                  value={values.first_name}
                  name="first_name"
                  invalid={showFirstnameInvalid}
                  maxLength={30}
                />
              </NameWrapper>
              <InvalidMessage show={showFirstnameInvalid || showLastnameInvalid} message={requiredError} />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel tooltipMessage="手機填寫範例：0912345678；市話填寫範例：02-23456789 #123">聯絡電話</FieldLabel>
              <Input
                type="tel"
                handleChange={handleChange}
                name="phone_number"
                value={values.phone_number}
                placeholder="市話／手機"
                invalid={!isPhoneValid}
                maxLength={20}
              />
              <InvalidMessage show={!isPhoneValid} message={formatError} />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel tooltipMessage="寄送扣款成功通知信以及其他《報導者》相關訊息時使用。若您希望寄送至其他信箱，請回到捐款步驟第一步，重新操作。">Email</FieldLabel>
              <Input
                disabled
                name="email"
                value={userData.email}
                handleChange={handleChange}
                maxLength={100}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel tooltipMessage="如您居住在台灣以外的國家，請選擇「其他」，並將地址直接填寫於空白欄位。">地址</FieldLabel>
              <Address
                handleChange={handleChange}
                name="address"
                country={_.get(values, [ 'address', 'country' ])}
                state={_.get(values, [ 'address', 'state' ])}
                city={_.get(values, [ 'address', 'city' ])}
                zipCode={_.get(values, [ 'address', 'zip_code' ])}
                elseString={_.get(values, [ 'address', 'elseString' ])}
              />
            </FieldContainer>
            <FieldContainer>
              <CheckboxContainer>
                <OnlyDisplayOnTabletAbove>
                  <Checkbox
                    handleClick={handleChange}
                    isChecked={_.get(values, 'is_anonymous')}
                    name="is_anonymous"
                    label="我不希望將全名公開在《報導者》的捐款徵信名冊"
                  />
                </OnlyDisplayOnTabletAbove>
                <OnlyDisplayOnMobile>
                  <CircleCheckbox
                    handleClick={handleChange}
                    isChecked={_.get(values, 'is_anonymous')}
                    name="is_anonymous"
                    label="我不希望將全名公開在《報導者》的捐款徵信名冊"
                  />
                </OnlyDisplayOnMobile>
                <p>依據《財團法人法》規定，我們須定期在網站公開贊助人姓名與贊助金額。若您勾選此選項，您的姓名將會全部匿名。</p>
              </CheckboxContainer>
            </FieldContainer>
          </Fields>
        </FormGroup>
        <FormGroup>
          <Header groupIndex={3} required title="收據寄送" />
          <Fields>
            <FieldContainer>
              <FieldLabel
                required
              >
                是否需要開立收據？
              </FieldLabel>
              <RadioGroup
                handleChange={handleChange}
                options={sendReceiptOptions.level1}
                name="send_receipt"
                checkedFunc={(value) => sendReceiptCheckedFunc(value, 1)}
              />
              <NextLevelRadio $hide={!isReceiptRequired}>
                <RadioGroup
                  handleChange={handleChange}
                  options={sendReceiptOptions.level2}
                  name="send_receipt"
                  checkedFunc={(value) => sendReceiptCheckedFunc(value, 2)}
                />
                <NextLevelRadio $hide={!isReceiptLevel3Required}>
                  <RadioGroup
                    handleChange={handleChange}
                    options={sendReceiptOptions.level3}
                    name="send_receipt"
                    checkedFunc={(value) => sendReceiptCheckedFunc(value, 3)}
                  />
                </NextLevelRadio>
              </NextLevelRadio>
            </FieldContainer>
            <FieldContainer>
              <CheckboxContainer>
                <OnlyDisplayOnTabletAbove>
                  <Checkbox
                    handleClick={handleChange}
                    isChecked={values.auto_tax_deduction}
                    name="auto_tax_deduction"
                    label="我同意由《報導者》上傳此筆捐款資料至國稅局。"
                  />
                </OnlyDisplayOnTabletAbove>
                <OnlyDisplayOnMobile>
                  <CircleCheckbox
                    handleClick={handleChange}
                    isChecked={values.auto_tax_deduction}
                    name="auto_tax_deduction"
                    label="我同意由《報導者》上傳此筆捐款資料至國稅局。"
                  />
                </OnlyDisplayOnMobile>
              </CheckboxContainer>
            </FieldContainer>
            <FieldContainer $hide={!isReceiptRequired}>
              <CheckboxContainer>
                <OnlyDisplayOnTabletAbove>
                  <Checkbox
                    handleClick={handleChangeWithDetail(getAutoFillDetails())}
                    isChecked={values.autofill_with_donor_info}
                    name="autofill_with_donor_info"
                    label="與贊助者資料相同"
                  />
                </OnlyDisplayOnTabletAbove>
                <OnlyDisplayOnMobile>
                  <CircleCheckbox
                    handleClick={handleChangeWithDetail(getAutoFillDetails())}
                    isChecked={values.autofill_with_donor_info}
                    name="autofill_with_donor_info"
                    label="與贊助者資料相同"
                  />
                </OnlyDisplayOnMobile>
              </CheckboxContainer>
            </FieldContainer>
            <FieldContainer $hide={!isReceiptSecurityIdRequired}>
              <FieldLabel
                tooltipMessage="請填寫真實姓名"
                required={isReceiptSecurityIdRequired}
              >
                收據抬頭
              </FieldLabel>
              <Input
                handleChange={handleChange}
                placeholder="收據抬頭"
                value={values.receipt_header}
                name="receipt_header"
                type="text"
                invalid={showReceiptHeaderInvalid}
                maxLength={128}
              />
              <InvalidMessage show={showReceiptHeaderInvalid} message={requiredError} />
            </FieldContainer>
            <FieldContainer $hide={!isReceiptSecurityIdRequired}>
              <FieldLabel
                required={isReceiptSecurityIdRequired}
                tooltipMessage="填寫後，您將同意由《報導者》上傳此筆捐款資料至國稅局。日後當您以「列舉扣除額」方式申報所得稅，下載扣除額資料時，贊助金額會一併帶入，不需再檢附紙本收據，方便您快速完成報稅作業。"
              >
                身分證字號
              </FieldLabel>
              <Input
                handleChange={handleChange}
                name="person_receipt_security_id"
                value={values.person_receipt_security_id}
                placeholder="A123456789"
                invalid={showReceiptSecurityIdInvalid}
                maxLength={10}
              />
              <InvalidMessage show={showReceiptSecurityIdInvalid} message={formatError} />
            </FieldContainer>
            <FieldContainer $hide={!isReceiptAddressRequired}>
              <FieldLabel required={isReceiptAddressRequired} tooltipMessage="如居住台灣以外國家，請將地址直接填寫於空白欄位">地址</FieldLabel>
              <Address
                handleChange={handleChange}
                name="receipt_address"
                country={_.get(values, [ 'receipt_address', 'country' ])}
                state={_.get(values, [ 'receipt_address', 'state' ])}
                city={_.get(values, [ 'receipt_address', 'city' ])}
                zipCode={_.get(values, [ 'receipt_address', 'zip_code' ])}
                elseString={_.get(values, [ 'receipt_address', 'elseString' ])}
                invalid={showIsAddressInvalid}
              />
              <InvalidMessage show={showIsAddressInvalid} message={requiredError} />
            </FieldContainer>
            <FieldContainer $hide={!isReceiptEmailRequired}>
              <FieldLabel required={isReceiptEmailRequired} tooltipMessage="Email 需同贊助者資訊，若您希望寄送至其他信箱，請回到捐款步驟第一步，重新操作。">Email</FieldLabel>
              <Input
                disabled
                name="receipt_email"
                value={userData.email}
                handleChange={handleChange}
                maxLength={100}
                placeholder="twreporter@twreporter.org"
                invalid={showIsReceiptEmailInvalid}
              />
              <InvalidMessage show={showIsReceiptEmailInvalid} message={receiptEmailValidateMessage} />
            </FieldContainer>
          </Fields>
        </FormGroup>
        <FormGroup>
          <Header groupIndex={4} title="讓我們更了解您" />
          <Fields>
            <FieldContainer>
              <FieldLabel >希望報導者如何稱呼您</FieldLabel>
              <Input
                handleChange={handleChange}
                placeholder="王小明"
                value={values.nickname}
                name="nickname"
                maxLength={50}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>性別</FieldLabel>
              <RadioGroup
                handleChange={handleChange}
                options={options.gender}
                name="gender"
                checkedValue={values.gender}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>年齡</FieldLabel>
              <RadioGroup
                handleChange={handleChange}
                options={options.age}
                name="age"
                checkedValue={values.age}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>關心的議題</FieldLabel>
              <ReadPreferenceContainer>
                {renderReadPreferenceCheckboxes}
              </ReadPreferenceContainer>
            </FieldContainer>
            <FieldContainer>
              <WordsFieldWrapper>
                <FieldLabel>想對報導者說的話</FieldLabel>
                <WordsCount>{wordsCount}/{wordsCountLimit}</WordsCount>
              </WordsFieldWrapper>
              <Textarea
                value={values.words_for_twreporter}
                name="words_for_twreporter"
                placeholder="歡迎與我們分享您的想法"
                handleChange={handleChange}
                maxLength={wordsCountLimit}
              />
            </FieldContainer>
          </Fields>
        </FormGroup>
      </form>
      <ConfirmWithButton
        ButtonComponent={
          <NextStep
            disabled={!isFormValid}
            message="完成"
            textAlign="center"
          />
        }
        title="您已確認資訊無誤並決定送出？"
        message=""
        confirmBtnText="確定"
        cancelBtnText="取消"
        handleConfirmed={submit}
        disabled={!isFormValid}
      >
        <SubmittingValues
          values={preparePersonalSubmittingValues(updates)}
        />
      </ConfirmWithButton>
    </React.Fragment>
  )
}

export default PersonForm
