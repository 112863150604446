import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components'
// actions
import { resetContributeState, errorStep, contributeApi } from '../../actions/contribute'
// utils
import shouldRedirectToLoginPage from '../../utils/should-redirect-to-login-page'
import urlUtils from '../../utils/url'
// constants
import accountsConsts from '../../constants/accounts'
import actionTypes from '../../constants/action-types'
import httpStatusCode from '../../constants/status-code'
import supportConsts from '../../constants/support'
import wellDefinedPropTypes from '../../constants/prop-types'
// components
import Message from '../../components/support/message-page'
import NavigationPrompt from '../../components/support/navigation-prompt'
import RedirectMessage from '../../components/support/redirect-message'
// @twreporter
import { P2 } from '@twreporter/react-components/lib/text/paragraph'
// lodash
import assign from 'lodash/assign'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
const _ = {
  assign,
  get,
  isEqual,
}

const renderedStepConsts = supportConsts.contribute.renderedStep

const verifyLinePayContributeRecord = (paramsToBeVerified, benchmark = {}) => {
  const { 
    rec_trade_id, 
    bank_transaction_id,
    status,
  } = benchmark
  const isValidRequest = _.isEqual(paramsToBeVerified, {
    rec_trade_id,
    bank_transaction_id,
  })
  if (isValidRequest) {
    if (status === supportConsts.status.transactionStatus.paying) {
      return actionTypes.verifyLinePayContributeRecordUnknown
    }
    return actionTypes.verifyLinePayContributeRecordSuccess
  } else {
    return actionTypes.verifyLinePayContributeRecordFailure
  }
}

const haveFinalTransactionStatus = (tappayRecordStatus) => {
  const isPaid = tappayRecordStatus === supportConsts.status.tappayRecordStatus.auth || tappayRecordStatus === supportConsts.status.tappayRecordStatus.ok
  return isPaid
}

export const loadTheContributeRecordThenVerify = async (store, match, search) => {
  const frequency = _.get(match, 'params.frequency')
  const orderNum = _.get(match, 'params.orderNum')
  const authState = _.get(store.getState(), 'auth', {})
  const { userInfo={} , accessToken='' } = authState
  const userData = _.assign({}, userInfo, { jwt: accessToken })
  const { 
    status: tappayApiStatus,
    rec_trade_id, 
    bank_transaction_id,
  } = search

  /*
   * Check if transaction is successful
   **/
  // Tappay Error Code: https://docs.tappaysdk.com/line-pay/zh/error.html#web-sdk-error-code
  if (tappayApiStatus !== supportConsts.status.tappayAPIStatus.success) {
    store.dispatch(errorStep({
      message: 'contribute by line pay failed, check tappayApiStatus',
      data: { tappayApiStatus, rec_trade_id, bank_transaction_id },
    }))
    throw supportConsts.contribute.errorType.contribute
  }

  try {
    let actionResult, verifyResult, isPaid
    actionResult = await store.dispatch(contributeApi.endpoints.fetchLinePayVerificationInformation.initiate({ userData, orderNum, frequency }))
    if (actionResult.isError) {
      throw supportConsts.contribute.errorType.getContributeRecord
    }

    if (actionResult.isSuccess) {
      const record = _.get(actionResult.data, 'data')
      verifyResult = verifyLinePayContributeRecord({ rec_trade_id: rec_trade_id, bank_transaction_id: bank_transaction_id }, record)
      isPaid = verifyResult === actionTypes.verifyLinePayContributeRecordSuccess
    }
    // Error handling: verification fail
    if (verifyResult === actionTypes.verifyLinePayContributeRecordFailure) {
      throw supportConsts.contribute.errorType.getContributeRecord
    }
    // Fetch the tappay record directly to check the final transaction status.
    if (verifyResult === actionTypes.verifyLinePayContributeRecordUnknown || verifyResult === actionTypes.getLinePayVerificationInformationFailure) {
      const filter = {
        order_number: orderNum,
        rec_trade_id,
        bank_transaction_id,
      }
      actionResult = await store.dispatch(contributeApi.endpoints.fetchTappayRecord.initiate({ userData, filter }))
      // If tappay record is fetched successfully, get the final transaction status from it
      if (actionResult.isSuccess) {
        const recordStatus = _.get(actionResult, 'data.data.trade_records.0.record_status')
        isPaid = haveFinalTransactionStatus(recordStatus)
      }
    }
    // There are only two cases which contributes successfully:
    // 1. The record status is `paying` while the final status get from tappay record is `paid`
    // 2. The record status turns into `paid`
    if (isPaid) {
      // Redirect to updateDonationRecord page after passing the verification
      const redirectTarget = {
        statusCode: httpStatusCode.found, 
        redirectURL: `/contribute/${frequency}/${orderNum}?frequency=${frequency}`,
      }
      return { redirectTarget }
    } else {
      throw supportConsts.contribute.errorType.contribute
    }
  } catch (err) {
    /* eslint no-console:0 */
    console.error('An error was catched on verify-line-pay-callback page:', err)
    return store.dispatch(errorStep(err))
  }
}

const Guide = styled(P2)`
  display: block;
`

const VerifyLinePayCallback = ({ location, history }) => {
  // redux state selector
  const authState = (state) => _.get(state, 'auth')
  const contributeState = (state) => _.get(state, 'contribute')
  const userInfoSelector = createSelector(authState, (auth) => ({
    userInfo: _.get(auth, 'userInfo', {}),
    jwt: _.get(auth, 'accessToken', ''),
  }))
  const contributeDetailSelector = createSelector(contributeState, (contribute) => ({
    renderedStep: _.get(contribute, 'step', ''),
    errorType: _.get(contribute, 'error'),
    isVerified: _.get(contribute, 'isVerified', false),
  }))
  const { userInfo, jwt } = useSelector(userInfoSelector)
  const { renderedStep, errorType, isVerified } = useSelector(contributeDetailSelector)

  const userData = _.assign({}, userInfo, { jwt })
  const toShowRedirectMessage = useMemo(() => shouldRedirectToLoginPage(userData), [ userData ])
  const canUserLeave = useMemo(() => (renderedStep === renderedStepConsts.errorMessage ||
    renderedStep === renderedStepConsts.successMessage ||
    isVerified), [ renderedStep, isVerified ])
  const pathname = useMemo(() => _.get(location, 'pathname', ''))
  const search = useMemo(() => _.get(location, 'search', ''))
  const dispatch = useDispatch()

  const renderErrorContent = () => {
    switch (errorType) {
      case supportConsts.contribute.errorType.contribute: {
        const onButtonClick = () => {
          window.open('/intro', '_self')
        }
        const guide = (
          <Guide>
            <span>很抱歉，您使用報導者贊助系統作業失敗。若再次嘗試後仍無法解決，請</span>
            <a href="mailto:events@twreporter.org">聯絡我們</a>
            <span>為您檢查問題，謝謝。</span>
          </Guide>
        )
        return (
          <Message
            title="贊助失敗"
            guide={guide}
            buttonText="回到贊助頁"
            buttonOnclick={onButtonClick}
          />
        )
      }
      case supportConsts.contribute.errorType.getContributeRecord: {
        const email = _.get(userData, 'email', '')
        const loginHref = `${accountsConsts.env.app.url}?${new URLSearchParams({
          destination: supportConsts.env.app.url + pathname + search,
        }).toString()}`
        const logoutHref = urlUtils.formAPIURL('v2', '/auth/logout', {
          destination: loginHref,
        })
        const onButtonClick = () => {
          window.open(logoutHref, '_self')
        }
        const guide = (
          <Guide>
            <span>{`很抱歉，您所查詢的贊助紀錄不存在，或您目前的登入帳號${ email ? `（${email}）` : ''}並非此筆贊助的贊助者，因此沒有權限讀取，請登出後再嘗試。若再次嘗試仍遇到相同狀況，請`}</span>
            <a href="mailto:events@twreporter.org">聯絡我們</a>
            <span>為您檢查問題，謝謝。</span>
          </Guide>
        )
        return (
          <Message
            title="您無權限讀取贊助紀錄"
            guide={guide}
            buttonText="登出帳號"
            buttonOnclick={onButtonClick}
          />
        )
      }
      default: {
        const onButtonClick = () => {
          window.open('/intro', '_self')
        }
        const guide = (
          <Guide>
            <span>如果您重複遇到這個問題，請過一段時間再重新嘗試連到我們的網站，或可以</span>
            <a href="mailto:events@twreporter.org">聯絡我們</a>
            <span>為您檢查問題，謝謝！</span>
          </Guide>
        )
        return (
          <Message
            title="發生未知的錯誤"
            guide={guide}
            buttonText="回到贊助頁"
            buttonOnclick={onButtonClick}
          />
        )
      }
    }
  }

  const renderPageContent = () => {
    switch(renderedStep) {
      case renderedStepConsts.errorMessage: {
        return renderErrorContent()
      }
      default: {
        return (
          <Message
            style={Message.Style.UNDER_CONSTRUCTION}
            title="交易處理中，請勿關閉視窗⋯⋯"
            guide="成為《報導者》贊助夥伴還差最後一哩路！"
          />
        )
      }
    }
  
  }
  
  const renderRedirectMessage = () => {
    return (
      <RedirectMessage
        history={history}
        toShow={toShowRedirectMessage}
        message="為了後續與您聯繫，請先登入報導者網站。您可以選擇 Facebook、Google 或是電子信箱（擇其一）的方式登入報導者網站。登入資訊僅作為報導者與您聯絡時使用"
        handleCanceled={() => {
          dispatch(errorStep(supportConsts.contribute.errorType.getContributeRecord))
        }}
      />   
    )
  }

  return (
    <React.Fragment>
      {renderRedirectMessage()}
      <NavigationPrompt
        toPrompt={() => !canUserLeave}
        actionBeforeUnmount={() => dispatch(resetContributeState())}
        redirectToAfterConfirm={{
          pathname: '/intro',
          search, 
        }}
      />
      {renderPageContent()}
    </React.Fragment>
  )
}

VerifyLinePayCallback.propTypes = {
  // Props below given by react-router
  location: wellDefinedPropTypes.location.isRequired, 
  // history is provided by `BrowserRouter` of react-router-dom
  history: PropTypes.object.isRequired,
}

export default withRouter(VerifyLinePayCallback)
