import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// @twreporter
import { Arrow } from '@twreporter/react-components/lib/icon'
import { P1 } from '@twreporter/react-components/lib/text/paragraph'
import { colorGrayscale, colorOpacity } from '@twreporter/core/lib/constants/color'
import mq from '@twreporter/core/lib/utils/media-query'

// env
import env from '../../global-env'

const ExpansionPanelContainer = styled.div`
  border-top: ${props => props.$showBorderTop && `1px solid ${colorGrayscale.gray300}`};
  border-bottom: ${props => props.$showBorderBottom && `1px solid ${colorGrayscale.gray300}`};
`

const TitleContainer = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: auto 24px;
  grid-gap: 0px 24px;
  align-items: center;
  cursor: pointer;
  ${mq.mobileOnly`
    padding: 16px 0px;
  `}
  &:hover {
    background-color: ${colorOpacity['black_0.05']};
  }
  svg {
    transform: rotate(${props => props.$rotate});
    transition: all 300ms;
  }
`

const Title = styled.div`
  display: flex;
  max-width: 800px;
  color: ${colorGrayscale.gray800};
`

const TitleNumber = styled(P1)`
  min-width: 24px;
  width: 24px;
  align-items: baseline;
`

const TitleText = styled(P1)`
  align-items: baseline;
`

const ArrowIcon = styled(Arrow)`
  background-color: ${colorGrayscale.gray600};
`

const TextContainer = styled.div`
  max-height: ${props => (props.$isActive ? '100%' : '0')};
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
`

const Text = styled(P1)`
  padding-bottom: 16px;
  max-width: 800px;
  color: ${colorGrayscale.gray800};
  ${mq.tabletAndAbove`
    padding-left: 16px;
    padding-right: 64px;
  `}
  :first-child {
    padding-top: 8px;
  }
  :last-child {
    padding-bottom: 24px;
  }
`

const ExpansionPanel = ({ active = false, title = '', text = '', showBorderTop = false, showBorderBottom = true, index }) => {
  const [ isActive, setIsActive ] = useState(active)
  let textJSX
  if (Array.isArray(text)) {
    textJSX = text.map((paragraph, index) => <Text key={'answer-p-' + index} text={paragraph} />)
  } else if (React.isValidElement(text)) {
    textJSX = <Text>{text}</Text>
  } else {
    textJSX = <Text text={text} />
  }
  return (
    <ExpansionPanelContainer $showBorderTop={showBorderTop} $showBorderBottom={showBorderBottom}>
      <TitleContainer $rotate={isActive ? '-180deg' : '0'} onClick={() => setIsActive(!isActive)}>
        <Title>
          {index && <TitleNumber text={`${index}. `} />}
          <TitleText text={title} />
        </Title>
        <ArrowIcon direction="down" releaseBranch={env.releaseBranch} />
      </TitleContainer>
      <TextContainer $isActive={isActive}>
        {textJSX}
      </TextContainer>
    </ExpansionPanelContainer>
  )
}

ExpansionPanel.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.oneOfType([ PropTypes.string, PropTypes.array, PropTypes.element ]),
  showBorderTop: PropTypes.bool,
  showBorderBottom: PropTypes.bool,
  index: PropTypes.number,
}

export default ExpansionPanel
