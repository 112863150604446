import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// constants
import steps from '../../constants/steps'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { MobileOnly, TabletAndAbove } from '@twreporter/react-components/lib/rwd'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import { fontFamily, fontWeight } from '@twreporter/core/lib/constants/font'

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const StepBlocks = styled.div`
  width: 408px;
  display: flex;
  justify-content: space-between;
  ${mq.mobileOnly`
    width: 264px;
  `}
`

const StepBase = styled.div`
  border: solid 1px ${colorGrayscale.gray400};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StepLarge = styled(StepBase)`
  width: 104px;
  height: 104px;
`

const StepSmall = styled(StepBase)`
  width: 40px;
  height: 40px;
  margin: auto;
  margin-bottom: 8px;
`

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${props => props.$active ? '1' : '0.5'};
`

const StepIndex = styled.p`
  font-family: ${fontFamily.title};
  font-size: 20px;
  color: ${colorGrayscale.gray700};
  ${mq.mobileOnly`
    font-size: 16px;
  `}
`

const Step = styled.p`
  font-size: 16px;
  font-weight: ${fontWeight.normal};
  color: ${colorGrayscale.gray700};
  ${mq.mobileOnly`
    font-size: 14px;
  `}
`

function Steps({ currentStep, ...props }) {
  return (
    <Container {...props}>
      <StepBlocks>
        {
          steps.map((step, stepIndex) => {
            const active = currentStep === stepIndex + 1
            return (
              <StepContent key={`step-${stepIndex}`} $active={active}>
                <TabletAndAbove>
                  <StepLarge>
                    <StepIndex>
                      {stepIndex + 1}
                    </StepIndex>
                    <Step>{step}</Step>
                  </StepLarge>
                </TabletAndAbove>
                <MobileOnly>
                  <StepSmall>
                    <StepIndex>
                      {stepIndex + 1}
                    </StepIndex>
                  </StepSmall>
                  <Step>{step}</Step>
                </MobileOnly>
              </StepContent>
            )
          })
        }
      </StepBlocks>
    </Container>
  )
}

Steps.propTypes = {
  currentStep: PropTypes.number.isRequired,
}

export default Steps
