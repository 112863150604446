import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// constants
import facebookConst from '../../../constants/facebook'
import siteMap from '../../../constants/site-map'
// @twreporter
import { IconButton, PillButton } from '@twreporter/react-components/lib/button'
import { SocialMedia, Copy } from '@twreporter/react-components/lib/icon'
import { P1, P2 } from '@twreporter/react-components/lib/text/paragraph'
import { colorSupportive, colorGrayscale, colorOpacity } from '@twreporter/core/lib/constants/color'
import { BRANCH, BRANCH_PROP_TYPES } from '@twreporter/core/lib/constants/release-branch'
// global var
const url = siteMap.support.url

const SocialMediaBox = styled.div`
  display: flex;
`
const SocialButton = styled(IconButton)`
  border-radius: 50%;
  background-color: ${colorGrayscale.white};
  padding: 8px;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colorOpacity['gray100_0.8']};
  padding: 24px 40px;
`
const Title = styled(P1)`
  color: ${colorSupportive.heavy};
`
const Subtitle = styled(P2)`
  margin: 24px 0;
  color: ${colorGrayscale.gray600};
`

export const Share = ({
  releaseBranch = BRANCH.master,
  toastr,
  ...props
}) => {
  const shareList = [ SocialMedia.MediaType.FACEBOOK, SocialMedia.MediaType.LINE, SocialMedia.MediaType.TWITTER ]
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url)
      toastr({ text: '已複製', timeout: 3000 })
    } catch (err) {
      toastr({ text: '複製失敗', timeout: 3000 })
    }
  }
  const shareTo = (mediaType) => {
    const title = '有你，才有《報導者》——成為贊助夥伴'
    let shareLink
    switch (mediaType) {
      case SocialMedia.MediaType.FACEBOOK:
        shareLink = `https://www.facebook.com/dialog/share?app_id=${facebookConst.appID}&display=popup&href=${encodeURIComponent(url)}`
        break
      case SocialMedia.MediaType.LINE:
        shareLink = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(url)}&text=${title}`
        break
      case SocialMedia.MediaType.TWITTER:
        shareLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`
        break
    }
    window.open(
      shareLink,
      'SharingWindow',
      'width=560,height=560,left=200,top=150,resizable,scrollbars=yes,status=0',
    )
  }

  return (
    <Box {...props}>
      <Title text="壯大非營利媒體" weight={P1.Weight.BOLD} />
      <Title text="你也可以出一分力" weight={P1.Weight.BOLD} />
      <Subtitle text="分享《報導者》贊助頁面，或截圖號召身邊的親友，一起支持獨立媒體走更長遠的路。" />
      <SocialMediaBox>
        {
          shareList.map((mediaType) => (
            <SocialButton
              key={`share-to-${mediaType}`}
              theme={IconButton.THEME.normal}
              type={IconButton.Type.PRIMARY}
              iconComponent={<SocialMedia type={SocialMedia.MASK} mediaType={mediaType} releaseBranch={releaseBranch} />}
              onClick={() => shareTo(mediaType)}
            />
          ))
        }
        <SocialButton
          key={'copy-link'}
          theme={IconButton.THEME.normal}
          type={IconButton.Type.PRIMARY}
          iconComponent={<Copy releaseBranch={releaseBranch} />}
          onClick={copyToClipboard}
        />
      </SocialMediaBox>
    </Box>
  )
}
Share.propTypes = {
  releaseBranch: BRANCH_PROP_TYPES,
  toastr: PropTypes.func.isRequired,
}

const InstallButton = styled(PillButton)`
  cursor: pointer;
`
export const Webapp = () => {
  const onInstall = () => {
    const url = 'https://www.twreporter.org/a/how-to-follow-the-reporter#方法3：安裝Web%20App'
    window.open(url, '_blank')
  }
  return (
    <Box>
      <Title text="安裝 Web App" weight={P1.Weight.BOLD} />
      <Title text="隨手掌握《報導者》新聞" weight={P1.Weight.BOLD} />
      <Subtitle text="不佔容量、不用更新版本，以最輕薄的方式在行動裝置掌握《報導者》深度新聞。" />
      <InstallButton text="安裝教學" theme={PillButton.THEME.normal} style={PillButton.Style.LIGHT} type={PillButton.Type.PRIMARY} size={PillButton.Size.L} onClick={onInstall} />
    </Box>
  )
}
