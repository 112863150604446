import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { colorGrayscale, colorSupportive } from '@twreporter/core/lib/constants/color'

const NotifyBackground = styled.div`
  background-color: ${colorGrayscale.white};
  position: relative;
`

const NotifyContainer = styled.div`
  position: relative;
  color: ${colorGrayscale.gray800};
  font-size: 14px;

  ${mq.mobileOnly`
    padding: 20px 22px 14px 22px;
    display: block;
  `}

  ${mq.tabletOnly`
    padding: 20px 50px 20px 50px;
  `}

  ${mq.desktopAndAbove`
    width: calc(875/1024*100%);
    padding: 20px 0;
    margin: 0 auto;
  `}

  ${mq.hdOnly`
    width: calc(992/1440*100%);
  `}
`

const MegaphoneEmoji = styled.div`
  display: inline-block;

  /* flip the emoji */
  transform: scaleX(-1);
  margin-right: 6px;
  &:before {
    content: '📣';
    line-height: 1.5;
  }
`

const NotifyTitle = styled.div`
  font-size: 16px;
  display: inline-block;
  font-weight: bold;

  ${mq.mobileOnly`
    margin-bottom: 12px;
  `}

  ${mq.tabletOnly`
    margin-bottom: 12px;
  `}

  ${mq.desktopAndAbove`
    font-size: 14px;
  `}
`

const NotifyText = styled.p`
  line-height: 1.43;
  position: relative;
  word-break: break-all;

  ${mq.mobileOnly`
    margin-bottom: 12px;
  `}

  ${mq.tabletOnly`
    margin-left: 15px;
    margin-bottom: 20px;
  `}

  ${mq.desktopAndAbove`
    width: 60%;
  `}
`

const NotifyButton = styled.button`
  background-color: ${colorGrayscale.white};
  border: 1px solid ${colorSupportive.heavy};
  color: ${colorSupportive.heavy};
  cursor: pointer;
  font-size: 14px;
  display: block;

  ${mq.mobileOnly`
    margin-left: auto;
    padding: 10px 20px;
  `}

  ${mq.tabletOnly`
    text-align: center;
    width: calc(514/768*100%);
    padding: 10px 0;
    margin-left: auto;
  `}

  ${mq.desktopAndAbove`
    position: absolute;
    padding: 10px 20px;
    top: 50%;
    right: 60px;
    transform: translateY(-50%);
  `}

  &:hover {
    background-color: ${colorSupportive.heavy};
    color: ${colorGrayscale.white};
    transition: background-color .2s linear;
  }
`

class NotifyBox extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    btText: PropTypes.string,
  }

  static defaultProps = {
    btText: '我知道了',
  }

  constructor(props) {
    super(props)
    this.state = {
      toShowInstruction: true,
    }
    this.denyNotification = this._denyNotification.bind(this)
  }
  
  _denyNotification() {
    this.setState({
      toShowInstruction: false,
    })
  }

  /**
   *  @param {string} title - notify box title
   *  @param {string} desc - notify box description
   *  @param {string} btText - notify box button text
   *  @param {function} btClickCallback - callback function of button click
   *  @return {Object} React node containing notify box view
   */
  _renderNotifyBox({ title, desc, btText, btClickCallback }) {
    return (
      <NotifyBackground>
        <NotifyContainer>
          <MegaphoneEmoji />
          <NotifyTitle>{title}</NotifyTitle>
          <NotifyText>{desc}</NotifyText>
          <NotifyButton onClick={btClickCallback}>{btText}</NotifyButton>
        </NotifyContainer>
      </NotifyBackground>
    )
  }

  render() {
    let boxJSX = null
    const { toShowInstruction } = this.state
    const { title, desc, btText } = this.props

    if (toShowInstruction) {
      boxJSX = this._renderNotifyBox({
        title,
        desc,
        btText,
        btClickCallback: this.denyNotification,
      })
    }

    return boxJSX
  }
}

function MaintenanceNotifyBox({ title = '系統維護公告', desc = '' }) {
  if (title && desc) {
    return (
      <NotifyBox
        title={title}
        desc={desc}
      />
    )  
  }
  return null
}

MaintenanceNotifyBox.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
}

export { MaintenanceNotifyBox }
export default NotifyBox
