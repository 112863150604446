import React, { useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import { Route } from 'react-router-dom'

// @twreporter
import { colorBrand, colorGrayscale } from '@twreporter/core/lib/constants/color'
import { PillButton } from '@twreporter/react-components/lib/button'
import { P2 } from '@twreporter/react-components/lib/text/paragraph'
import { TextField } from '@twreporter/react-components/lib/input'
import { fontWeight, fontFamily } from '@twreporter/core/lib/constants/font'
import { MEMBER_ROLE } from '@twreporter/core/lib/constants/member-role'
import { DONATION_LINK_ANCHOR } from '@twreporter/core/lib/constants/donation-link-anchor'

// utils
import { getImageUrl } from '../../../../utils/get-gcs-images'
import { customizeSmoothScroll } from '../../../../utils/customize-smooth-scroll'

// constants
import { FREQUENCY } from './constants'

// context
import { RoleContext } from '../../../../contexts'

const RecButton = styled(PillButton)`
  justify-content: center;
  border-radius: 2px;
  border-color: ${props => props.$isActive ? colorBrand.heavy : colorGrayscale.gray400};
  color: ${props => props.disabled ? colorGrayscale.gray400 : props => props.$isActive ? colorBrand.heavy : colorGrayscale.gray800};
  background: ${props => props.$isActive && `${colorBrand.main}0d`}; // add 5% opacity
  padding: 8px;
  &:hover {
    ${props => props.disabled 
    ? css`color: ${colorGrayscale.gray400}`
    : css`color: ${colorBrand.heavy}; border-color: ${colorBrand.heavy};`}
  }
`

const FrequencyContainer = styled.div`
  width: 100%;
`

const P2Gray600 = styled(P2)`
  color: ${colorGrayscale.gray600};
  margin-bottom: 16px;
`

const FrequencyButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  margin-bottom: 32px;
`

const FrequencyButton = styled(RecButton)`
  flex: 1 1 50%;
`

const AmountContainer = styled.div``

const AmountTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const CreditCards = styled.div`
  display: flex;
  gap: 4px;
`

const CreditCard = styled.img`
  height: 18px;
`

const AmountButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`

const AmountButton = styled(RecButton)`
  flex: 1 1 33.33%
`

const AmountTextField = styled(TextField)`
  margin-bottom: 48px;
  position: relative;
  [type=number] {
    font-family: ${fontFamily.default};
  }
  pointer-events: ${props => props.disabled ? 'none' : ''};
  &::after {
    content: '${props => props.$frequency === FREQUENCY.monthly ? '元／月' : '元'}';
    position: absolute;
    top: 7px;
    right: 0;
    color: ${colorGrayscale.gray600};
    font-size: 14px;
    line-height: 150%;
    font-weight: ${fontWeight.normal};
    font-family: ${fontFamily.default};
  }
  // hide input number arrow
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`

const SupportButton = styled(PillButton)`
  width: 100%;
  justify-content: center;
  border-radius: 2px;
`

const PayByOnline = () => {
  const [ activeAmountBtnIdx, setActiveAmountBtnIdx ] = useState(-1)
  const [ textFieldState, setTextFieldState ] = useState(TextField.State.DISABLED)
  const [ textFieldMessage, setTextFieldMessage ] = useState('')
  const [ isFirstClick, setIsFirstClick ] = useState(true)
  const { role, tick, amount, setAmount, frequency, setFrequency } = useContext(RoleContext)  
  const MASTERCARD = getImageUrl('MasterCard.svg')
  const JCB = getImageUrl('JCB.svg')
  const VISA = getImageUrl('Visa.svg')
  const LINEPAY = getImageUrl('LINE.svg')
  const [ paymentMethods, setPaymentMethods ] = useState([ MASTERCARD, JCB, VISA ])
  const [ isDisableAmountInteractive, setIsDisableAmountInteractive ] = useState(true)
  
  const buttonAmount = frequency === FREQUENCY.monthly ? [ 500, 1000, 2000 ] : [ 500, 1000, 3000 ]
  const onTextFieldChange = (value) => {
    setAmount(value)
  }

  useEffect(() => {
    setActiveAmountBtnIdx(buttonAmount.indexOf(Number(amount)))
    if (amount < 100) {
      setTextFieldState(TextField.State.ERROR)
      setTextFieldMessage('請輸入至少 100 元')
    } else {
      setTextFieldState(TextField.State.DEFAULT)
      setTextFieldMessage('')
    }
  }, [ amount, frequency ])

  useEffect(() => {
    if (role === MEMBER_ROLE.action_taker) {
      if (!amount || amount < 100 || (frequency === FREQUENCY.monthly && amount >= 500)) {
        setAmount(300)
      }
    }
    if (role === MEMBER_ROLE.trailblazer) {
      if (!amount || amount < 500) {
        setAmount(500)
      }
    }
  }, [ role, tick ])

  useEffect(() => {
    if (!frequency) {
      setIsDisableAmountInteractive(true)
      setTextFieldState(TextField.State.DISABLED)
      setTextFieldMessage('請先選擇每月定額或單筆贊助')
    } else {
      setIsDisableAmountInteractive(false)
      setTextFieldState(TextField.State.DEFAULT)
      if (frequency === FREQUENCY.monthly) {
        setPaymentMethods([ MASTERCARD, JCB, VISA ])
      }
      if (frequency === FREQUENCY.oneTime) {
        setPaymentMethods([ MASTERCARD, JCB, VISA, LINEPAY ])
      }
    }
  }, [ frequency ])

  const handleFrequncyBtnClick = (func = () => {}) => {
    if (window && window.innerWidth >= 1024) {
      if (isFirstClick) {
        setIsFirstClick(false)
        const el = document.getElementById(DONATION_LINK_ANCHOR.plan)
        customizeSmoothScroll(el)
      }
    }
    func()
  }

  const checkIsDisable = (func = () => {}) => {
    if (isDisableAmountInteractive) {
      setTextFieldState(TextField.State.ERROR)
      return
    }
    if (typeof func === 'function') {
      func()
    }
  }

  return (
    <div>
      <FrequencyContainer>
        <P2Gray600 text={'頻率'} weight={P2.Weight.BOLD} />
        <FrequencyButtons>
          <FrequencyButton
            $isActive={frequency === FREQUENCY.monthly}
            text={'每月定額'}
            type={PillButton.Type.SECONDARY}
            style={PillButton.Style.LIGHT}
            onClick={() => handleFrequncyBtnClick(() => setFrequency(FREQUENCY.monthly))}
          />
          <FrequencyButton
            $isActive={frequency === FREQUENCY.oneTime}
            text={'單筆'}
            type={PillButton.Type.SECONDARY}
            style={PillButton.Style.LIGHT}
            onClick={() => handleFrequncyBtnClick(() => setFrequency(FREQUENCY.oneTime))}
          />
        </FrequencyButtons>
      </FrequencyContainer>
      <AmountContainer>
        <AmountTitle>
          <P2Gray600 text={'金額'} weight={P2.Weight.BOLD} />
          <CreditCards>
            {paymentMethods.map((method, idx) => <CreditCard key={idx} src={method} />)}
          </CreditCards>
        </AmountTitle>
        <AmountButtons>
          {buttonAmount.map((value, idx) =>
            <AmountButton
              disabled={isDisableAmountInteractive}
              $isActive={idx === activeAmountBtnIdx}
              key={idx}
              text={`${value}`}
              type={PillButton.Type.SECONDARY}
              style={PillButton.Style.LIGHT}
              onClick={() => checkIsDisable(() => setAmount(value))}
            />
          )}
        </AmountButtons>
        <div onClick={checkIsDisable}>
          <AmountTextField
            value={amount}
            placeholder={'自訂金額'}
            align={TextField.Align.CENTER}
            $frequency={frequency}
            onChange={onTextFieldChange}
            state={textFieldState}
            message={textFieldMessage}
            type="number"
            disabled={isDisableAmountInteractive}
          />
        </div>
      </AmountContainer>
      <Route render={({ history, location }) => {
        const isSupportBtnDisabled = !frequency || amount < 100 || !amount
        const search = new URLSearchParams(location.search)
        search.set('frequency', frequency)
        search.set('amount', amount)

        const handleBtnClick = () => {
          if (!isSupportBtnDisabled) {
            history.push({
              pathname: '/authenticate',
              search: `?${search.toString()}`,
            })
          }
        }

        return (
          <SupportButton size={PillButton.Size.L} disabled={isSupportBtnDisabled} text={'贊助支持'} onClick={handleBtnClick}/>
        )
      }} />
    </div>
  )
}

export default PayByOnline
