import supportConsts from './support'

export default {
  root: '/',
  refreshToken: '/refreshToken',
  logging: '/log',

  // accounts
  signin: '/signin',
  register: '/register',
  onboarding: '/onboarding',

  // contribute
  intro: '/intro',
  authenticate: '/authenticate',
  contribute: '/contribute',
  lineCallback: `/contribute/:payment(${supportConsts.contribute.payMethod.line})/:frequency(${supportConsts.contribute.frequency.oneTime})/:orderNum`,
  updateDonation: `/contribute/:frequency(${supportConsts.contribute.frequency.oneTime}|${supportConsts.contribute.frequency.monthly}|${supportConsts.contribute.frequency.yearly})/:orderNum`,
}
