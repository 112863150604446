import styled, { css } from 'styled-components'
// constants
import typography from '../constants/typography'
import zIndex from '../constants/z-index'
// @twreporter
import mediaQuery from '@twreporter/core/lib/utils/media-query'
import { colorGrayscale, colorOpacity } from '@twreporter/core/lib/constants/color'

const _pesudoSelector = `
  content: '';
  border-bottom: solid 1px ${colorGrayscale.gray300};
  position: absolute;
  width: 45%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

const SeparateLine = styled.div`
  position: relative;
  text-align: center;
  color: ${colorGrayscale.gray300};
  font-size: 12px;
  font-weight: ${typography.font.weight.bold};

  &:before {
    left: 0;
    ${_pesudoSelector}
  }

  &:after {
    right: 0;
    ${_pesudoSelector}
  }
`

const StyledPolyline = styled.polyline`
  fill: none;
  stroke-dasharray: 100;
`

export default {
  CenteredBoxModel: styled.div`
    margin-left: auto;
    margin-right: auto;
  `,
  FloatRightBoxModel: styled.div`
    float: right;
  `,
  FloatLeftBoxModel: styled.div`
    float: left;
  `,
  AbsoluteVerticalCenter: styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  `,
  AbsoluteHorizontalCenter: styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  `,
  AbsoluteCenter: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  MobileDisplayNone: styled.div`
    ${mediaQuery.mobileOnly`
      display: none;
    `}
  `,
  OnlyMobileDisplay: styled.div`
    ${mediaQuery.tabletAndAbove`
      display: none;
    `}
  `,
  StyledPolyline,
  SeparateLine,
  Dimmer: styled.div`
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    ${props => props.$light ? css`
      background: white;
      ${props.$show ? 'opacity: .87;' : 'opacity: 0; visibility: hidden;'}
    ` : ''}
    ${props => props.$dark ? css`
      background: ${colorOpacity['black_0.8']};
      ${props.$show ? 'opacity: 1;' : 'opacity: 0; visibility: hidden;'}
    ` : ''}
    z-index: ${zIndex.modalDimmer};
    transition: opacity 400ms ease;
  `,
}
