import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
// hooks
import useUpdateForm from './use-update-form'
// utils
import { prepareOrganizationSubmittingValues } from './prepare-submitting-values'
// constants
import supportConsts from '../../../constants/support'
import taiwanDistricts from './taiwan-districts'
import options from './options'
// components
import Address from './address'
import Checkbox from '../../styled-checkbox'
import CircleCheckbox from './circle-checkbox'
import ConfirmWithButton from '../confirm'
import FieldLabel from './field-label'
import Input from './input'
import InvalidMessage from './invalid-message'
import NextStep from '../next-step'
import RadioGroup from '../radio-group'
import SubmittingValues from './submitting-values'
import {
  CheckboxContainer,
  FieldContainer,
  Fields,
  FormGroup,
  FormTypeOptions,
  Header,
  NameWrapper,
  OnlyDisplayOnMobile,
  OnlyDisplayOnTabletAbove,
  RequiredSign,
  NextLevelRadio,
} from './form-components'
// lodash
import get from 'lodash/get'
import map from 'lodash/map'
const _ = {
  get,
  map,
}

const OrganizationForm = ({ frequency, userData, updateRecord }) => {
  // redux state
  const updateFormState = (state) => _.get(state, 'updateForm')
  const contributeState = (state) => _.get(state, 'contribute')
  const formSelector = createSelector(updateFormState, (form) => ({
    values: _.get(form, 'update'),
    touched: _.get(form, 'touched'),
  }))
  const contributeSelector = createSelector(contributeState, (contribute) => ({
    isOrganization: !!_.get(contribute, [ 'record', 'cardholder', 'legal_name' ]),
  }))
  const { values, touched } = useSelector(formSelector)
  const { isOrganization } = useSelector(contributeSelector)

  const { periodic, oneTime } = options.receiptOptions
  const isPeriodic = frequency !== supportConsts.contribute.frequency.oneTime
  const sendReceiptOptions = isPeriodic ? periodic : oneTime
  const { handleChange, handleChangeWithDetail, handleRoleChange } = useUpdateForm()
  if (isOrganization) {
    options.role[0].tooltip = (
      <div>
        <p>如需變更為「個人」，</p>
        <p>請來信客服信箱</p>
        <p>events@twreporter.org，</p>
        <p>將有專人為您處理。</p>
      </div>
    )
  }

  // Build updates
  const { address, receipt_address } = values

  const updates = {
    donor: {
      email: userData.email,
      first_name: values.first_name,
      last_name: values.last_name,
      phone_number: values.phone_number,
      legal_name: values.legal_name,
      title: values.title,
      security_id: values.organization_security_id,
      address_country: _.get(address, 'country', taiwanDistricts.countries[0]),
      address_state: _.get(address, 'state'),
      address_city: _.get(address, 'city'),
      address_detail: _.get(address, 'elseString'),
      address_zip_code: _.get(address, 'zip_code'),
    },
    send_receipt: values.send_receipt,
    is_anonymous: values.is_anonymous,
    receipt: {
      header: values.receipt_header,
      security_id: values.organization_receipt_security_id,
      email: userData.email,
      address_country: _.get(receipt_address, 'country', taiwanDistricts.countries[0]),
      address_state: _.get(receipt_address, 'state'),
      address_city: _.get(receipt_address, 'city'),
      address_detail: _.get(receipt_address, 'elseString'),
      address_zip_code: _.get(receipt_address, 'zip_code'),
    },
  }

  const submit = (e) => {
    if (e && typeof e.preventDefault === 'function') e.preventDefault()
    return updateRecord(updates)
  }

  const getAutoFillDetails = () => {
    return ({ target }) => {
      const isChecked = _.get(target, 'checked')
      // to be unchecked
      if (!isChecked) {
        return {
          autofill_with_donor_info: isChecked,
        }
      }
      return {
        autofill_with_donor_info: isChecked,
        // The receipt_header field should be auto filled in by legal_name since it is about the legal name of organization
        // instead of the name of contributor.
        receipt_header: _.get(values, 'legal_name', ''),
        organization_receipt_security_id: _.get(values, 'organization_security_id', ''),
        receipt_address: {
          country: _.get(values, 'address.country'),
          state: _.get(values, 'address.state'),
          city: _.get(values, 'address.city'),
          zipCode: _.get(values, 'address.zip_code'),
          elseString: _.get(values, 'address.elseString'),
        },
      }
    }
  }

  // Receipt fields control
  const sendReceipt = _.get(values, 'send_receipt')
  const isReceiptRequired = sendReceipt !== supportConsts.contribute.sendReceipt.no
  const isReceiptAddressRequired = sendReceipt === supportConsts.contribute.sendReceipt.yearly || sendReceipt === supportConsts.contribute.sendReceipt.monthly
  const isReceiptEmailRequired = sendReceipt === supportConsts.contribute.sendReceipt.digital
  const isReceiptSecurityIdRequired = isReceiptRequired || _.get(values, 'auto_tax_deduction')
  const isReceiptLevel3Required = !isPeriodic && isReceiptRequired && !isReceiptEmailRequired
  const sendReceiptCheckedFunc = (value, level) => {
    if (level === 1) {
      if (value === supportConsts.contribute.sendReceipt.no) {
        return sendReceipt === value
      } else {
        return sendReceipt !== supportConsts.contribute.sendReceipt.no
      }
    } else if (level === 2) {
      if (value === supportConsts.contribute.sendReceipt.digital) {
        return sendReceipt === value
      } else {
        return sendReceipt === supportConsts.contribute.sendReceipt.yearly || sendReceipt === supportConsts.contribute.sendReceipt.monthly
      }
    } else {
      return sendReceipt === value
    }
  }

  // Fields validation:
  let isFormValid = true
  let receiptEmailValidateMessage
  const requiredError = '此為必填項目'
  const formatError = '請檢查格式是否正確'

  // validate receipt header 
  // receipt header is a required field only when users need receipts
  // `isReceiptHeaderValid` is for form validity
  // `showReceiptHeaderInvalid` is for showing field invalid status
  // Declare two seperate variables above only when the field could be required.
  let isReceiptHeaderValid = true
  let showReceiptHeaderInvalid = false
  const receiptHeader = _.get(values, 'receipt_header')
  const isReceiptHeaderTouched = _.get(touched, 'receipt_header')
  if (isReceiptSecurityIdRequired && !receiptHeader) {
    isReceiptHeaderValid = false
    if (isReceiptHeaderTouched) {
      showReceiptHeaderInvalid = true
    }
  }
  // validate legal name
  // name is a required field 
  let showLegalnameInvalid = false
  const legalName = _.get(values, 'legal_name') 
  const isLegalnameValid = Boolean(legalName)
  showLegalnameInvalid = !isLegalnameValid && _.get(touched, 'legal_name')

  // validate name
  // name is a required field
  let showFirstnameInvalid = false 
  let showLastnameInvalid = false 
  const isFirstnameValid = Boolean(_.get(values, 'first_name'))
  const isLastnameValid = Boolean(_.get(values, 'last_name'))
  const isNameTouched = Boolean(_.get(touched, 'first_name')) || Boolean(_.get(touched, 'last_name'))
  showFirstnameInvalid = !isFirstnameValid && isNameTouched
  showLastnameInvalid = !isLastnameValid && isNameTouched

  // validate phone_number
  // phone_number is not a required field, only validate when it is not empty
  let isPhoneValid = true
  const phoneValues = _.get(values, 'phone_number')
  const isPhoneTouched = _.get(touched, 'phone_number')
  if (isPhoneTouched) {
    // The regular expression is used here to validate phone number, for example:
    // 0987654323 -> valid, numbers
    // +886987654 -> valid, the leading character is '+'
    // ++88678987 -> invalid, only one '+' as the leading character is acceptable
    // 876        -> invalid, should more than 6 digits
    // 0225602929 ext.100 -> valid, extension numbers are acceptable after 6 concecutive digits
    // 02-25602929 ext.100 -> valid
    const reg = /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})|^\+?[\d]{6,}/
    if (Boolean(phoneValues) && !reg.test(phoneValues)) {
      isPhoneValid = false
    }
  }

  // validate address when it is required 
  // `isAddressValid` is for form validity
  // `showIsAddressInvalid` is for showing field invalid status
  // Declare two seperate variables above only when the field could be required.
  let isAddressValid = true
  let showIsAddressInvalid = false
  if (isReceiptAddressRequired) {
    isAddressValid = Boolean(_.get(values, 'receipt_address.elseString'))
    if (!isAddressValid && _.get(touched, 'receipt_address')) {
      showIsAddressInvalid = true
    }
  }
 
  // validate receipt security id
  // `isReceiptSecurityIdValid` is for form validity
  // `showReceiptSecurityIdInvalid` is for showing field invalid status
  // Declare two seperate variables above only when the field could be required.
  let isReceiptSecurityIdValid = true
  let showReceiptSecurityIdInvalid = false
  const receiptSecurityId = _.get(values, 'organization_receipt_security_id', '')
  const isReceiptSecurityIdTouched = _.get(touched, 'organization_receipt_security_id')
  if (isReceiptSecurityIdRequired && !receiptSecurityId) {
    isReceiptSecurityIdValid = false
    if (isReceiptSecurityIdTouched) {
      showReceiptSecurityIdInvalid = true
    }
  }

  // validate receipt email when it is required 
  // `isReceiptEmailValid` is for form validity
  // `showIsReceiptEmailInvalid` is for showing field invalid status
  // Declare two seperate variables above only when the field could be required.
  let isReceiptEmailValid = true
  let showIsReceiptEmailInvalid = false
  if (isReceiptEmailRequired) {
    const emailRegrex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    const receiptEmail = _.get(values, 'receipt_email')
    isReceiptEmailValid = emailRegrex.test(receiptEmail)
    if (!isReceiptEmailValid && _.get(touched, 'receipt_email')) {
      showIsReceiptEmailInvalid = true
      receiptEmailValidateMessage = receiptEmail ? '請檢查格式是否正確' : '此為必填項目'
    }
  }
 
  // validate the form
  isFormValid = isFormValid &&
                isLegalnameValid &&
                isReceiptHeaderValid &&
                isFirstnameValid &&
                isLastnameValid &&
                isPhoneValid &&
                isAddressValid &&
                isReceiptSecurityIdValid &&
                isReceiptEmailValid

  return (
    <React.Fragment>
      <form onSubmit={submit}>
        <FormGroup>
          <Header groupIndex={1} required title="贊助者身份" />
          <RequiredSign>
            <span>*</span>
            <span>為必填欄位</span>
          </RequiredSign>
          <FormTypeOptions>
            <RadioGroup
              handleChange={handleRoleChange}
              options={options.role}
              name="role"
              checkedValue={supportConsts.contribute.role.organization}
              canToggle={!isOrganization}
            />
          </FormTypeOptions>
        </FormGroup>
        <FormGroup>
          <Header groupIndex={2} required title="贊助者資訊" />
          <Fields>
            <FieldContainer>
              <FieldLabel required >公司／組織全名</FieldLabel>
              <Input
                handleChange={handleChange}
                placeholder="全名"
                value={values.legal_name}
                name="legal_name"
                invalid={showLegalnameInvalid}
                maxLength={50}
              />
              <InvalidMessage show={showLegalnameInvalid} message={requiredError} />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>
                統一編號
              </FieldLabel>
              <Input
                handleChange={handleChange}
                name="organization_security_id"
                value={values.organization_security_id}
                placeholder="統一編號"
                maxLength={8}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel required >聯絡人姓名</FieldLabel>
              <NameWrapper>
                <Input
                  handleChange={handleChange}
                  placeholder="姓氏"
                  value={values.last_name}
                  name="last_name"
                  invalid={showLastnameInvalid}
                  maxLength={30}
                />
                <Input
                  handleChange={handleChange}
                  placeholder="名字"
                  value={values.first_name}
                  name="first_name"
                  invalid={showFirstnameInvalid}
                  maxLength={30}
                />
              </NameWrapper>
              <InvalidMessage show={showFirstnameInvalid || showLastnameInvalid} message={requiredError} />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>聯絡人職稱</FieldLabel>
              <Input
                handleChange={handleChange}
                placeholder="職稱全名"
                value={values.title}
                name="title"
                maxLength={30}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel tooltipMessage="手機填寫範例：0912345678；市話填寫範例：02-23456789 #123">聯絡電話</FieldLabel>
              <Input
                type="tel"
                handleChange={handleChange}
                name="phone_number"
                value={values.phone_number}
                placeholder="市話／手機"
                invalid={!isPhoneValid}
                maxLength={20}
              />
              <InvalidMessage show={!isPhoneValid} message={formatError} />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel tooltipMessage="寄送扣款成功通知信以及其他《報導者》相關訊息時使用。若您希望寄送至其他信箱，請回到捐款步驟第一步，重新操作。">聯絡人Email</FieldLabel>
              <Input
                disabled
                name="email"
                value={userData.email}
                handleChange={handleChange}
                maxLength={100}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel tooltipMessage="如您居住在台灣以外的國家，請選擇「其他」，並將地址直接填寫於空白欄位。">地址</FieldLabel>
              <Address
                handleChange={handleChange}
                name="address"
                country={_.get(values, 'address.country')}
                state={_.get(values, 'address.state')}
                city={_.get(values, 'address.city')}
                zipCode={_.get(values, 'address.zip_code')}
                elseString={_.get(values, 'address.elseString')}
              />
            </FieldContainer>
            <FieldContainer>
              <CheckboxContainer>
                <OnlyDisplayOnTabletAbove>
                  <Checkbox
                    handleClick={handleChange}
                    isChecked={_.get(values, 'is_anonymous')}
                    name="is_anonymous"
                    label="我不希望將全名公開在《報導者》的捐款徵信名冊"
                  />
                </OnlyDisplayOnTabletAbove>
                <OnlyDisplayOnMobile>
                  <CircleCheckbox
                    handleClick={handleChange}
                    isChecked={_.get(values, 'is_anonymous')}
                    name="is_anonymous"
                    label="我不希望將全名公開在《報導者》的捐款徵信名冊"
                  />
                </OnlyDisplayOnMobile>
                <p>依據《財團法人法》規定，我們須定期在網站公開贊助人姓名與贊助金額。若您勾選此選項，您的姓名將會全部匿名。</p>
              </CheckboxContainer>
            </FieldContainer>
          </Fields>
        </FormGroup>
        <FormGroup>
          <Header groupIndex={3} required title="收據寄送" />
          <Fields>
            <FieldContainer>
              <FieldLabel
                required
              >
                是否需要開立收據？
              </FieldLabel>
              <RadioGroup
                handleChange={handleChange}
                options={sendReceiptOptions.level1}
                name="send_receipt"
                checkedFunc={(value) => sendReceiptCheckedFunc(value, 1)}
              />
              <NextLevelRadio $hide={!isReceiptRequired}>
                <RadioGroup
                  handleChange={handleChange}
                  options={sendReceiptOptions.level2}
                  name="send_receipt"
                  checkedFunc={(value) => sendReceiptCheckedFunc(value, 2)}
                />
                <NextLevelRadio $hide={!isReceiptLevel3Required}>
                  <RadioGroup
                    handleChange={handleChange}
                    options={sendReceiptOptions.level3}
                    name="send_receipt"
                    checkedFunc={(value) => sendReceiptCheckedFunc(value, 3)}
                  />
                </NextLevelRadio>
              </NextLevelRadio>
            </FieldContainer>
            <FieldContainer $hide={!isReceiptRequired}>
              <CheckboxContainer>
                <OnlyDisplayOnTabletAbove>
                  <Checkbox
                    handleClick={handleChangeWithDetail(getAutoFillDetails())}
                    isChecked={values.autofill_with_donor_info}
                    name="autofill_with_donor_info"
                    label="與贊助者資料相同"
                  />
                </OnlyDisplayOnTabletAbove>
                <OnlyDisplayOnMobile>
                  <CircleCheckbox
                    handleClick={handleChangeWithDetail(getAutoFillDetails())}
                    isChecked={values.autofill_with_donor_info}
                    name="autofill_with_donor_info"
                    label="與贊助者資料相同"
                  />
                </OnlyDisplayOnMobile>
              </CheckboxContainer>
            </FieldContainer>
            <FieldContainer $hide={!isReceiptSecurityIdRequired}>
              <FieldLabel 
                tooltipMessage="請填寫真實姓名"
                required={isReceiptSecurityIdRequired}
              >
                收據抬頭
              </FieldLabel>
              <Input
                handleChange={handleChange}
                placeholder="王小明"
                value={values.receipt_header}
                name="receipt_header"
                type="text"
                invalid={showReceiptHeaderInvalid}
                maxLength={128}
              />
              <InvalidMessage show={showReceiptHeaderInvalid} message={requiredError} />
            </FieldContainer>
            <FieldContainer $hide={!isReceiptSecurityIdRequired}>
              <FieldLabel
                required={isReceiptSecurityIdRequired}
                tooltipMessage="填寫後，您將同意由《報導者》上傳此筆捐款資料至國稅局。日後當您以「列舉扣除額」方式申報所得稅，下載扣除額資料時，贊助金額會一併帶入，不需再檢附紙本收據，方便您快速完成報稅作業。"
              >
                統一編號
              </FieldLabel>
              <Input
                handleChange={handleChange}
                name="organization_receipt_security_id"
                value={values.organization_receipt_security_id}
                placeholder="統一編號"
                invalid={showReceiptSecurityIdInvalid}
                maxLength={8}
              />
              <InvalidMessage show={showReceiptSecurityIdInvalid} message={requiredError} />
            </FieldContainer>
            <FieldContainer $hide={!isReceiptAddressRequired}>
              <FieldLabel required={isReceiptAddressRequired} tooltipMessage="如居住台灣以外國家，請將地址直接填寫於空白欄位">地址</FieldLabel>
              <Address
                handleChange={handleChange}
                name="receipt_address"
                country={_.get(values, 'receipt_address.country')}
                state={_.get(values, 'receipt_address.state')}
                city={_.get(values, 'receipt_address.city')}
                zipCode={_.get(values, 'receipt_address.zip_code')}
                elseString={_.get(values, 'receipt_address.elseString')}
                invalid={showIsAddressInvalid}
              />
              <InvalidMessage show={showIsAddressInvalid} message={requiredError} />
            </FieldContainer>
            <FieldContainer $hide={!isReceiptEmailRequired}>
              <FieldLabel required={isReceiptEmailRequired} tooltipMessage="Email 需同贊助者資訊，若您希望寄送至其他信箱，請回到捐款步驟第一步，重新操作。">Email</FieldLabel>
              <Input
                disabled
                name="receipt_email"
                value={userData.email}
                handleChange={handleChange}
                maxLength={100}
                placeholder="twreporter@twreporter.org"
                invalid={showIsReceiptEmailInvalid}
              />
              <InvalidMessage show={showIsReceiptEmailInvalid} message={receiptEmailValidateMessage} />
            </FieldContainer>
          </Fields>
        </FormGroup>
      </form>
      <ConfirmWithButton
        ButtonComponent={
          <NextStep
            disabled={!isFormValid}
            message="完成"
            textAlign="center"
          />
        }
        title="您已確認資訊無誤並決定送出？"
        message=""
        confirmBtnText="確定"
        cancelBtnText="取消"
        handleConfirmed={submit}
        disabled={!isFormValid}
      >
        <SubmittingValues
          values={prepareOrganizationSubmittingValues(updates)}
        />
      </ConfirmWithButton>
    </React.Fragment>
  )
}

export default OrganizationForm 
