import supportConsts from '../../../constants/support'

const contribute = supportConsts.contribute

export const gender = [
  { 
    label: contribute.genderLabel.female, 
    value: contribute.gender.female,
  },
  { 
    label: contribute.genderLabel.male, 
    value: contribute.gender.male,
  },
  { 
    label: contribute.genderLabel.others, 
    value: contribute.gender.others,
  },
  { 
    label: contribute.genderLabel.unwilling_to_disclose, 
    value: contribute.gender.unwilling_to_disclose,
  },
]

export const age = [
  { 
    label: contribute.ageLabel['less_than_18'], 
    value: contribute.age['less_than_18'],
  },
  { 
    label: contribute.ageLabel['19_to_24'], 
    value: contribute.age['19_to_24'],
  },
  { 
    label: contribute.ageLabel['25_to_34'], 
    value: contribute.age['25_to_34'],
  },
  { 
    label: contribute.ageLabel['35_to_44'], 
    value: contribute.age['35_to_44'],
  },
  { 
    label: contribute.ageLabel['45_to_54'], 
    value: contribute.age['45_to_54'],
  },
  { 
    label: contribute.ageLabel['55_to_64'], 
    value: contribute.age['55_to_64'],
  },
  { 
    label: contribute.ageLabel['above_65'], 
    value: contribute.age['above_65'],
  },
]

export const readPreference = [
  {
    name: contribute.readPreference.international,
    label: contribute.readPreferenceLabel.international,
  },
  {
    name: contribute.readPreference.cross_straits,
    label: contribute.readPreferenceLabel.cross_straits,
  },
  {
    name: contribute.readPreference.human_right,
    label: contribute.readPreferenceLabel.human_right,
  },
  {
    name: contribute.readPreference.society,
    label: contribute.readPreferenceLabel.society,
  },
  {
    name: contribute.readPreference.environment,
    label: contribute.readPreferenceLabel.environment,
  },
  {
    name: contribute.readPreference.education,
    label: contribute.readPreferenceLabel.education,
  },
  {
    name: contribute.readPreference.politics,
    label: contribute.readPreferenceLabel.politics,
  },
  {
    name: contribute.readPreference.economy,
    label: contribute.readPreferenceLabel.economy,
  },
  {
    name: contribute.readPreference.culture,
    label: contribute.readPreferenceLabel.culture,
  },
  {
    name: contribute.readPreference.art,
    label: contribute.readPreferenceLabel.art,
  },
  {
    name: contribute.readPreference.life,
    label: contribute.readPreferenceLabel.life,
  },
  {
    name: contribute.readPreference.health,
    label: contribute.readPreferenceLabel.health,
  },
  {
    name: contribute.readPreference.sport,
    label: contribute.readPreferenceLabel.sport,
  },
  {
    name: contribute.readPreference.all,
    label: contribute.readPreferenceLabel.all,
  },
]

const receiptOptions = {
  periodic: {
    level1: [
      { 
        label: supportConsts.contribute.sendReceiptLabel.no.label, 
        value: supportConsts.contribute.sendReceipt.no,
      },
      { 
        label: supportConsts.contribute.sendReceiptLabel.yes.label,
        notation: supportConsts.contribute.sendReceiptLabel.yearly.notation,
        value: supportConsts.contribute.sendReceipt.digital,
      },
    ],
    level2: [
      {
        label: supportConsts.contribute.sendReceiptLabel.digital.label,
        value: supportConsts.contribute.sendReceipt.digital, 
      },
      {
        label: supportConsts.contribute.sendReceiptLabel.paper.label,
        value: supportConsts.contribute.sendReceipt.yearly, 
      },
    ],
  },
  oneTime: {
    level1: [
      { 
        label: supportConsts.contribute.sendReceiptLabel.no.label,
        value: supportConsts.contribute.sendReceipt.no,
      },
      { 
        label: supportConsts.contribute.sendReceiptLabel.yes.label, 
        value: supportConsts.contribute.sendReceipt.digital,
      },
    ],
    level2: [
      {
        label: supportConsts.contribute.sendReceiptLabel.digital.label,
        value: supportConsts.contribute.sendReceipt.digital, 
      },
      {
        label: supportConsts.contribute.sendReceiptLabel.paper.label,
        value: supportConsts.contribute.sendReceipt.yearly, 
      },
    ],
    level3: [
      { 
        label: supportConsts.contribute.sendReceiptLabel.monthly.label, 
        notation: supportConsts.contribute.sendReceiptLabel.monthly.notation, 
        value: supportConsts.contribute.sendReceipt.monthly,
      },
      {
        label: supportConsts.contribute.sendReceiptLabel.yearly.label,
        notation: supportConsts.contribute.sendReceiptLabel.yearly.notation, 
        value: supportConsts.contribute.sendReceipt.yearly, 
      },
    ],
  },
}

const role = [
  {
    label: supportConsts.contribute.roleLabel.person,
    value: supportConsts.contribute.role.person,
  },
  {
    label: supportConsts.contribute.roleLabel.organization,
    value: supportConsts.contribute.role.organization,
  },
]

export default {
  gender,
  age,
  readPreference,
  receiptOptions,
  role,
}
