import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
// @twreporter
import { P1, P2 } from '@twreporter/react-components/lib/text/paragraph'
import { colorGrayscale, colorOpacity } from '@twreporter/core/lib/constants/color'
// lodash
import throttle from 'lodash/throttle'
const _ = {
  throttle,
}

const CardContainer = styled.div`
  cursor: pointer;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(${colorOpacity['black_0.2']}, ${colorOpacity['black_0.2']}), url(${props => props.$bgImgUrl});
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  ${props => props.$cardWidth >= 250 ? `
    padding-left: 24px;
    padding-right: 24px;
  ` : `
    padding-left: 16px;
    padding-right: 16px;
  `}
  &::before { 
    content:"";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    background-size: cover;
    transform-origin: center;
    transition: transform .4s ease-in-out;
  }
  &:hover&::before { 
    transform: scale(1.2);
  }
`


const StyledP1 = styled(P1)`
  position: relative;
  color: ${colorGrayscale.white};
  padding-bottom: 8px;
  text-shadow: 0px 0px 4px ${colorOpacity['black_0.5']};
`

const StyledP2 = styled(P2)`
  position: relative;
  color: ${colorGrayscale.white};
  text-shadow: 0px 0px 4px ${colorOpacity['black_0.5']};
`

const Card = ({ title, content, imageUrl, link }) => {
  const CardRef = useRef(null)
  const [ cardWidth, setCardWidth ] = useState(0)

  useEffect(() => {
    const handleResize = _.throttle(() => {
      const offsetWidth = CardRef.current.offsetWidth
      setCardWidth(offsetWidth)
    }, 150)
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <a href={link} target="_blank">
      <CardContainer $bgImgUrl={imageUrl} ref={CardRef} $cardWidth={cardWidth} >
        <StyledP1 weight={P1.Weight.BOLD} text={title} />
        <StyledP2 text={content} />
      </CardContainer>
    </a>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  imageUrl: PropTypes.string,
  link: PropTypes.string,
}

export default Card
