import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
// actions
import { setFields } from '../../../actions/update-record'
// constants
import taiwanDistricts from './taiwan-districts'
// components
import Input from './input'
import Select from './select'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
// lodash
import get from 'lodash/get'
import map from 'lodash/map'
const _ = {
  get,
  map,
}

// global const
const others = '其他'

const StackWrapper = styled.div`
  margin-bottom: 15px;
  ${mq.mobileOnly`
    >* {
      margin-bottom: 15px;
    }
    >*:last-child {
      margin-bottom: 0
    }
  `}
`

const SelectForAddress = styled.div`
  ${mq.tabletAndAbove`
    display: flex;
    justify-content: space-between;
    >* {
      width: calc(32%);
    }
  `}
`

const ZipCode = styled.div`
  ${mq.tabletAndAbove`
    display: inline-block;
    width: 18%;
    margin-right: 9px;
  `}
`

const AddressDetail = styled.div`
  ${mq.tabletAndAbove`
    display: inline-block;
    width: 80%;
  `}
`

export const countryOptions = _.map(taiwanDistricts.countries, (countryName) => ({
  label: countryName,
  value: countryName,
}))

export const stateOptions = _.map(taiwanDistricts.states, (stateName) => ({
  label: stateName,
  value: stateName,
}))

export const cityOptions = _.map(taiwanDistricts.cities, (citiesInState) => _.map(citiesInState[0], (cityName) => ({
  label: cityName,
  value: cityName,
})))

const Address = ({
  handleChange,
  country = taiwanDistricts.countries[0],
  state = '',
  city = '',
  elseString = '',
  zipCode,
  name,
  disabled = false,
  invalid = false,
}) => {
  const isOtherCountries = country === others

  const generateTwZipcode = () => {
    const stateIndex = taiwanDistricts.states.indexOf(state)
    const cityIndex = _.get(taiwanDistricts.cities, `${stateIndex}.0`, '').indexOf(city)
    if (stateIndex >= 0 && cityIndex >= 0) {
      // If user select a state in Taiwan, use the saved zipcode
      const twZipCode = _.get(taiwanDistricts, [ 'cities', stateIndex, 1, cityIndex ])
      const updateObject = {}
      updateObject[name] = { 'zip_code': twZipCode }
      dispatch(setFields(updateObject))
    }
  }
  useEffect(() => {
    generateTwZipcode()
  }, [])

  const dispatch = useDispatch()

  const handleStateChange = (e) => {
    handleChange(e)
    // set country to Taiwan when a state is selected
    const updateObject = {}
    updateObject[name] = {
      country: taiwanDistricts.countries[0],
      city: '',
      'zip_code': '',
    }
    dispatch(setFields(updateObject))
  }
  
  const handleCountryChange = (e) => {
    handleChange(e)
    const updateObject = {}
    updateObject[name] = {
      state: '',
      city: '',
      'zip_code': '',
    }
    dispatch(setFields(updateObject))
  }

  return (
    <Fragment>
      <StackWrapper>
        <SelectForAddress>
          <Select
            handleChange={handleCountryChange}
            name={`${name}.country`}
            options={countryOptions}
            selectedValue={country}
            placeholder="國家"
            disabled={disabled}
          />
          <Select
            handleChange={handleStateChange}
            name={`${name}.state`}
            options={stateOptions}
            selectedValue={state}
            placeholder="城市"
            disabled={disabled || isOtherCountries}
          />
          <Select
            handleChange={handleChange}
            name={`${name}.city`}
            options={_.get(cityOptions, taiwanDistricts.states.indexOf(state))}
            selectedValue={city}
            placeholder="鄉鎮（市）區"
            disabled={disabled || isOtherCountries}
          />
        </SelectForAddress>
      </StackWrapper>
      <StackWrapper>
        <ZipCode>
          <Input
            handleChange={handleChange}
            name={`${name}.zip_code`}
            value={zipCode}
            placeholder="郵遞區號"
            disabled={disabled || isOtherCountries}
            maxLength={10}
          />
        </ZipCode>
        <AddressDetail>
          <Input
            handleChange={handleChange}
            name={`${name}.elseString`}
            value={elseString}
            placeholder="詳細地址"
            disabled={disabled}
            invalid={invalid}
            maxLength={255}
          />
        </AddressDetail>
      </StackWrapper>
    </Fragment>
  )
}
Address.propTypes = {
  handleChange: PropTypes.func.isRequired,
  country: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  elseString: PropTypes.string,
  zipCode: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default Address
