import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
// constants
import typography from '../../../constants/typography'
import colors from '../../../constants/colors'

const StyledInput = styled.input`
  width: 100%;
  height: 65px;
  font-size: 18px;
  font-weight: ${typography.font.weight.bold};
  background-color: ${props => props.$backgroundColor};
  border: solid 1px ${props => props.$borderColor};
  color: ${props => props.$color};
  padding-left: 17px;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  &:focus {
    outline: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: ${props => props.$placeholderColor};
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`

const Input = ({
  name,
  type = 'text',
  placeholder = '',
  value = '',
  disabled = false,
  invalid = false,
  maxLength = 255,
  handleChange,
}) => {
  const [ isHovered, setIsHovered ] = useState(false)
  const [ isFocused, setIsFocused ] = useState(false)
  const colorSet = colors.input

  const handleMouseOver = () => setIsHovered(true)
  const handleMouseOut = () => setIsHovered(false)
  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  const getCurrentColorSet = () => {
    // CAUTION: The order matters. First matched first returned.
    if(disabled) {
      return colorSet.disabled
    } else if(isFocused) {
      return colorSet.focus
    } else if(isHovered) {
      return colorSet.hover
    } else if(invalid) {
      return colorSet.invalid
    } else if(value) {
      return colorSet.active
    } else {
      return colorSet.default
    }
  }

  const { color, backgroundColor, borderColor, placeholderColor } = getCurrentColorSet()
  return (
    <StyledInput
      name={name}
      placeholder={placeholder}
      value={value}
      type={type}
      disabled={disabled}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      maxLength={maxLength}
      $placeholderColor={placeholderColor}
      $backgroundColor={backgroundColor}
      $borderColor={borderColor}
      $color={color}
    />
  )
}
Input.propTypes = {
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  type: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
}

export default Input
