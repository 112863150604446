import PropTypes from 'prop-types'
import React, { useState } from 'react'
// constants
import accountsConsts from '../../constants/accounts'
import siteMap from '../../constants/site-map'
// components
import { Confirm } from '../../components/support/confirm'
// lodash
import assign from 'lodash/assign'
import get from 'lodash/get'
import map from 'lodash/map'
const _ = {
  assign,
  get,
  map,
}
 
const RedirectMessage = ({
  message = '為了後續與您聯繫，贊助前，請先登入報導者網站。您可以選擇 Facebook、Google 或是電子信箱（擇其一）的方式登入報導者網站。登入資訊僅作為報導者與您聯絡時使用',
  toShow = false,
  history,
  handleCanceled,
  handleConfirmed,
}) => {
  const [ show, setShow ] = useState(toShow)
  const redirectToLoginPage = () => {
    // destination is current URL
    const destination = _.get(window, 'location.href', siteMap.main.url)
    window.location.assign(`${accountsConsts.env.app.url}/signin?${new URLSearchParams({
      destination,
    }).toString()}`)
  }
  const redirectToIntroPage = () => {
    const search = _.get(window, 'location.search', '')

    if (history && typeof history.push === 'function') {
      return history.push({
        pathname: '/intro',
        search,
      })
    }
  } 
  const confirmed = () => {
    if (typeof handleConfirmed === 'function') {
      closeRedirectMessage()
      handleConfirmed()
      return
    }
    redirectToLoginPage()
  }

  const canceled = () => {
    if (typeof handleCanceled === 'function') {
      closeRedirectMessage()
      handleCanceled()
      return
    }
    redirectToIntroPage()
  }

  const closeRedirectMessage = () => {
    setShow(false)
  }

  return (
    <Confirm
      show={show}
      title="登入報導者網站"
      message={message}
      cancelBtnText="返回"
      confirmBtnText="確定"
      handleConfirmed={confirmed}
      handleCanceled={canceled}
    />
  ) 
}
RedirectMessage.propTypes = {
  handleCanceled: PropTypes.func,
  handleConfirmed: PropTypes.func,
  message: PropTypes.string,
  toShow: PropTypes.bool,
  history: PropTypes.object.isRequired,
}

export default RedirectMessage
