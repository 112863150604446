import React from 'react'
import styled from 'styled-components'
// utils
import { getImageUrl } from '../../../../utils/get-gcs-images'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { colorOpacity } from '@twreporter/core/lib/constants/color'

const HeroImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 360px;
  background-image: linear-gradient(180deg, transparent 0%, ${colorOpacity['black_0.2']} 100%), url(${props => props.$bgImgUrl});
  background-position: center;
  background-size: cover;
  ${mq.hdOnly`
    width: 100vw;
  `}
  ${mq.tabletOnly`
    margin-top: 64px;
  `}
  ${mq.mobileOnly`
    height: 240px;
    margin-top: 64px;
  `}
`

const HeroImageTitle = styled.div`
  height: 100%;
  width: 800px;
  background-image: url(${getImageUrl('hero-image-title-Desktop.png')});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  ${mq.tabletOnly`
    width: 480px;
    background-image: url(${getImageUrl('hero-image-title-Tablet.png')});
  `}
  ${mq.mobileOnly`
    width: 240px;
    background-image: url(${getImageUrl('hero-image-title-Mobile.png')});
  `}
`

const HeroImage = () => {
  return (
    <HeroImageContainer $bgImgUrl={getImageUrl('hero-image.png')}>
      <HeroImageTitle role="img" aria-label="請支持獨立媒體到現場帶回真相" />
    </HeroImageContainer>
  )
}

export default HeroImage
