export const PAY_METHODS = {
  online: {
    text: '線上贊助',
    value: 'online',
  },
  atmAndFax: {
    text: '轉帳／傳真',
    value: 'atm-and-fax',
  },
  global: {
    text: '海外捐款',
    value: 'global',
  },
}

export const FREQUENCY = {
  monthly: 'monthly',
  oneTime: 'one_time',
}
