import React, { useState, useEffect, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import errors from '@twreporter/errors'
import log from '../../utils/logging'
import styled from 'styled-components'
// context
import { AuthContext } from '../../contexts'
// actions
import { useSignInByOtpMutation, useResendOtpMutation } from '../../actions/sign-in-by-email'
// hook
import { useCountdown } from '../../hooks'
// @twreporter
import { colorGrayscale, COLOR_SEMANTIC } from '@twreporter/core/lib/constants/color'
import { H3 } from '@twreporter/react-components/lib/text/headline'
import { P1 } from '@twreporter/react-components/lib/text/paragraph'
import { PillButton, LinkButton } from '@twreporter/react-components/lib/button'
import { TextField } from '@twreporter/react-components/lib/input'
import { SnackBar, useSnackBar } from '@twreporter/react-components/lib/snack-bar'
import MeterialSymbol from '@twreporter/react-components/lib/material-icon'
// lodash
import get from 'lodash/get'
const _ = {
  get,
}

const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
`
const InboxIcon = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorGrayscale.white};
  color: ${colorGrayscale.gray600};
`
const Title = styled(H3)`
  color: ${colorGrayscale.gray800};
  margin: 24px 0 8px 0;
`
const SubTitle = styled(P1)`
  color: ${colorGrayscale.gray600};
  text-align: center;
`
const OtpInput = styled(TextField)`
  padding: 0 8px;
  width: 100%;
  margin: 48px 0;
  input {
    font-size: 24px;
    letter-spacing: 6px;
  }
  /* Chrome, Safari, Edge: hide input arrows */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firfox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`
const ConfirmButton = styled(PillButton)`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Resend = styled(LinkButton)`
  margin-top: 24px;
  &, &:visited {
    color: ${COLOR_SEMANTIC.info} !important;
  }
`
const SnackBarContainer = styled.div`
  position: fixed;
  bottom: 8px;
  z-index: 1;
  transition: opacity 100ms ease-in-out;
  opacity: ${props => (props.$showSnackBar ? 1 : 0)};
`

const ErrorType = {
  EXPIRED: 'expired',
  FAIL: 'fail',
}
const getInputState = (errorType) => {
  switch(errorType) {
    case ErrorType.EXPIRED:
      return {
        msg: '驗證碼已過期，請再寄一次驗證碼',
        state: TextField.State.DISABLED,
      }
    case ErrorType.FAIL:
      return {
        msg: '驗證碼錯誤，請重新輸入',
        state: TextField.State.ERROR,
      }
    default:
      return {
        msg: '請在 15 分鐘內輸入',
        state: TextField.State.DEFAULT,
      }
  }
}
export const SignInByOtp = ({
  email = '',
  ...props
}) => {
  useEffect(() => {
    toastr({ text: '已寄送驗證碼，請在 15 分鐘內輸入', timeout: 3000 })
  }, [])
  const { onboardingUrl, destination, withOnboarding, setIsBanned } = useContext(AuthContext)
  const [ signInByOtp, signInResult ] = useSignInByOtpMutation()
  const [ resendOtp, resendResult ] = useResendOtpMutation()
  const isLoading = useMemo(() => signInResult.isLoading || resendResult.isLoading, [ signInResult.isLoading, resendResult.isLoading ])
  const [ isEnableResend, setIsEnableResend ] = useState(true)
  const [ otp, setOtp ] = useState('')
  const [ error, setError ] = useState('')
  useEffect(() => {
    if (signInResult.isError) {
      const err = signInResult.error
      if (err.status === 429) {
        setIsBanned(true)
        return
      }
      setError(_.get(err, 'data.status'))
    }
  }, [ signInResult.isError ])
  useEffect(() => {
    if (resendResult.isError) {
      setError(_.get(resendResult.error, 'data.status'))
      toastr({ text: '出了點小問題，請再試一次', timeout: 3000 })
    }
  }, [ resendResult.isError ])
  useEffect(() => {
    if (signInResult.isSuccess) {
      setError('')
      onSuccess()
    }
  }, [ signInResult.isSuccess ])
  useEffect(() => {
    if (resendResult.isSuccess) {
      setIsEnableResend(false)
      setOtp('')
      setError('')
      toastr({ text: '已寄送驗證碼，請在 15 分鐘內輸入', timeout: 3000 })
      startCount()
    }
  }, [ resendResult.isSuccess ])
  const { toastr, showSnackBar, snackBarText } = useSnackBar()
  const onCountEnd = () => setIsEnableResend(true)
  const { remain, isStartCount, startCount } = useCountdown({ 'seconds': 30, 'minutes': 0 }, 'mm:ss', onCountEnd)
  const { msg, state } = getInputState(error)
  const disabled = error === ErrorType.EXPIRED
  const resendText = `再寄一次驗證碼${isStartCount && !isEnableResend ? ` (${remain})` : ''}`
  const onChange = value => setOtp(value)
  const onInput = e => {
    e.target.value = e.target.value.slice(0, 6)
  }
  const onSuccess = () => {
    let redirectUrl = destination
    if (withOnboarding) {
      try {
        const onboardingWithDestination = new URL(onboardingUrl)
        onboardingWithDestination.searchParams.append('destination', destination)
        redirectUrl = onboardingWithDestination.href
      } catch (_error) {
        const error = errors.helpers.wrap(
          _error,
          'OTPSumbitError',
          'Error to handle `onboardingUrl` prop',
          {
            email,
            onboardingUrl,
            withOnboarding,
            destination,
          }
        )
        const errorMessage = errors.helpers.printAll(error, {
          withStack: true,
          withPayload: true,
        })
        console.log(errorMessage) // eslint-disable-line no-console

        // send error log to server
        log({
          message: errorMessage,
        })
      }
    }
    window.location.href = redirectUrl
  }
  const onSubmit = async () => {
    if (disabled || isLoading) {
      return
    }
    await signInByOtp({ otp, email })
  }
  const onResend = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!isEnableResend) {
      return
    }
    await resendOtp(email)
  }
  return (
    <Container {...props}>
      <InboxIcon>
        <MeterialSymbol fill={false} weight={600} grade={0} size={32} icon="forward_to_inbox" />
      </InboxIcon>
      <Title text="輸入驗證碼" />
      <SubTitle text="已將驗證碼寄到" />
      <SubTitle text={email} />
      <OtpInput
        type="number"
        pattern="\d*"
        align={TextField.Align.CENTER}
        onSubmit={onSubmit}
        onInput={onInput}
        onChange={onChange}
        state={state}
        message={msg}
        value={otp}
        autoFocus
      />
      <ConfirmButton
        type={PillButton.Type.PRIMARY}
        style={PillButton.Style.DARK}
        size={PillButton.Size.L}
        text="確認"
        onClick={onSubmit}
        disabled={disabled}
        loading={isLoading}
      />
      <Resend text={resendText} disabled={!isEnableResend} onClick={onResend}/>
      <SnackBarContainer $showSnackBar={showSnackBar}>
        <SnackBar text={snackBarText} />
      </SnackBarContainer>
    </Container>
  )
}
SignInByOtp.propTypes = {
  email: PropTypes.string.isRequired,
}
SignInByOtp.ErrorType = ErrorType

export default SignInByOtp
