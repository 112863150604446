import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// constants
import typography from '../../../constants/typography'
import colors from '../../../constants/colors'
// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
// lodash
import map from 'lodash/map'
const _ = {
  map,
}

// global var
const selectPlaceholder = ''

const StyledSelect = styled.select`
  color: ${props => (props.$isSelected ? colors.select.active.color : colors.select.default.color)};
  width: 100%;
  height: 65px;
  font-size: 18px;
  font-weight: ${typography.font.weight.bold};
  padding-left: 18px;
  outline: none;
  margin: 0;
  background-color: ${props => (props.$isSelected ? colors.select.active.backgroundColor : colors.select.default.backgroundColor)};
  border: 1px solid ${props => (props.$isSelected ? colors.select.active.borderColor : colors.select.default.borderColor)};
  border-radius: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background-image: url(/asset/select-arrow-down.png);
  background-repeat: no-repeat;
  background-position: right 20px center;
  &:-moz-focusring {
    text-shadow: 0 0 0 ${colorGrayscale.balck};
    color: transparent;
  }
  &:focus {
    color: ${colors.select.focus.color};
  }
`

const Select = ({
  handleChange,
  name,
  options = [],
  placeholder = '請選擇',
  selectedValue = '',
  disabled = false,
}) => {
  const isSelected = selectedValue !== selectPlaceholder
  const placeholderJSX = (
    <option
      key={`${name}-${placeholder}`}
      value=""
    >
      {placeholder}
    </option>
  )
  const optionJSX = _.map(options, ({ value, label }) => (
    <option
      key={`${name}-${value}`}
      value={value}
    >
      {label}
    </option>
  ))
  return (
    <StyledSelect
      onChange={handleChange}
      value={selectedValue}
      name={name}
      default={selectedValue === selectPlaceholder}
      disabled={disabled}
      $isSelected={isSelected}
    >
      {placeholderJSX}
      {optionJSX}
    </StyledSelect> 
  )
}
Select.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  })),
  placeholder: PropTypes.string,
  selectedValue: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  disabled: PropTypes.bool,
}

export default Select
