import { createSlice } from '@reduxjs/toolkit'
// constants
import supportConsts from '../constants/support'
// lodash
import forEach from 'lodash/forEach'
import merge from 'lodash/merge'
import set from 'lodash/set'
const _ = {
  forEach,
  merge,
  set,
}

/*
 *  slices for update donation record page
 */
const formSlice = createSlice({
  name: 'updateForm',
  initialState: {
    update: {},
    touched: {},
    role: supportConsts.contribute.role.person,
  },
  reducers: {
    setRole(state, action) {
      state.role = action.payload
    },
    setField(state, action) {
      const { field, value } = action.payload
      _.set(state.update, field, value)
    },
    setFields(state, action) {
      _.merge(state.update, action.payload)
    },
    setTouched(state, action) {
      const field = action.payload
      _.set(state.touched, field, true)
    },
    setTouchedByList(state, action) {
      const fields = action.payload
      _.forEach(fields, (field) => {
        _.set(state.touched, field, true)
      })
    },
  },
})

export const { setRole, setField, setFields, setTouched, setTouchedByList } = formSlice.actions
export default formSlice.reducer
