import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// env
import env from '../../global-env'
// components
import SimpleHeader from '../simple-header'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import Footer from '@twreporter/react-components/lib/footer'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

const Box = styled.div`
  background-color: ${colorGrayscale.gray100};
`
const Body = styled.div`
  margin-top: ${props => props.$marginTop.desktop}px;
  margin-bottom: ${props => props.$marginBottom.desktop}px;
  ${mq.tabletOnly`
    margin-top: ${props => props.$marginTop.tablet}px;
    margin-bottom: ${props => props.$marginBottom.tablet}px;
  `}
  ${mq.mobileOnly`
    margin-top: ${props => props.$marginTop.mobile}px;
    margin-bottom: ${props => props.$marginBottom.mobile}px;
  `}
`
const FooterBox = styled.div`
  width: 100vw;
`
const defaultMarginTop = { desktop: 48, tablet: 48, mobile: 24 }
const defaultMarginBottom = { desktop: 120, tablet: 120, mobile: 120 }
const Layout = ({ bodyMarginTop = defaultMarginTop, bodyMarginBottom=defaultMarginBottom, children }) => (
  <Box>
    <SimpleHeader />
    <Body $marginTop={bodyMarginTop} $marginBottom={bodyMarginBottom}>
      { children }
    </Body>
    <FooterBox>
      <Footer releaseBranch={env.releaseBranch} />
    </FooterBox>
  </Box>
)
Layout.propTypes = {
  bodyMarginTop: PropTypes.shape({
    desktop: PropTypes.number,
    tablet: PropTypes.number,
    mobile: PropTypes.number,
  }),
  bodyMarginBottom: PropTypes.shape({
    desktop: PropTypes.number,
    tablet: PropTypes.number,
    mobile: PropTypes.number,
  }),
  children: PropTypes.element,
}

export default Layout
