import ClickOutHandler from 'react-onclickout'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
// constants
import typography from '../../constants/typography'
// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

const backgroundColor = colorGrayscale.gray500
const textColor = colorGrayscale.white
const arrowSize = {
  height: 12,
  width: 10,
}

const iconSize = {
  mobile: 21,
  desktop: 18,
}

const MessageBox = styled.div`
  z-index: 5;
  width: 268px;
  top: ${iconSize.desktop + arrowSize.height + 5}px;
  right: -${iconSize.desktop}px; 
  margin-left: -134px;
  padding: 25px 19px;
  border-radius: 5px;
  visibility: ${props => props.$show ? 'visible' : 'hidden'};
  opacity: ${props => props.$show ? 1 : 0};
  position: absolute;
  transition: opacity 300ms ease;
  background: ${backgroundColor};
  color: ${textColor};
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: ${typography.font.weight.regular};
  &::after {
    /* Arrow */
    content: " ";
    position: absolute;
    bottom: 100%;
    right: 22px;
    margin-left: -${arrowSize.width}px;
    border-top: 0;
    border-bottom: ${arrowSize.height}px solid ${backgroundColor};
    border-left: ${arrowSize.width / 2}px solid transparent;
    border-right: ${arrowSize.width / 2}px solid transparent;
  }
`

const Container = styled.div`
  position: relative;
`

const Icon = styled.div`
  width: 18px;
  height: 18px;
  background: ${backgroundColor};
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  &:hover~${MessageBox} {
    visibility: visible;
    opacity: 1;
  }
  &::after {
    content: "?";
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    color: ${textColor};
    font-weight: ${typography.font.weight.normal};
  }
`

const Tooltip = ({
  message = '',
  className,
}) => {
  const [ show, setShow ] = useState(false)
  const open = () => setShow(true)
  const close = () => setShow(false)

  return (
    <Container className={className}>
      <Icon onClick={open} />
      {show ? (
        <ClickOutHandler onClickOut={show && close}>
          <MessageBox $show={show}>{message}</MessageBox>
        </ClickOutHandler>
      ) : (
        <MessageBox $show={show}>{message}</MessageBox>
      )}
    </Container>
  )
}
Tooltip.propTypes = {
  message: PropTypes.oneOf([ PropTypes.string, PropTypes.element ]),
  className: PropTypes.string, // for styled component
}

export default Tooltip
