import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { H3, H5 } from '@twreporter/react-components/lib/text/headline'
import { P2 } from '@twreporter/react-components/lib/text/paragraph'
import { PillButton, LinkButton } from '@twreporter/react-components/lib/button'
import { colorGrayscale, COLOR_SEMANTIC } from '@twreporter/core/lib/constants/color'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mq.mobileOnly`
    margin: 0 24px;
  `}
`
const Title = styled(H3)`
  color: ${colorGrayscale.gray800};
` 
const SubTitle = styled(H5)`
  margin-top: 64px;
  text-align: center;
  color: ${colorGrayscale.gray700};
  ${mq.tabletAndBelow`
    margin-top: 24px;
  `}
`
const Description = styled(P2)`
  text-align: center;
  color: ${colorGrayscale.gray600};
  margin-bottom: 64px;
  ${mq.tabletAndBelow`
    margin-bottom: 24px;
  `}
`
const Confirm = styled(PillButton)`
  width: 280px; 
  display: flex;
  justify-content: center;
`
const SwitchMethod = styled(LinkButton)`
  margin-top: 24px;
  &, &:visited {
    color: ${COLOR_SEMANTIC.info} !important;
  }
`

const defaultFunc = () => {}
const ConfirmEmail = ({ email, onSwitchMethod = defaultFunc, onConfirm = defaultFunc }) => {
  return (
    <Container>
      <Title text="確認您的身分" />
      <SubTitle text={`以 ${email}  贊助`} />
      <Description text="我們將使用您的 email 建立贊助紀錄，建議您使用常用的 email 進行贊助。" />
      <Confirm text="確認" type={PillButton.Type.PRIMARY} style={PillButton.Style.DARK} size={PillButton.Size.L} onClick={onConfirm} />
      <SwitchMethod text="使用其他 email 贊助" TextComponent={P2} onClick={onSwitchMethod} />
    </Container>
  )
}
ConfirmEmail.propTypes = {
  email: PropTypes.string.isRequired,
  onSwitchMethod: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default ConfirmEmail
