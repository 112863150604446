import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
// utils
import shouldRedirectToLoginPage from '../../utils/should-redirect-to-login-page'
import urlUtils from  '../../utils/url'
// actions
import {
  resetContributeState,
} from '../../actions/contribute'
// constants
import supportConsts, { CARD_ERROR } from '../../constants/support'
import wellDefinedPropTypes from '../../constants/prop-types'
// components
import Contribute from '../../components/support/contribute-page'
import Message from '../../components/support/message-page'
import NavigationPrompt from '../../components/support/navigation-prompt'
import RedirectMessage from '../../components/support/redirect-message'
import CardError from '../../components/card-error-page'
// @twreporter
import { P2 } from '@twreporter/react-components/lib/text/paragraph'
// lodash
import assign from 'lodash/assign'
import get from 'lodash/get'
import values from 'lodash/values'
const _ = {
  assign,
  get,
  values,
}

const Guide = styled(P2)`
  display: block;
`

const renderedStepConsts = supportConsts.contribute.renderedStep
const frequencyConstsKeys = _.values(supportConsts.contribute.frequency)

const ContributePage = ({
  location,
  history,
}) => {
  // redux state selector
  const authState = (state) => _.get(state, 'auth')
  const contributeState = (state) => _.get(state, 'contribute')
  const userInfoSelector = createSelector(authState, (auth) => ({
    userInfo: _.get(auth, 'userInfo', {}),
    jwt: _.get(auth, 'accessToken', ''),
  }))
  const contributeDetailSelector = createSelector(contributeState, (contribute) => ({
    renderedStep: _.get(contribute, 'step', ''),
    orderNum: _.get(contribute, 'orderNum'),
    error: _.get(contribute, 'error'),
  }))
  const { userInfo, jwt } = useSelector(userInfoSelector)
  const { renderedStep, orderNum, error } = useSelector(contributeDetailSelector)

  const userData = useMemo(() => _.assign({}, userInfo, { jwt }), [ userInfo, jwt ])
  const toShowRedirectMessage = useMemo(() => shouldRedirectToLoginPage(userData), [ userData ])
  const canUserLeave = useMemo(() => (renderedStep === renderedStepConsts.errorMessage
    || renderedStep === renderedStepConsts.redirectOnContribute
    || toShowRedirectMessage), [ renderedStep, toShowRedirectMessage ])
  
  const dispatch = useDispatch()

  useEffect(() => {
    // check if url have parameter
    const isContributeParamsValid = validateContributeParams(searchParams)
    if (!isContributeParamsValid) {
      // redirect to inrtro page if no valid frequency and value given
      return window.location.replace(`${supportConsts.env.app.url}/intro${search}`)
    }
  }, [ location ])

  const urlSearchParams = () => {
    const params = {}
    const searchParams = urlUtils.parseSearch(_.get(location, 'search', ''))
    for (const [ key, value ] of searchParams.entries()) {
      params[key] = value
    }
    return params
  }

  const searchParams = useMemo(() => urlSearchParams(), [ location ])
  const search = useMemo(() => _.get(location, 'search', ''), [ location ])

  const validateContributeParams = (urlSearchParams) => {
    const amount = get(urlSearchParams, 'amount')
    if (!(parseInt(amount, 10) >= supportConsts.contribute.amount.lowerLimit)) return false
    const frequency = get(urlSearchParams, 'frequency')
    if (!frequencyConstsKeys.some(frequencyConstsKey => frequency === frequencyConstsKey)) return false
    return true
  }

  const renderErrorPage = () => {
    const errorMsg = _.get(error, 'data.message')
    if (errorMsg && errorMsg.match(CARD_ERROR.regrex)) {
      return <CardError returnContribute={() => dispatch(resetContributeState())} />
    }
    const onButtonClick = () => {
      window.open('/intro', '_self')
    }
    const guide = (
      <Guide>
        <span>很抱歉，您使用報導者贊助系統作業失敗。若再次嘗試後仍無法解決，請</span>
        <a href="mailto:events@twreporter.org">聯絡我們</a>
        <span>為您檢查問題，謝謝。</span>
      </Guide>
    )
    return (
      <Message
        title="贊助失敗"
        guide={guide}
        buttonText="回到贊助頁"
        buttonOnclick={onButtonClick}
      />
    )
  }

  const renderPageContent = () => {
    switch(renderedStep) {
      case renderedStepConsts.errorMessage: {
        return renderErrorPage()
      }
      case renderedStepConsts.updateContributeRecordForm: {
        const { frequency } = searchParams
        const currentState = _.get(history, 'location.state.step')
        const step = renderedStepConsts.updateContributeRecordForm
        if (currentState !== step) {
          history.push(`/contribute/${frequency}/${orderNum}${search}`, { step })
        }
        break
      }
      case renderedStepConsts.contribute:
      case renderedStepConsts.redirectOnContribute:
      default: {
        const { frequency, amount } = searchParams
        const contributeValue = parseInt(amount, 10)
        const pathname = _.get(location, 'pathname', '')
        return (
          <Contribute
            currencyStr={supportConsts.contribute.currencyStr.ntd}
            frequency={frequency}
            uri={{
              pathname,
              search,
            }}
            value={contributeValue}
          />
        )
      }
    }
  }

  return (
    <React.Fragment>
      <RedirectMessage
        toShow={toShowRedirectMessage}
        history={history}
      />
      <NavigationPrompt
        toPrompt={() => !canUserLeave}
        actionBeforeUnmount={() => dispatch(resetContributeState())}
        redirectToAfterConfirm={{
          pathname: '/intro',
          search,
        }}
      />
      {renderPageContent()}
    </React.Fragment>
  )
}

ContributePage.propTypes = {
  // Props below given by react-router
  location: wellDefinedPropTypes.location.isRequired,
  // history is provided by `BrowserRouter` of react-router-dom
  history: PropTypes.object.isRequired,
}

export default withRouter(ContributePage)
