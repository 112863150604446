import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import env from '../global-env'
// components
import Header from './simple-header'
// @twreporter
import Footer from '@twreporter/react-components/lib/footer'
import EmptyState from  '@twreporter/react-components/lib/empty-state'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

const Container = styled.div`
  background: ${colorGrayscale.gray100};
`
const Box = styled.div`
  margin: 72px 0 120px 0;
  display: flex;
  align-items: center;
`

const CardError = ({ returnContribute }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])
  const onButtonClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    returnContribute()
  }

  return (
    <Container>
      <Header />
      <Box>
        <EmptyState
          style={EmptyState.Style.DEFAULT}
          title="信用卡授權失敗"
          guide="請檢查信用卡資訊是否正確，若無誤請洽詢發卡行，或使用其他信用卡。"
          showGuide={true}  
          showButton={true}
          buttonText="返回上一步"
          buttonOnclick={onButtonClick}
          releaseBranch={env.releaseBranch}
        />
      </Box>
      <Footer releaseBranch={env.releaseBranch} />
    </Container>
  )
}
CardError.propTypes = {
  returnContribute: PropTypes.func.isRequired,
}

export default CardError
