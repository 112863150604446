import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import env from '../../../global-env'
// components
import Header from '../../simple-header'
// @twreporter
import Footer from '@twreporter/react-components/lib/footer'
import EmptyState from  '@twreporter/react-components/lib/empty-state'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

const Container = styled.div`
  background: ${colorGrayscale.gray100};
`
const Box = styled.div`
  margin: 72px 0 120px 0;
  display: flex;
  align-items: center;
`

const Message = ({
  style = EmptyState.Style.DEFAULT,
  title = '',
  guide = '',
  buttonText = '',
  buttonOnclick = () => {},
}) => {
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])
  const showGuide = useMemo(() => !!guide, [ guide ])
  const showButton = useMemo(() => !!buttonText, [ buttonText ])
  const onButtonClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (typeof buttonOnclick === 'function') {
      buttonOnclick()
    }
  }

  return (
    <Container>
      <Header />
      <Box>
        <EmptyState
          style={style}
          title={title}
          guide={guide}
          showGuide={showGuide}  
          showButton={showButton}
          buttonText={buttonText}
          buttonOnclick={onButtonClick}
          releaseBranch={env.releaseBranch}
        />
      </Box>
      <Footer releaseBranch={env.releaseBranch} />
    </Container>
  )
}
Message.propTypes = {
  style: EmptyState.propTypes.style,
  title: EmptyState.propTypes.title,
  guide: EmptyState.propTypes.guide,
  buttonText: EmptyState.propTypes.buttonText,
  buttonOnclick: EmptyState.propTypes.buttonOnclick,
}
Message.Style = EmptyState.Style

export default Message
