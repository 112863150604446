import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import PropTypes from 'prop-types'

// @twreporter
import { H3 } from '@twreporter/react-components/lib/text/headline'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import { P1 } from '@twreporter/react-components/lib/text/paragraph'
import mq from '@twreporter/core/lib/utils/media-query'
import { MEMBER_ROLE } from '@twreporter/core/lib/constants/member-role'
import { MobileOnly, TabletAndAbove } from '@twreporter/react-components/lib/rwd'

// components
import { Divider } from '../divider'
import RoleCard from './role-card'

// constant
import siteMap from '../../../../constants/site-map'
import { FREQUENCY } from '../contribute-box/constants'

// context
import { RoleContext } from '../../../../contexts'

const Container = styled.div``

const StyledH3 = styled(H3)`
  margin-bottom: 16px;
  color: ${colorGrayscale.gray800};
`

const ContentContainer = styled.div`
  margin-top: 24px;
  display: grid;
  ${mq.mobileOnly`
    grid-column-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  `}
  ${mq.tabletOnly`
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  `}
  ${mq.desktopAndAbove`
    grid-column-gap: 32px;
    grid-template-columns: repeat(9, 1fr);
  `}
`
const Text = styled.div`
  color: ${colorGrayscale.gray800};
  ${mq.mobileOnly`
    grid-column: span 4;
  `}
  ${mq.tabletOnly`
    grid-column: span 9;
  `}
  ${mq.desktopAndAbove`
    grid-column: span 5;
  `}
`

const RoleCards = styled.div`
  margin-top: 32px;
  display: grid;
  ${mq.mobileOnly`
    width: 100vw;
    margin-left: -24px;
    .swiper-container {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      width: 75vw;
      max-width: 360px;
    }
  `}
  ${mq.tabletOnly`
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  `}
  ${mq.desktopAndAbove`
    grid-column-gap: 32px;
    grid-template-columns: repeat(9, 1fr);
  `}
`

const StyledRoleCard = styled.div`
  height: 100%;
  ${mq.tabletOnly`
    grid-column: span 4;
  `}
  ${mq.desktopAndAbove`
    grid-column: span 3;
  `}
`

const Roles = ({ onRoleCardButtonClick }) => {
  const [ activeRole, setActiveRole ] = useState('')
  const { amount, frequency, setFrequency } = useContext(RoleContext)
  const rolesData = [
    {
      roleKey: MEMBER_ROLE.explorer,
      conditionText: '免費註冊',
      roleTitle: '探索者',
      roleKeyText: 'EXPLORER',
      slogan: '探索獨立媒體的價值',
      buttonText: '前往註冊',
      onButtonClick: () => window.open(`${siteMap.accounts.url}/register`, '_blank'),
    },
    {
      roleKey: MEMBER_ROLE.action_taker,
      conditionText: '不限金額贊助',
      roleTitle: '行動者',
      roleKeyText: 'ACTION TAKER',
      slogan: '以實際行動表達支持',
      buttonText: '贊助支持',
      onButtonClick: () => {
        setActiveRole(MEMBER_ROLE.action_taker)
        onRoleCardButtonClick(MEMBER_ROLE.action_taker)
      },
    },
    {
      roleKey: MEMBER_ROLE.trailblazer,
      conditionText: '每月贊助 500 元以上',
      roleTitle: '開創者',
      roleKeyText: 'TRAILBLAZER',
      slogan: '開創組織永續經營之路',
      buttonText: '贊助支持',
      onButtonClick: () => {
        setActiveRole(MEMBER_ROLE.trailblazer)
        setFrequency(FREQUENCY.monthly)
        onRoleCardButtonClick(MEMBER_ROLE.trailblazer)
      },
    },
  ]

  const checkActiveRoleByAmount = () => {
    if (!amount || amount < 100) {
      setActiveRole('')
    }
    if (frequency === FREQUENCY.oneTime && amount) {
      setActiveRole(MEMBER_ROLE.action_taker)
    } else {
      if (amount >= 100 && amount < 500) {
        setActiveRole(MEMBER_ROLE.action_taker)
      }
      if (amount >= 500) {
        setActiveRole(MEMBER_ROLE.trailblazer)
      }
    }
  }

  useEffect(() => {
    checkActiveRoleByAmount()
  }, [ amount, frequency ])
  
  return (
    <Container>
      <StyledH3 text={'用你的方式，開創更好的媒體環境'} />
      <Divider />
      <ContentContainer>
        <Text>
          <P1 text={'在追求真相的路上，任何形式的支持對《報導者》都具有重大意義。'} />
          <P1 text={'歡迎用最適合你的方案支持我們繼續前行。'} />
        </Text>
      </ContentContainer>
      <TabletAndAbove>
        <RoleCards>
          {rolesData.map(({ roleKey, conditionText, roleTitle, roleKeyText, slogan, buttonText, onButtonClick }, idx) => 
            <StyledRoleCard key={idx}>
              <RoleCard isActive={roleKey === activeRole} roleKey={roleKey} conditionText={conditionText} roleTitle={roleTitle} roleKeyText={roleKeyText} slogan={slogan} buttonText={buttonText} onButtonClick={onButtonClick} />
            </StyledRoleCard>
          )}
        </RoleCards>  
      </TabletAndAbove>
      <MobileOnly>
        <RoleCards>
          <Swiper slidesPerView={'auto'} centeredSlides={true} spaceBetween={16} initialSlide={1}>
            {rolesData.map(({ roleKey, conditionText, roleTitle, roleKeyText, slogan, buttonText, onButtonClick }, idx) => 
              <SwiperSlide key={idx}>
                <RoleCard roleKey={roleKey} conditionText={conditionText} roleTitle={roleTitle} roleKeyText={roleKeyText} slogan={slogan} buttonText={buttonText} onButtonClick={onButtonClick} />
              </SwiperSlide>
            )}
          </Swiper>
        </RoleCards>  
      </MobileOnly>
    </Container>
  )
}

Roles.propTypes = {
  onRoleCardButtonClick: PropTypes.func,
}

export default Roles
