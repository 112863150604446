import predefinedPropTypes from '../constants/prop-types'
import PropTypes from 'prop-types'
import React from 'react'
import { Route } from 'react-router-dom'

const RouteWithStatus = ({ statusCode, children }) => (
  <Route render={({ staticContext }) => {
    /* 
      Under `<StaticRouter>`, when a `<Route>` matches, it will pass the context object to the component it renders as the `staticContext` prop.
      See: https://reacttraining.com/react-router/web/api/StaticRouter/context-object
    */
    if (staticContext) {
      staticContext.status = statusCode
    }
    return children
  }}/>
)

RouteWithStatus.propTypes = {
  statusCode: predefinedPropTypes.statusCode.isRequired,
  children: PropTypes.node,
}

export default RouteWithStatus
