import globalEnv from '../global-env'
import siteMap from './site-map'
// @twreporter
import releaseBranchConst from '@twreporter/core/lib/constants/release-branch'

const env = {
  [releaseBranchConst.master]: {
    app: {
      url: 'http://localhost:3000',
    },
  },
  [releaseBranchConst.dev]: {
    app: {
      url: siteMap.accounts.url,
    },
  },
  [releaseBranchConst.staging]: {
    app: {
      url: siteMap.accounts.url,
    },
  },
  [releaseBranchConst.release]: {
    app: {
      url: siteMap.accounts.url,
    },
  },
}[globalEnv.releaseBranch]

export default {
  env,
  timeout: 5000,
}
