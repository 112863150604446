import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// components
import StructuredValues from './structured-values'
// constants
import typography from '../../../constants/typography'
import colors from '../../../constants/colors'
// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

const color = {
  title: colorGrayscale.gray800,
  content: colorGrayscale.gray900,
  background: colors.gray50,
}

const Item = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`
const Title = styled.div`
  color: ${color.title};
  letter-spacing: 0.8px;
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: ${typography.font.weight.bold};
`

const Label = styled.div`
  color: ${color.title};
  letter-spacing: 0.8px;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: ${typography.font.weight.normal};
`

const Value = styled.div`
  color: ${color.content};
  line-height: 1.88;
  letter-spacing: 1.1px;
  font-size: 16px;
  font-weight: ${typography.font.weight.normal};
`

const Container = styled.div`
  background: ${color.background};
  padding: 30px 25px;
`

const SubmittingValues = ({ values = [] }) => (
  <Container>
    <StructuredValues
      values={values}
      components={{
        Title,
        Label,
        Value, 
        Item,
      }}
    />
  </Container>
)

SubmittingValues.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.bool,
    ]),
    handler: PropTypes.func,
  })),
}

export default React.memo(SubmittingValues)
