import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import styled from 'styled-components'
// components
import Tooltip from './tooltip'
// @twreporter
import mediaQuery from '@twreporter/core/lib/utils/media-query'
import { colorGrayscale, colorSupportive } from '@twreporter/core/lib/constants/color'
// lodash
import get from 'lodash/get'
import map from 'lodash/map'

const _ = {
  get,
  map,
}

const CustomCheckmark = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: ${props => props.$diameter.desktop}px;
  width: ${props => props.$diameter.desktop}px;
  background-color: ${props => props.$disabled ? colorGrayscale.gray200 : colorGrayscale.gray300 };
  border-radius: 50%;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.$diameter.desktop-2}px;
    height: ${props => props.$diameter.desktop-2}px;
    border-radius: 50%;
    background: white;
  }
  ${mediaQuery.mobileOnly`
    height: ${props => props.$diameter.mobile}px;
    width: ${props => props.$diameter.mobile}px;
    &::after {
      width: ${props => props.$diameter.mobile-2}px;
      height: ${props => props.$diameter.mobile-2}px;
    }
  `}
`

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${props => props.$disabled ? 'default': 'pointer'};
  color: ${props => props.$disabled ? colorGrayscale.gray500 : colorGrayscale.gray900};
  font-size: 18px;
  line-height: 20px;
  padding-left: calc(${props => props.$diameter.desktop}px + 15px);
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;

  ${mediaQuery.desktopAndAbove`
    ${props => props.$disabled ? '' : `
      &:hover {
        ${CustomCheckmark} {
          background-color: ${colorGrayscale.gray900};
        }
        ${CustomCheckmark}::after {
          width: calc(${props.$diameter.desktop}px * 11/20);
          height: calc(${props.$diameter.desktop}px * 11/20);
        }
      }
    `}
  `}

  ${props => props.$checked ? `
    ${CustomCheckmark} {
      background-color: ${colorSupportive.heavy} !important;
    }
    ${CustomCheckmark}::after {
      width: 10px;
      height: 10px;
    }
  ` : ''}
  
  ${mediaQuery.mobileOnly`
    padding-left: calc(${props => props.$diameter.mobile}px + 16px);
    ${props => props.$checked ? `
      ${CustomCheckmark}::after {
        width: calc(${props => props.$diameter.mobile}px * 11/20);
        height: calc(${props => props.$diameter.mobile}px * 11/20);
      }
    ` : ''}
  `}
`

const HiddenInput = styled.input`
  display: none;
`

const Notation = styled.p`
  display: inline-block;
  font-size: 14px;
  ${mediaQuery.mobileOnly`
    display: block;
  `}
`

const StyledTooltip = styled(Tooltip)`
  margin-right: 30px!important;
  margin-left: 8px;
  & > :first-child, & * > :first-child {
    margin: 0!important;
  }
`

export const Radio = ({
  checked,
  handleChange,
  label,
  notation = '',
  name,
  value,
  diameter,
  tooltip,
  canToggle,
}) => {
  const select = (e) => {
    if (!canToggle) return
    handleChange(e)
  }
  return (
    <Label
      $diameter={diameter}
      $checked={checked}
      $disabled={!canToggle}
    >
      {label}
      {notation ? (
        <Notation>
          {notation}
        </Notation>
      ) : null}
      {tooltip ? <StyledTooltip message={tooltip} /> : null}
      <HiddenInput
        role="radio"
        type="radio"
        checked={checked}
        name={name}
        value={value}
        onChange={select}
        onClick={select}
        $diameter={diameter}
      />
      <CustomCheckmark 
        $diameter={diameter}
        $disabled={!canToggle}
      />
    </Label>
  )
}
Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  notation: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  diameter: PropTypes.shape({
    desktop: PropTypes.number,
    mobile: PropTypes.number,
  }).isRequired,
  tooltip: PropTypes.oneOf([ PropTypes.string, PropTypes.element ]),
  canToggle: PropTypes.bool.isRequired,
}

const RadioGroup = ({
  name,
  checkedValue,
  checkedFunc,
  options,
  handleChange,
  diameter = { desktop: 20, mobile: 20 },
  canToggle = true,
}) => {
  const buildInput = (option) => {
    const { label, notation, value, tooltip } = option
    const checked = typeof checkedFunc === 'function' ? checkedFunc(value) : checkedValue === value
    return (
      <Radio
        key={value}
        value={value}
        name={name}
        label={label}
        notation={notation}
        checked={checked}
        handleChange={handleChange}
        diameter={diameter}
        tooltip={tooltip}
        canToggle={canToggle}
      />
    )
  }

  return (
    <Fragment>
      {_.map(options, buildInput)}
    </Fragment>
  )
}
RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  checkedValue: PropTypes.string,
  checkedFunc: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    notation: PropTypes.string,
    value: PropTypes.string,
    tooltip: PropTypes.oneOf([ PropTypes.string, PropTypes.element ]),
  })).isRequired,
  handleChange: PropTypes.func.isRequired,
  diameter: PropTypes.shape({
    desktop: PropTypes.number,
    mobile: PropTypes.number,
  }),
  canToggle: PropTypes.bool,
}

export default RadioGroup
