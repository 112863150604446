import React from 'react'
import styled from 'styled-components'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import env from '../global-env'
// constants
import statusCodeConst from '../constants/status-code'
import predefinedPropTypes from '../constants/prop-types'
// @twreporter
import Footer from '@twreporter/react-components/lib/footer'
import Header from '@twreporter/universal-header/lib/containers/header'
import { ErrorMessage } from '@twreporter/react-components/lib/error'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

const Container = styled.div`
  background: ${colorGrayscale.gray100};
`

const ErrorPage = ({ statusCode = statusCodeConst.internalServerError }) => {
  const selectTitle = (statusCode) => {
    switch (statusCode) {
      case 404:
        return 'Error 404 - 找不到網頁'
      case 500:
      default:
        return 'Error 500 - 網站伺服器錯誤'
    }
  }

  const errorType = statusCode.toString()
  return (
    <HelmetProvider>
      <Container>
        <Header
          theme="normal"
          releaseBranch={env.releaseBranch}
          isLinkExternal={true}
        />
        <ErrorMessage errorType={errorType} />
        <Footer releaseBranch={env.releaseBranch} />
      </Container>
      <Helmet>
        <title>{selectTitle(statusCode)}</title>
      </Helmet>
    </HelmetProvider>
  )
}
ErrorPage.propTypes = {
  statusCode: predefinedPropTypes.statusCode,
}

export default ErrorPage
