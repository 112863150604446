import React from 'react'
import styled from 'styled-components'

// @twreporter
import { fontWeight, fonts } from '@twreporter/core/lib/constants/font'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import sloganText from '@twreporter/universal-header/lib/constants/slogan'

const SloganContainer = styled.div`
  color: ${colorGrayscale.gray800};
  display: flex;
  align-items: center;
  cursor: default;
  font-family: ${fonts.serif};
  font-weight: ${fontWeight.normal};
  font-size: 14px;
  line-height: 150%;
`

const Slogan = () => {
  return (
    <SloganContainer>
      {sloganText}
    </SloganContainer>
  )
}

export default Slogan
