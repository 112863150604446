import axios from 'axios'
import env from '../global-env'
// constants
import accountsConst from '../constants/accounts'
// @twreporter
import requestOrigin from '@twreporter/core/lib/constants/request-origins'

export const refreshAccessToken = async () => {
  const url = `${requestOrigin.forServerSideRendering[env.releaseBranch].accounts}/refreshToken`

  const options = {
    timeout: accountsConst.timeout,
    withCredentials: true,
  }

  try {
    const res = await axios.get(url, options)
    return res
  } catch (err) {
    /* eslint-disable-next-line no-console */
    console.error('refresh token fail', err)
  }
}

