/* eslint no-console: 0 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
// utils
import urlUtils from '../../utils/url'
// constants
import wellDefinedPropTypes from '../../constants/prop-types'
import siteMap from '../../constants/site-map'
// components
import SignIn from '../../components/accounts/sign-in-page'
// @twreporter
import Footer from '@twreporter/react-components/lib/footer'
import { BRANCH, BRANCH_PROP_TYPES } from '@twreporter/core/lib/constants/release-branch'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
// lodash
import get from 'lodash/get'

const _ = {
  get,
}

const Body = styled.div`
  background-color: ${colorGrayscale.gray100};
`

const SignInPage = ({
  type = SignIn.Type.LOGIN,
  releaseBranch = BRANCH.master,
  location,
}) => {
  const [ destination, setDestination ] = useState('')
  useEffect(() => {
    // get destination from url search
    // location is passed from withRouter HOC
    const search = _.get(location, 'search', '')
    setDestination(urlUtils.parseSearch(search).get('destination') || siteMap.main.url)
  }, [])

  return (
    <React.Fragment>
      <Body>
        <SignIn
          type={type}
          destination={destination}
        />
      </Body>
      <Footer releaseBranch={releaseBranch} />
    </React.Fragment>
  )
}
SignInPage.propTypes = {
  type: SignIn.propTypes.type,
  location: wellDefinedPropTypes.location.isRequired,
  releaseBranch: BRANCH_PROP_TYPES,
}
SignInPage.Type = SignIn.Type

function mapStateToProps(state) {
  const releaseBranch = _.get(state, 'globalEnv.releaseBranch', BRANCH.master)

  return {
    releaseBranch,
  }
}

export default withRouter(connect(mapStateToProps)(SignInPage))
