import React from 'react'
import TagManger from 'react-gtm-module'

class ScrollToTopAndSendPageView extends React.PureComponent {
  scrollToTopAndSendGAPageView() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
      // Send Google Analytics Pageview event on route changes
      TagManger.dataLayer({
        dataLayer: {
          event: 'PageView',
          page: window.location.pathname + window.location.search,
        },
      })
    }
  }

  render() {
    this.scrollToTopAndSendGAPageView()
    return null
  }
}

export default ScrollToTopAndSendPageView 
