import supportConsts from '../../../constants/support'
// lodash
import get from 'lodash/get'
import map from 'lodash/map'

const _ = {
  get,
  map,
}

const contributeConsts = supportConsts.contribute

const getSendReceiptLabel = (sendReceipt) => {
  switch(sendReceipt) {
    case contributeConsts.sendReceipt.no:
      return contributeConsts.sendReceiptLabel.no.label
    case contributeConsts.sendReceipt.monthly:
      return contributeConsts.sendReceiptLabel.monthly.label
    case contributeConsts.sendReceipt.yearly:
      return contributeConsts.sendReceiptLabel.yearly.label
    case contributeConsts.sendReceipt.digital:
      return contributeConsts.sendReceiptLabel.digital.label
    default:
      console.error('The value of `sendReceipt` is invalid', sendReceipt) // eslint-disable-line no-console
      return ''
  }
}

/**
 * arrayJoinParam type definition
 * @typedef {Object} arrayJoinParam
 * @property {string[]} ignoreStringsArray - strings as an array to ignore when join an array
 * @property {string} separator - a separator for array join
 */

/**
 * Join an array to string
 *
 * @param {arrayJoinParam} arg
 * @returns {function}
 *   @param {string[]} stringsArray - target array to be joined to a string
 *   @returns {string}
 */
const arrayJoin = (arg) => {
  const { ignoreStringsArray = [], separator='' } = arg || {}
  return (stringsArray) => {
    return stringsArray.filter((string) => !ignoreStringsArray.includes(string)).join(separator)
  }
}

const getBooleanValue = (bool) => {
  switch(bool) {
    case true:
      return '是'
    case false:
      return '否'
    default:
      return ''
  }
}

const getGender = (gender) => {
  switch(gender) {
    case contributeConsts.gender.female:
      return contributeConsts.genderLabel.female
    case contributeConsts.gender.male:
      return contributeConsts.genderLabel.male
    case contributeConsts.gender.others:
      return contributeConsts.genderLabel.others
    case contributeConsts.gender.unwilling_to_disclose:
      return contributeConsts.genderLabel.unwilling_to_disclose
    default:
      return ''
  }
}

const getAgeRange = (age) => {
  switch(age) {
    case contributeConsts.age['less_than_18']:
      return contributeConsts.ageLabel['less_than_18']
    case contributeConsts.age['19_to_24']:
      return contributeConsts.ageLabel['19_to_24']
    case contributeConsts.age['25_to_34']:
      return contributeConsts.ageLabel['25_to_34']
    case contributeConsts.age['35_to_44']:
      return contributeConsts.ageLabel['35_to_44']
    case contributeConsts.age['45_to_54']:
      return contributeConsts.ageLabel['45_to_54']
    case contributeConsts.age['55_to_64']:
      return contributeConsts.ageLabel['55_to_64']
    case contributeConsts.age['above_65']:
      return contributeConsts.ageLabel['above_65']
    default:
      return ''
  }
}

const getReadPreference = (values) => {
  if (!values) {
    return []
  }
  const readPreferenceLabel = contributeConsts.readPreferenceLabel
  return _.map(values.split(','), (value) => readPreferenceLabel[value]).join(',')
}

export const preparePersonalSubmittingValues = (updates) => {
  return [
    {
      title: '贊助者身份',
      value: contributeConsts.roleLabel.person,
    },
    {
      title: '贊助者資訊',
    },
    {
      label: '姓名',
      value: [
        _.get(updates, 'donor.last_name'),
        _.get(updates, 'donor.first_name'),
      ],
      handler: arrayJoin(),
    },
    {
      label: '聯絡電話',
      value: _.get(updates, 'donor.phone_number'),
    },
    {
      label: 'Email',
      value: _.get(updates, 'donor.email'),
    },
    {
      label: '地址',
      value: [
        _.get(updates, 'donor.address_country'),
        _.get(updates, 'donor.address_state'),
        _.get(updates, 'donor.address_city'),
        _.get(updates, 'donor.address_detail'),
      ],
      handler: arrayJoin({ ignoreStringsArray: [ contributeConsts.placeholder ] }),
    },
    {
      label: '我不希望將全名公開在《報導者》的捐款徵信名冊',
      value: _.get(updates, 'is_anonymous'),
      handler: getBooleanValue,
    },
    {
      title: '收據寄送',
    },
    {
      label: '是否需要開立收據？',
      value: _.get(updates, 'send_receipt'),
      handler: getSendReceiptLabel,
    },
    {
      label: '收據抬頭',
      value: _.get(updates, 'receipt.header'),
    },
    {
      label: '身分證字號／統一證號',
      value: _.get(updates, 'receipt.security_id'),
    },
    {
      label: '地址',
      value: [
        _.get(updates, 'receipt.address_country'),
        _.get(updates, 'receipt.address_state'),
        _.get(updates, 'receipt.address_city'),
        _.get(updates, 'receipt.address_detail'),
      ],
      handler: arrayJoin({ ignoreStringsArray: [ contributeConsts.placeholder ] }),
    },
    {
      label: '是否需要上傳此筆捐款資料至國稅局',
      value: _.get(updates, 'auto_tax_deduction'),
      handler: getBooleanValue,
    },
    {
      title: '讓我們更了解您',
    },
    {
      label: '希望報導者如何稱呼您',
      value: _.get(updates, 'donor.nickname'),
    },
    {
      label: '性別',
      value: _.get(updates, 'donor.gender'),
      handler: getGender,
    },
    {
      label: '年齡',
      value: _.get(updates, 'donor.age_range'),
      handler: getAgeRange,
    },
    {
      label: '關心的議題',
      value: _.get(updates, 'donor.read_preference'),
      handler: getReadPreference,
    },
    {
      label: '想對報導者說的話',
      value: _.get(updates, 'donor.words_for_twreporter'),
    },
  ]
}

export const prepareOrganizationSubmittingValues = (updates) => {
  return [
    {
      title: '贊助者身份',
      value: contributeConsts.roleLabel.organization,

    },
    {
      title: '贊助者資訊',
    },
    {
      label: '公司／組織全名',
      value: _.get(updates, 'donor.legal_name'),
    },
    {
      label: '統一編號',
      value: _.get(updates, 'donor.security_id'),
    },
    {
      label: '聯絡人姓名',
      value: [
        _.get(updates, 'donor.last_name'),
        _.get(updates, 'donor.first_name'),
      ],
      handler: arrayJoin(),
    },
    {
      label: '聯絡人職稱',
      value: _.get(updates, 'donor.title'),
    },
    {
      label: '聯絡電話',
      value: _.get(updates, 'donor.phone_number'),
    },
    {
      label: '聯絡人Email',
      value: _.get(updates, 'donor.email'),
    },
    {
      label: '地址',
      value: [
        _.get(updates, 'donor.address_country'),
        _.get(updates, 'donor.address_state'),
        _.get(updates, 'donor.address_city'),
        _.get(updates, 'donor.address_detail'),
      ],
      handler: arrayJoin({ ignoreStringsArray: [ contributeConsts.placeholder ] }),
    },
    {
      label: '我不希望將全名公開在《報導者》的捐款徵信名冊',
      value: _.get(updates, 'is_anonymous'),
      handler: getBooleanValue,
    },
    {
      title: '收據寄送',
    },
    {
      label: '是否需要開立收據?',
      value: _.get(updates, 'send_receipt'),
      handler: getSendReceiptLabel,
    },
    {
      label: '收據抬頭',
      value: _.get(updates, 'receipt.header'),
    },
    {
      label: '統一編號',
      value: _.get(updates, 'receipt.security_id'),
    },
    {
      label: '地址',
      value: [
        _.get(updates, 'receipt.address_country'),
        _.get(updates, 'receipt.address_state'),
        _.get(updates, 'receipt.address_city'),
        _.get(updates, 'receipt.address_detail'),
      ],
      handler: arrayJoin({ ignoreStringsArray: [ contributeConsts.placeholder ] }),
    },
  ]
}
