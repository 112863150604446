import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import ScreenScrollLock from '../screen-scroll-lock'
// components
import Bsc from '../basic-styled-components'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import { H4 } from '@twreporter/react-components/lib/text/headline'
import { P1 } from '@twreporter/react-components/lib/text/paragraph'
import { TextButton, PillButton } from '@twreporter/react-components/lib/button'

const Dimmer = styled(Bsc.Dimmer)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Box = styled.div`
  background-color: ${colorGrayscale.white};
  max-width: 400px;
  width: 100%;
  flex: 0 0 100%;
  ${mq.desktopAndAbove`
    width: 400px;
    flex: 0 0 400px;
  `}
`

const BoxContent = styled.div`
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
`

const FixSection = styled.div`
  flex: 1 0 auto;
`

const ShrinkSection = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`

const TitleSection = styled(FixSection)`
  margin-bottom: 30px;
`
const ButtonsSection = styled(FixSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`
const ConfirmButton = styled(PillButton)`
  min-width: 96px;
  justify-content: center;
`

export const Confirm = ({
  show = true,
  title = '確認送出？',
  message = '按下確定後，將前進到下一步',
  confirmBtnText = '確定',
  cancelBtnText = '取消',
  handleConfirmed =  () => {},
  handleCanceled = () => {},
  children,
}) => {
  return (
    <Dimmer $show={show} $dark>
      <Box>
        <BoxContent>
          {title ? <TitleSection><H4 text={title} /></TitleSection> : null}
          <ShrinkSection>
            {message ? <P1 text={message} /> : null}
            {children}
          </ShrinkSection>
          <ButtonsSection>
            <TextButton
              onClick={handleCanceled}
              text={cancelBtnText}
              size={TextButton.Size.S}
              style={TextButton.Style.LIGHT}
              theme={TextButton.THEME.normal}
            />
            <ConfirmButton
              onClick={handleConfirmed}
              text={confirmBtnText}
              size={PillButton.Size.S}
              style={PillButton.Style.DARK}
              theme={PillButton.THEME.normal}
              type={PillButton.Type.PRIMARY}
            />
          </ButtonsSection>
        </BoxContent>
      </Box>
      <ScreenScrollLock lock={show} />
    </Dimmer>
  )
}
Confirm.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  handleConfirmed: PropTypes.func.isRequired,
  handleCanceled: PropTypes.func.isRequired,
  children: PropTypes.node,
}
Confirm.displayName = 'confirm'

const ConfirmWithButton = ({
  ButtonComponent,
  // confirm props
  title = '確認送出？',
  message = '按下確定後，將前進到下一步',
  confirmBtnText = '確定',
  cancelBtnText = '取消',
  handleConfirmed =  () => {},
  disabled = false,
  children,
}) => {
  const [ showConfirm, setShowConfirm ] = useState(false)
  const showConfirmation = () => {
    if (disabled) {
      return
    }
    setShowConfirm(true)
  }
  const hideConfirmation = () => {
    setShowConfirm(false)
  }
  const confirmed = () => {
    if (disabled) {
      return
    }
    if (typeof handleConfirmed === 'function') {
      handleConfirmed()
      hideConfirmation()
    } else {
      console.error(new Error('`props.handleConfirmed` must be a function.')) // eslint-disable-line no-console
    }
  }
  return (
    <Fragment>
      <div onClick={showConfirmation}>
        {ButtonComponent}
      </div>
      <Confirm
        show={showConfirm}
        title={title}
        message={message}
        confirmBtnText={confirmBtnText}
        cancelBtnText={cancelBtnText}
        handleCanceled={hideConfirmation}
        handleConfirmed={confirmed}
        children={children}
      />
    </Fragment>
  )
}
ConfirmWithButton.propTypes = {
  ButtonComponent: PropTypes.element,
  // belows are confirm props
  title: PropTypes.string,
  message: PropTypes.string,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  handleConfirmed: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}

export default ConfirmWithButton
