import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// @twreporter
import { colorGrayscale, colorSupportive } from '@twreporter/core/lib/constants/color'
// lodash
import merge from 'lodash/merge'
const _ = {
  merge,
}

const Input = styled.input.attrs({ type: 'checkbox' })`
  /* taken from https://css-tricks.com/inclusively-hidden/ */
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const Box = styled.div`
  display: inline-block;
  cursor: pointer;
  width: ${props => props.$width}px;
  height: ${props => props.$height}px;
  border: solid 1px ${colorGrayscale.gray500};
  margin-right: ${props => props.$marginRightToLabel}px;
  vertical-align: middle;
  border-radius: 2px;
  background: ${props => props.$backgroundColor};
  > svg {
    visibility: ${props => props.$isChecked ? 'visible' : 'hidden'};
  }
`

const VerticallyCenter = styled.div`
  display: flex;
  align-items: center;
`

const noop = () => {}

export default class Checkbox extends React.PureComponent {
  static defaultProps = {
    className: '',
    handleClick: noop,
    height: 21,
    width: 21,
    isChecked: false,
    name: '',
    label: '',
    style: {
      backgroundColor: {
        default: 'transparent',
        checked: colorSupportive.heavy,
      },
      checkMarkColor: colorGrayscale.white,
      marginRightToLabel: 14,
    },
  }

  static propTypes = {
    className: PropTypes.string,
    handleClick: PropTypes.func,
    height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    isChecked: PropTypes.bool,
    label: PropTypes.string,
    style: PropTypes.shape({
      backgroundColor: PropTypes.shape({
        default: PropTypes.string,
        checked: PropTypes.string,
      }),
      checkMarkColor: PropTypes.string,
      marginRightToLabel: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    }),
  }

  render() {
    const {
      className,
      height,
      width,
      isChecked,
      handleClick,
      name,
      label,
    } = this.props

    const style = _.merge({}, Checkbox.defaultProps.style, this.props.style)
    const { backgroundColor, checkMarkColor, marginRightToLabel } = style

    return (
      <label>
        <Input
          type="checkbox"
          name={name}
          checked={isChecked}
          onChange={handleClick}
        />
        <VerticallyCenter>
          <Box
            className={className}
            $width={width}
            $height={height}
            $isChecked={isChecked}
            $backgroundColor={isChecked ? backgroundColor.checked : backgroundColor.default}
            $marginRightToLabel={marginRightToLabel}
          >
            <svg width={width} height={height} viewBox="0, 0, 52, 52" style={{ transform: 'scale(2) translate(-1px, -0.5px)' }}>
              <path fill="none" stroke={checkMarkColor} strokeWidth="3"
                d="m19.1 25.2 4.7 6.2 12.1-11.2" />
            </svg>
          </Box>
          <span>{label}</span>
        </VerticallyCenter>
      </label>
    )
  }
}
