import globalEnv from '../global-env'
// @twreporter
import releaseBranchConst from '@twreporter/core/lib/constants/release-branch'

const appID = {
  [releaseBranchConst.master]: '760575077441512',
  [releaseBranchConst.dev]: '1901724830098637',
  [releaseBranchConst.staging]: '1901724830098637',
  [releaseBranchConst.release]: '1296686743779808',
}[globalEnv.releaseBranch]

export default {
  appID,
}
