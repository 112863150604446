import { Helmet } from 'react-helmet-async'
import React from 'react'
// constants
import fbConst from './constants/facebook'
import siteMap from './constants/site-map'
import statusCode from './constants/status-code'
import routes from './constants/routes'
// components
import ErrorPage from './components/error-page'
import RouteWithStatus from './components/route-with-status'
import SignInPage from './containers/accounts/sign-in'
import OnboardingPage from './containers/accounts/onboarding'
import SupportAuthenticatePage from './containers/support/authenticate-page'
import SupportContributePage from './containers/support/contribute-page'
import SupportIntroPage from './components/support/intro-page'
import UpdateDonationRecordPage, { loadTheContributeRecord } from './containers/support/update-donation-record'
import VerifyLinePayCallbackPage, { loadTheContributeRecordThenVerify } from './containers/support/verify-line-pay-callback'

// utils
import { getImageUrl } from './utils/get-gcs-images'

/**
 *  Open Graph Metadata
 *  @typedef {Object} OpenGraph
 *  @property {string} title - og:title
 *  @property {string} image - og:image
 *  @property {string} url - og:url
 *  @property {string} description - og:description
 *  @property {string} type - og:type
 */

/**
 *  Facebook Metadata
 *  @typedef {Object} FacebookMetadata
 *  @property {string} appID
 */
const fb = {
  appID: fbConst.appID,
}

/**
 *  Favorite Icon
 *  @typedef {Object} Favicon
 *  @property {string} type - file type favorite icon
 *  @property {string} href - url of favorite icon
 */
const favicon = {
  type: 'image/png',
  href: '/asset/favicon.png',
}

/**
 *  Metadata of HTML
 *  @typedef {Object} Metadata
 *  @property {string} title - html.head.title
 *  @property {string} description - html.head.meta['name'='description']
 *  @property {OpenGraph} og - open graph metadata
 *  @property {FacebookMetadata} fb - facebook metadata
 *  @property {Favicon} favicon - favorite icon
 */

/**
 * Metedata of subdomains
 * @typedef {Object} SubdomainMeatadata
 * @property {Metadata} accounts - Metadata for accounts subdomain
 * @property {Metadata} support - Metadata for support subdomain
 */
const metadata = {
  accounts: {
    title: '登入會員-報導者 The Reporter',
    description: '登入成為《報導者》會員，享受更好的閱讀體驗與專屬服務。',
    og: {
      title: '登入會員-報導者 The Reporter',
      image: `${siteMap.accounts.url}/asset/open-graph/accounts-login.jpg`,
      url: `${siteMap.accounts.url}`,
      description: '登入成為《報導者》會員，享受更好的閱讀體驗與專屬服務。',
      type: 'website',
    },
    favicon,
    fb,
  },
  register: {
    title: '註冊會員-報導者 The Reporter',
    description: '註冊成為《報導者》會員，享受更好的閱讀體驗與專屬服務。',
    og: {
      title: '註冊會員-報導者 The Reporter',
      image: `${siteMap.accounts.url}/asset/open-graph/accounts-login.jpg`,
      url: `${siteMap.accounts.url}/register`,
      description: '註冊成為《報導者》會員，享受更好的閱讀體驗與專屬服務。',
      type: 'website',
    },
    favicon,
    fb,
  },
  support: {
    title: '贊助支持報導者',
    description: '《報導者》營運經費仰賴民間捐助，這股力量能讓我們不受外部勢力干預；請支持我們持續深入現場，讓更多真實、重要的議題被看見。',
    og: {
      title: '贊助支持報導者',
      image: getImageUrl('support-donation.png'),
      url: `${siteMap.support.url}/intro`,
      description: '《報導者》營運經費仰賴民間捐助，這股力量能讓我們不受外部勢力干預；請支持我們持續深入現場，讓更多真實、重要的議題被看見。',
      type: 'website',
    },
    favicon,
    fb,
  },
}

const NotFound = () => (
  <RouteWithStatus statusCode={statusCode.notFound}>
    <ErrorPage statusCode={statusCode.notFound} />
  </RouteWithStatus>
)

/**
 *  @param {SubdomainMeatadata} meta - metadata for subdomains
 *  @param {Object} Comp - React Component
 *  @return {Object} React Component wrapped with html metadata
 */
function wrapHelmet(meta, Comp) {
  return class WrappedHelemt extends React.Component {
    render() {
      return (      
        <div>
          <Helmet prioritizeSeoTags>
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
            <meta name="twitter:title" content={meta.og.title} />
            <meta name="twitter:image" content={meta.og.image} />
            <meta name="twitter:description" content={meta.og.description} />
            <meta property="og:title" content={meta.og.title} />
            <meta property="og:image" content={meta.og.image} />
            <meta property="og:type" content={meta.og.type} />
            <meta property="og:url" content={meta.og.url} />
            <meta property="og:description" content={meta.og.description} />
            <meta property="fb:app_id" content={meta.fb.appID} />
            <link rel="shortcut icon" type={meta.favicon.type} href={meta.favicon.href} />
          </Helmet>
          <Comp {...this.props} />
        </div> 
      )
    }
  }
}

const accountsRoutes = [
  {
    path: routes.root,
    exact: true,
    component: wrapHelmet(metadata.accounts, SignInPage),
  },
  {
    path: routes.signin,
    component: wrapHelmet(metadata.accounts, SignInPage),
  },
  {
    path: routes.register,
    render: () => {
      const Register = wrapHelmet(metadata.register, SignInPage)
      return <Register type={SignInPage.Type.REGISTER} />
    },
  },
  {
    path: routes.onboarding,
    component: wrapHelmet(metadata.accounts, OnboardingPage),
  },
]

const supportRoutes = [
  {
    path: routes.root,
    exact: true,
    component: wrapHelmet(metadata.support, SupportIntroPage),
  },
  {
    path: routes.intro,
    component: wrapHelmet(metadata.support, SupportIntroPage),
  },
  {
    path: routes.authenticate,
    exact: true,
    component: wrapHelmet(metadata.support, SupportAuthenticatePage),
  },
  {
    path: routes.contribute,
    exact: true,
    component: wrapHelmet(metadata.support, SupportContributePage),
  },
  {
    path: routes.lineCallback,
    component: wrapHelmet(metadata.support, VerifyLinePayCallbackPage),
    loadData: loadTheContributeRecordThenVerify,
  },
  {
    path: routes.updateDonation,
    component: wrapHelmet(metadata.support, UpdateDonationRecordPage),
    loadData: loadTheContributeRecord,
  },
]

const notFound = [
  {
    component: NotFound,
  },
]

export default {
  accounts: accountsRoutes.concat(notFound),
  support: supportRoutes.concat(notFound),
  all: accountsRoutes.concat(supportRoutes, notFound),
}
