import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// utils
import urlUtils from '../utils/url'
// constants
import accountsConst from '../constants/accounts'

export const signInApi = createApi({
  reducerPath: 'signIn',
  baseQuery: fetchBaseQuery({ baseUrl: urlUtils.formAPIURL('v3', '/auth'), timeout: accountsConst.timeout }),
  endpoints: (builder) => ({
    signInByOtp: builder.mutation({
      query: ({ otp, email }) => ({
        url: '/activate',
        method: 'POST',
        body: {
          email,
          'otp_code': otp,
        },
        withCredentials: true,
        credentials: 'include',
      }),
    }),
    signInByEmail: builder.mutation({
      query: (email) => ({
        url: '/signin',
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    resendOtp: builder.mutation({
      query: (email) => ({
        url: '/signin',
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
  }),
})

export const { useSignInByOtpMutation, useSignInByEmailMutation, useResendOtpMutation } = signInApi
