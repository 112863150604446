import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import TagManger from 'react-gtm-module'
// constants
import siteMap from '../../../constants/site-map'
// component
import Layout from '../layout-redesign'
import { Confirm } from '../confirm'
import { Share, Webapp } from './share'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { H1, H4, H5 } from '@twreporter/react-components/lib/text/headline'
import { P1 } from '@twreporter/react-components/lib/text/paragraph'
import { PillButton } from '@twreporter/react-components/lib/button'
import { TabletAndAbove } from '@twreporter/react-components/lib/rwd'
import { SnackBar, useSnackBar } from '@twreporter/react-components/lib/snack-bar'
import { colorGrayscale, COLOR_SEMANTIC } from '@twreporter/core/lib/constants/color'
import { BRANCH } from '@twreporter/core/lib/constants/release-branch'
// lodash
import get from 'lodash/get'
const _ = {
  get,
}

const bannerOverlap = 168
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colorGrayscale.gray100};
`
const Banner = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 64px ${72 + bannerOverlap}px 64px;
  background-repeat: no-repeat;
  ${mq.desktopAndAbove`
    background-image: ${props => `url(https://www.twreporter.org/assets/membership-fe/${props.$releaseBranch}/step4_banner_desktop.svg)`};
    background-position: top;
  `}
  ${mq.tabletAndBelow`
    max-width: 768px;
    padding: 48px 24px 64px 24px;
    align-items: flex-start;
  `}
  ${mq.tabletOnly`
    background-image: ${props => `url(https://www.twreporter.org/assets/membership-fe/${props.$releaseBranch}/step4_banner_tablet.png)`};
    background-position: top;
    background-size: 1050px;
  `}
  ${mq.mobileOnly`
    background-image: ${props => `url(https://www.twreporter.org/assets/membership-fe/${props.$releaseBranch}/step4_banner_mobile.png)`};
    background-position: -155px 0;
    background-size: 768px;
  `}
`
const BannerTitle = styled(H1)`
  color: ${colorGrayscale.gray800};
`
const BannerSubtitle = styled(H5)`
  color: ${colorGrayscale.gray500};
  margin: 8px 0 24px 0;
  ${mq.tabletAndBelow`
    margin: 8px 0 32px 0;
  `}
`
const BlockCss = css`
  z-index: 2;
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colorGrayscale.white};
  padding: 32px 64px;
  border-radius: 8px;
  &:last-child {
    margin: 24px 0;
  }
  ${mq.mobileOnly`
    padding: 32px 24px;
  `}
`
const ShareBlock = styled.div`
  ${BlockCss}
  ${mq.desktopAndAbove`
    transform: translateY(-${bannerOverlap}px);
  `}
`
const NewsletterBlock = styled.div`
  ${BlockCss}
  ${mq.desktopAndAbove`
    transform: translateY(-${bannerOverlap}px);
  `}
  margin-top: 24px;
  position: relative; // for star img parent
`
const BlockTitle = styled(H4)`
  text-align: center;
  color: ${colorGrayscale.gray800};
`
const BlockDescription = styled(P1)`
  text-align: center;
  color: ${colorGrayscale.gray600};
  margin: 24px 0;
`
const CallToAction = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;

  ${mq.mobileOnly`
    flex-direction: column;
  `}
`
const Note = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-right: 24px;
  margin-left: 24px;
  ${mq.desktopAndAbove`
    transform: translateY(-${bannerOverlap}px);
  `}
`
const NoteText = styled(P1)`
  text-align: center;
  color: ${colorGrayscale.gray600};
`
const NoteLink = styled.span`
  text-decoration: underline;
  color: ${COLOR_SEMANTIC.info};
  cursor: pointer;
`
const StyledShare = styled(Share)`
  ${mq.tabletAndAbove`
    margin-right: 8px;
  `}
  ${mq.mobileOnly`
    margin-bottom: 8px;
  `}
`
const SnackBarContainer = styled.div`
  position: fixed;
  bottom: 8px;
  z-index: 3;
  transition: opacity 100ms ease-in-out;
  opacity: ${props => (props.$showSnackBar ? 1 : 0)};
`
const Star = styled(TabletAndAbove)`
  position: absolute;
  top: 24px;
  right: 24px;
`
const AfterContribute = ({
  handleReturnButtonClicked,
  children,
}) => {
  useEffect(() => {
    TagManger.dataLayer({
      dataLayer: {
        event: 'purchase',
      },
    })
    window.scrollTo({ top: 0 })
  }, [])
  const releaseBranch = useSelector((state) => _.get(state, 'globalEnv.releaseBranch', BRANCH.master))
  const [ showDonation, setShowDonation ] = useState(false)
  const { toastr, showSnackBar, snackBarText } = useSnackBar()
  const editNewsletter = () => window.open(`${siteMap.main.url}/account/email-subscription`, '_blank')
  const gotoStep3 = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleReturnButtonClicked()
  }
  const bodyMarginTop = { desktop: 0, tablet: 0, mobile: 0 }
  const bodyMarginBottom = { desktop: 120 - bannerOverlap, tablet: 120, mobile: 120 }

  return (
    <Layout bodyMarginTop={bodyMarginTop} bodyMarginBottom={bodyMarginBottom}>
      <Container>
        <Banner $releaseBranch={releaseBranch}>
          <BannerTitle text="贊助成功" />
          <BannerSubtitle text="謝謝你幫助我們往前一步" />
          <PillButton text="檢視贊助資料" theme={PillButton.THEME.normal} style={PillButton.Style.LIGHT} type={PillButton.Type.PRIMARY} onClick={() => setShowDonation(true)} />
        </Banner>
        <ShareBlock>
          <BlockTitle text="贊助之後，如何表達對報導者的支持！" />
          <CallToAction>
            <StyledShare releaseBranch={releaseBranch} toastr={toastr} />
            <Webapp />
          </CallToAction>
        </ShareBlock>
        <NewsletterBlock>
          <Star>
            <img src={`https://www.twreporter.org/assets/membership-fe/${releaseBranch}/star.svg`} alt="" />
          </Star>
          <BlockTitle text="訂閱電子報，開箱《報導者》新聞室" />
          <BlockDescription text="《報導者》深度新聞精選、幕後故事、經營甘苦談，通通直達信箱！" />
          <PillButton text="前往訂閱" theme={PillButton.THEME.normal} style={PillButton.Style.BRAND} type={PillButton.Type.PRIMARY} size={PillButton.Size.L} onClick={editNewsletter} />
        </NewsletterBlock>
        <Note>
          <NoteText>
            <span>若需要變更資料，請點選
              <NoteLink onClick={gotoStep3}>
              修改贊助資料
              </NoteLink>
            ，系統將會在您資料填畢後立即更新。</span>
          </NoteText>
        </Note>
        <Confirm
          show={showDonation}
          title="贊助資料"
          message=""
          confirmBtnText="關閉"
          cancelBtnText=""
          handleConfirmed={() => setShowDonation(false)}
        >
          {children}
        </Confirm>
        <SnackBarContainer $showSnackBar={showSnackBar}>
          <SnackBar text={snackBarText} />
        </SnackBarContainer>
      </Container>
    </Layout>
  )
}
AfterContribute.propTypes = {
  handleReturnButtonClicked: PropTypes.func.isRequired,
  children: PropTypes.element,
}

export default AfterContribute
