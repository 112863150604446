import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

// @twreporter
import { H3 } from '@twreporter/react-components/lib/text/headline'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import mq from '@twreporter/core/lib/utils/media-query'

// components
import { Divider } from '../divider'
import List from './list'
import Images from './images'

// lodash
import throttle from 'lodash/throttle'

const _ = {
  throttle,
}

const ConceptContainer = styled.div`
  width: 100%;
`

const StyledH3 = styled(H3)`
  margin-bottom: 16px;
  color: ${colorGrayscale.gray800};
`

const ContentContainer = styled.div`
  margin-top: 32px;
  display: grid;
  ${mq.mobileOnly`
    grid-column-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  `}
  ${mq.tabletOnly`
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  `}
  ${mq.desktopAndAbove`
    grid-column-gap: 32px;
    grid-template-columns: repeat(8, 1fr);
  `}
`

const ListsContainer = styled.div`
  height: min-content;
  ${mq.mobileOnly`
    grid-column: 1 / 5;
  `}
  ${mq.tabletOnly`
    grid-column: 1 / 10;
  `}
  ${mq.desktopAndAbove`
    grid-column: 1 / 6;
  `}
`

const ImagesConatiner = styled.div`
  ${mq.mobileOnly`
    margin-top: 24px;
    grid-column: 1 / 5;
  `}
  ${mq.tabletOnly`
    grid-column: 10 / 13;
    height: ${props => props.$height}px;
  `}
  ${mq.desktopAndAbove`
    grid-column: 6 / 9;
    height: ${props => props.$height}px;
  `}

`

const Lists = () => {
  const lists = [
    {
      title: '堅持獨立自主',
      message: '營運僅靠民間捐款，沒有任何商業廣告、置入行銷、政府標案及政黨贊助。所有捐款者皆須接受「三不原則」：不擁有、不干預、不回收。',
    },
    {
      title: '重視公共價值',
      message: '關注被傳統媒體忽視的社會議題，以深度、長篇報導探討事件背後的結構性問題，期許能貼近社會脈動、維護公共利益。',
    },
    {
      title: '深入新聞現場',
      message: '秉持新聞專業，要求記者走入各地，進行長時間的田野調查、採訪事件相關利害當事人，並做好各項追蹤查證。',
    },
    {
      title: '注重社會影響力',
      message: '新聞產製內容著重於監督政府施政、改變政策法令等實質影響力；網站不顯示點閱數字，避免記者陷入點閱率的漩渦。',
    },
  ]
  return lists.map(({ title, message }, idx) => 
    <List index={`${idx+1}`} title={title} message={message} key={idx} />
  )
}

const Concept = () => {
  const [ height, setHeight ] = useState(0)
  const elementRef = useRef(null)

  useEffect(() => {
    const handleResize = _.throttle(() => {
      setHeight(elementRef.current.offsetHeight)
    }, 150)
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <ConceptContainer>
      <StyledH3 text={'改變社會，從媒體開始'} />
      <Divider />
      <ContentContainer>
        <ListsContainer ref={elementRef}>
          <Lists />
        </ListsContainer>
        <ImagesConatiner $height={height}>
          <Images />
        </ImagesConatiner>
      </ContentContainer>
    </ConceptContainer>
  )
}
export default Concept
