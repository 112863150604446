import React from 'react'
import styled from 'styled-components'

// @twreporter
import { TextButton, PillButton } from '@twreporter/react-components/lib/button'
import { TabletAndAbove } from '@twreporter/react-components/lib/rwd'
import { DONATION_LINK_ANCHOR } from '@twreporter/core/lib/constants/donation-link-anchor'

// utils
import { customizeSmoothScroll } from '../../../../utils/customize-smooth-scroll'

const ListsContainer = styled.div`
  display: flex;
  align-items: center;
`
const AnchorContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

const AnchorButton = styled(TextButton)`
  margin-right: 24px;
`

const anchorsData = [
  {
    title: '我們的理念',
    target: DONATION_LINK_ANCHOR.value,
  },
  {
    title: '報導影響力',
    target: DONATION_LINK_ANCHOR.impact,
  },
  {
    title: '常見問題',
    target: DONATION_LINK_ANCHOR.faq,
  },
]

const handleClick = (id) => {
  const el = document.getElementById(id)
  customizeSmoothScroll(el)
}

const Lists = () => {
  return (
    <ListsContainer>
      <TabletAndAbove>
        <AnchorContainer>
          {anchorsData.map((anchor, idx) => {
            return (
              <AnchorButton key={idx} size={TextButton.Size.L} text={anchor.title} onClick={() => handleClick(anchor.target)}/>
            )
          })}
        </AnchorContainer>
      </TabletAndAbove>
      <PillButton theme={PillButton.THEME.normal}  text={'支持方案'} onClick={() => handleClick(DONATION_LINK_ANCHOR.plan)}></PillButton>
    </ListsContainer>
  )
}

export default Lists
