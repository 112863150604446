import { useRef, useState, useEffect } from 'react'
// dayjs
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

/*
 * parameter:
 *   time(object): { <type>: <number> }
 *     countdown time, support format in dayjs (https://day.js.org/docs/en/manipulate/add)
 *   format(string): output date format
 *   onEnd(func): countdown end callback
 */
const defaultFunc = () => {}
const useCountdown = (time, format = 'mm:ss', onEnd = defaultFunc) => {
  if (!time || typeof time !== 'object') {
    throw 'Time invalid: should be object.'
  }

  const counterId = useRef(0)
  const [ start, setStart ] = useState(false)
  const [ remain, setRemain ] = useState(dayjs.duration(time))
  useEffect(() => {
    if (!start) {
      return
    }
    if (remain.asSeconds() > 0) {
      counterId.current = window.setInterval(() => {
        setRemain(remain.subtract(1, 'seconds'))
      }, 1000)
    } else {
      onEnd()
      resetCount()
    }

    return () => clearInterval(counterId.current)
  }, [ remain, start ])

  const startCount = () => setStart(true)
  const resetCount = () => {
    setStart(false)
    setRemain(dayjs.duration(time))
  }

  return { remain: remain.format(format), startCount, isStartCount: start }
}

export default useCountdown
