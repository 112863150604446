import PropTypes from 'prop-types'
import React from 'react'
import bsc from '../basic-styled-components'
import mediaQuery from '@twreporter/core/lib/utils/media-query'
import styled from 'styled-components'
import typography from '../../constants/typography'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

const NextStepDiv = styled(bsc.CenteredBoxModel)`
  color: ${colorGrayscale.white};
  opacity: ${props => props.$disabled ? 0.3 : 1};
  background-color: ${props => props.$backgroundColor};
  font-size: 18px;
  font-weight: ${typography.font.weight.bold};
  line-height: 65px;
  cursor: ${props => props.$disabled ? 'auto' : 'pointer'};
  text-align: ${props => props.$textAlign};
  position: relative;
  padding-left: ${props => props.$textAlign === 'left' ? '20px' : 0};

  ${mediaQuery.tabletOnly`
    max-width: 620px;
  `}

  ${mediaQuery.desktopAndAbove`
    max-width: 740px;
  `}
`

const TranslateXDiv = styled(bsc.AbsoluteVerticalCenter)`
  transition: transform 0.1s ease-in;
  transform: translate(${props => props.$toTranslate ? '5px': '0' }, -50%);
  right: 20px;
`

class NextStep extends React.PureComponent {
  static defaultProps = {
    disabled: true,
    message: '',
    textAlign: 'left',
    handleClicked: () => {},
    backgroundColor: colorGrayscale.black,
  }

  static propTypes = {
    disabled: PropTypes.bool,
    message: PropTypes.string,
    textAlign: PropTypes.oneOf([ 'center', 'left' ]),
    handleClicked: PropTypes.func.isRequired,
    backgroundColor: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      toTranslateXArrow: false,
    }

    this.handleMouseOver = this._handleHover.bind(this, true)
    this.handleMouseOut = this._handleHover.bind(this, false)
  }

  _handleHover(isHovered) {
    this.setState({
      toTranslateXArrow: isHovered,
    })
  }

  render() {
    const { disabled, message, textAlign, handleClicked, backgroundColor } = this.props
    const { toTranslateXArrow } = this.state
    const conditionalProps = disabled ? {} : {
      onMouseOver: this.handleMouseOver,
      onMouseOut: this.handleMouseOut,
      onClick: handleClicked,
    }
    return (
      // class 'next-step' is provided as css selector,
      // others can overwrite the styles by this css selector
      <NextStepDiv
        className="next-step"
        $disabled={disabled}
        $textAlign={textAlign}
        $backgroundColor={backgroundColor}
        {...conditionalProps}
      >
        {message}
        <TranslateXDiv
          $toTranslate={toTranslateXArrow}
        >
          <svg height="16" width="18">
            <polyline points="8,0 16,8 8,16"x1="0" style={{ stroke:'white', strokeWidth:2, strokeLinejoin: 'miter', fill: 'none' }} />
            <line x1="0" y1="8" x2="16" y2="8" style={{ stroke:'white', strokeWidth:2, strokeLinejoin: 'miter' }} />
          </svg>
        </TranslateXDiv>
      </NextStepDiv>
    )
  }
}

export default NextStep
