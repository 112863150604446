import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import styled from 'styled-components'
// constants
import typography from '../../../constants/typography'
import colors from '../../../constants/colors'

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 319px;
  border: 1px solid ${props => props.$borderColor};
  resize: none;
  font-size: 18px;
  padding: 20px;
  background: ${props => props.$backgroundColor};
  color: ${props => props.$color};
  font-weight: ${typography.font.weight.bold};
  line-height: 26px;
  letter-spacing: 1.125px;
  &:focus {
    outline: none;
  }
  &:placeholder {
    color: ${props => props.$placeholderColor};
  }
`

const Textarea = ({
  placeholder = '',
  value = '',
  name,
  //validate,
  handleChange,
  maxLength,
  disabled = false,
  invalid = false,
}) => {
  const [ isHovered, setIsHovered ] = useState(false)
  const [ isFocused, setIsFocused ] = useState(false)
  const textareaRef = useRef()
  const colorSet = colors.input

  const handleMouseOver = () => setIsHovered(true)
  const handleMouseOut = () => setIsHovered(false)
  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  const getCurrentColorSet = () => {
    // CAUTION: The order matters. First matched first returned.
    if(disabled) {
      return colorSet.disabled
    } else if(isFocused) {
      return colorSet.focus
    } else if(isHovered) {
      return colorSet.hover
    } else if(invalid) {
      return colorSet.invalid
    } else if(value) {
      return colorSet.active
    } else {
      return colorSet.default
    }
  }

  const { color, backgroundColor, borderColor, placeholderColor } = getCurrentColorSet()

  return (
    <StyledTextarea
      ref={textareaRef}
      name={name}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      maxLength={maxLength}
      $placeholderColor={placeholderColor}
      $backgroundColor={backgroundColor}
      $borderColor={borderColor}
      $color={color}
    />
  )
}
Textarea.propTypes = {
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
}

export default Textarea
