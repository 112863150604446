import React from 'react'
import styled from 'styled-components'

// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import mq from '@twreporter/core/lib/utils/media-query'
import { DesktopAndAbove } from '@twreporter/react-components/lib/rwd'
import Link from '@twreporter/react-components/lib/customized-link'
import { LogoHeader } from '@twreporter/react-components/lib/logo'

// env
import env from '../../../../global-env'
// constants
import siteMap from '../../../../constants/site-map'

// components
import Slogan from './slogan'
import Lists from './lists'

const HeaderContainer = styled.div`
  z-index: 10;
  position: sticky;
  top: 0px;
  width: 100vw;
  display: flex;
  height: 64px;
  background-color: ${colorGrayscale.gray100};
  ${mq.hdOnly`
    justify-content: center;
  `}
  ${mq.desktopOnly`
    padding: 24px 48px;
  `}
  ${mq.tabletOnly`
    padding: 24px 32px;
    position: fixed;
  `}
  ${mq.mobileOnly`
    padding: 16px 24px;
    position: fixed;
  `}
`

const HeaderContent = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  ${mq.hdOnly`
    margin: auto;
  `}
`

const FlexColumn = styled.div`
  display: flex;
  align-items: center;
`

const LogoContainer = styled.div`
  margin-right: 24px;
  a {
    height: 24px;
    display: flex;
  }
  ${mq.tabletAndBelow`
    margin-right: 0px;
    a {
      height: 21px;
    }
  `}
`

const IntroPageHeader = () => {
  return (
    <HeaderContainer>
      <HeaderContent>
        <FlexColumn>
          <LogoContainer>
            <Link isExternal={true} to={siteMap.main.url}>
              <LogoHeader releaseBranch={env.releaseBranch} />
            </Link>
          </LogoContainer>
          <DesktopAndAbove>
            <Slogan />
          </DesktopAndAbove>
        </FlexColumn>
        <FlexColumn>
          <Lists />
        </FlexColumn>
      </HeaderContent>
    </HeaderContainer>
  )
}

export default IntroPageHeader
