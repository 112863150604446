import React from 'react'
import styled from 'styled-components'

// @twreporter
import { H3 } from '@twreporter/react-components/lib/text/headline'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import { P1 } from '@twreporter/react-components/lib/text/paragraph'
import mq from '@twreporter/core/lib/utils/media-query'

// components
import { Divider } from '../divider'
import Box from './box'

// utils
import { getImageUrl } from '../../../../utils/get-gcs-images'

const FundingContainer = styled.div``

const StyledH3 = styled(H3)`
  margin-bottom: 16px;
  color: ${colorGrayscale.gray800};
`

const ContentContainer = styled.div`
  margin-top: 24px;
  display: grid;
  ${mq.mobileOnly`
    grid-column-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  `}
  ${mq.tabletOnly`
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  `}
  ${mq.desktopAndAbove`
    grid-column-gap: 32px;
    grid-template-columns: repeat(9, 1fr);
  `}
`

const StyledP1 = styled(P1)`
  color: ${colorGrayscale.gray800};
  padding-bottom: 32px;
  ${mq.mobileOnly`
    grid-column: 1 / 5;
  `}
  ${mq.tabletOnly`
    grid-column: 1 / 10;
  `}
  ${mq.desktopAndAbove`
    grid-column: 1 / 6;
  `}
`

const BoxsContainer = styled.div`
  display: grid;
  ${mq.mobileOnly`
    grid-column: 1 / 5;
    grid-gap: 8px;
  `}
  ${mq.tabletAndAbove`
    grid-column: 1 / 13;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  `}

`

const Boxs = () => {
  const boxs = [
    {
      iconUrl: getImageUrl('illustration_1.svg'),
      title: '新聞產製與內容',
      content: '除了調查報導的高額成本，我們也投入國際新聞、Podcast、報導漫畫等內容與形式的創新。',
    },
    {
      iconUrl: getImageUrl('illustration_2.svg'),
      title: '數位內容與產品開發',
      content: '致力於數位策展、互動影音、新聞遊戲等多媒體敘事，並持續開發所有公眾都能使用的新聞工具。',
    },
    {
      iconUrl: getImageUrl('illustration_3.svg'),
      title: '打造兒少新聞平台',
      content: '與10～15歲的小朋友討論重要社會議題，培育下一代的公民意識與媒體素養。',
    },
    {
      iconUrl: getImageUrl('illustration_4.svg'),
      title: '培育新聞專業人才',
      content: '連年舉辦工作坊，讓有志投入新聞工作的年輕人累積專業，也為新聞產業培養生力軍。',
    },
  ]
  return boxs.map(({ iconUrl, title, content }, idx) => {
    return (
      <Box key={idx} iconUrl={iconUrl} title={title} content={content} />
    )
  })
}

const Funding = () => {
  return (
    <FundingContainer>
      <StyledH3 text={'我們如何運用經費'} />
      <Divider />
      <ContentContainer>
        <StyledP1 text={'專業的新聞團隊，除了前往第一線的優秀記者、攝影，還需要編輯、設計、工程、營運等跨領域人才投入，你的支持能讓我們開展更多重要新聞工作。'} />
        <BoxsContainer>
          <Boxs />
        </BoxsContainer>
      </ContentContainer>
    </FundingContainer>
  )
}

export default Funding
