export default {
  // contribute
  RESET_CONTRIBUTE_STATE: 'reset_contribute_state',
  REQUEST_CONTRIBUTE: 'request_contribute',
  CONTRIBUTE_SUCCESS: 'contribute_success',
  CONTRIBUTE_FAILURE: 'contribute_failure',

  // update contribution record
  UPDATE_CONTRIBUTE_RECORD_PRE_REQUEST_VALIDATION_FAILURE: 'update_contribute_record_pre_request_validation_failure',
  UPDATE_CONTRIBUTE_RECORD_REQUEST: 'update_contribute_record_request',
  UPDATE_CONTRIBUTE_RECORD_SUCCESS: 'update_contribute_record_success',
  UPDATE_CONTRIBUTE_RECORD_FAILURE: 'update_contribute_record_failure',

  // get one contribution record
  GET_ONE_CONTRIBUTE_RECORD_REQUEST: 'get_one_contribute_record_request',
  GET_ONE_CONTRIBUTE_RECORD_SUCCESS: 'get_one_contribute_record_success',
  GET_ONE_CONTRIBUTE_RECORD_FAILURE: 'get_one_contribute_record_failure',
  
  // get line pay verification information 
  getLinePayVerificationInformationRequest: 'get_line_pay_verification_information_request',
  getLinePayVerificationInformationSuccess: 'get_line_pay_verification_information_success',
  getLinePayVerificationInformationFailure: 'get_line_pay_verification_information_failure',

  // verify line pay contribution record
  verifyLinePayContributeRecordSuccess: 'verify_line_pay_contribute_record_success',
  verifyLinePayContributeRecordFailure: 'verify_line_pay_contribute_record_failure',
  verifyLinePayContributeRecordUnknown: 'verify_line_pay_contribute_record_unknown',
  
  // get one contribution record
  getTappayRecordRequest: 'get_tappay_record_request',
  getTappayRecordSuccess: 'get_tappay_record_success',
  getTappayRecordFailure: 'get_tappay_record_failure',

  // handle the line pay record status get from tappay server
  recordStatusIsKnownPaid: 'record_status_is_known_paid',
  recordStatusIsKnownFail: 'record_status_is_known_fail',

  // handle the content rendered under contribution
  contribute: {
    changeRenderedStep: 'change_rendered_step',
  },

  // signin
  SHOW_SIGN_IN_BY_EMAIL_PAGE: 'show_sign_in_by_email_page',
  REQUEST_SIGN_IN_BY_EMAIL: 'request_sign_in_by_email',
  SENT_SIGN_IN_EMAIL: 'sent_sign_in_email',
  FAIL_TO_SEND_SIGN_IN_EMAIL: 'fail_to_send_sign_in_email',
  // otp
  REQUEST_SEND_OTP: 'request_send_otp',
  SENT_OTP: 'sent_otp',
  FAIL_TO_SEND_OTP: 'fail_to_send_otp',
  REQUEST_SIGN_IN_BY_OTP: 'request_sign_in_by_otp',
  SIGN_IN_BY_OTP_SUCCESS: 'sign_in_by_otp_success',
  FAIL_TO_SIGN_IN_BY_OTP: 'fail_to_sign_in_by_otp',
}
