import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import set from 'lodash/set'

const _ = {
  set,
}

const _initialHtmlOverflow = 'visible'
const _initialBodyOverflow = 'visible'

export default class ScreenScrollLock extends PureComponent {
  static propTypes = {
    lock: PropTypes.bool,
  }

  static defaultProps = {
    lock: false,
  }

  componentDidMount() {
    if (typeof document !== 'undefined') {
      this._htmlDOM = document.documentElement
      this._bodyDOM = document.body
      this._toggleScreenLock(this.props.lock)
    }
  }

  componentDidUpdate() {
    this._toggleScreenLock(this.props.lock)
  }

  componentWillUnmount() {
    this._toggleScreenLock(false)
    // clear cached dom ref
    this._htmlDOM = null
    this._bodyDOM = null
  }

  _toggleScreenLock(lockOrNot) {
    _.set(this._htmlDOM, 'style.overflow', lockOrNot ? 'hidden' : _initialHtmlOverflow)
    _.set(this._bodyDOM, 'style.overflow', lockOrNot ? 'hidden': _initialBodyOverflow)
  }

  render() {
    return null
  }
}
