import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// context
import { AuthContext } from '../../contexts'
// actions
import { useSignInByEmailMutation } from '../../actions/sign-in-by-email' 
// component
import Error from './error'
// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import { H3 } from '@twreporter/react-components/lib/text/headline'
import { P1 } from '@twreporter/react-components/lib/text/paragraph'
import { PillButton, TextButton } from '@twreporter/react-components/lib/button'
import { Arrow } from '@twreporter/react-components/lib/icon'
import { TextField } from '@twreporter/react-components/lib/input'
import MeterialSymbol from '@twreporter/react-components/lib/material-icon'

const ErrorBox = styled(Error)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  margin: auto;
  min-height: calc(100vh - 64px); // header height 64px
  padding-bottom: 64px;
`
const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
const MailIcon = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorGrayscale.white};
  color: ${colorGrayscale.gray600};
`
const Title = styled(H3)`
  color: ${colorGrayscale.gray800};
  margin: 24px 0 8px 0;
`
const SubTitle = styled(P1)`
  color: ${colorGrayscale.gray600};
  text-align: center;
`
const EmailInput = styled(TextField)`
  padding: 0 8px;
  width: 100%;
  margin: 48px 0;
`
const ConfirmButton = styled(PillButton)`
  width: 100%;
  display: flex;
  justify-content: center;
`
const BackButton = styled(TextButton)`
  margin-top: 24px;
`
const defaultFunc = () => {}
export const SignInByEmail = ({
  backToDashboard,
  typeWording = '',
  onSuccess = defaultFunc,
  ...props
}) => {
  const [ inputEmail, setInputEmail ] = useState('')
  const [ inputValid, setInputValid ] = useState(false)
  const [ inputShowError, setInputShowError ] = useState(false)
  const { releaseBranch } = useContext(AuthContext)
  const [ signInByEmail, { isLoading, isError, isSuccess } ] = useSignInByEmailMutation()
  const onSubmit = async () => {
    if (!inputValid || isLoading) {
      return
    }
    await signInByEmail(inputEmail)
  }
  useEffect(() => {
    if (isSuccess) {
      onSuccess(inputEmail)
    }
  }, [ isSuccess ])
  const onChange = (value, isValid) => {
    setInputEmail(value)
    setInputValid(isValid)
  }
  const onBlur = () => {
    setInputShowError(!inputValid)
  }

  if (isError) {
    return (
      <ErrorBox
        title="OOOOOOOOPS！"
        subtitle="糟糕，系統出現錯誤！"
        message={`請聯絡報導者數位產品部：developer@twreporter.org${'\n'}將盡速為您解決問題。`}
      />
    )
  }
  return (
    <Container {...props}>
      <MailIcon>
        <MeterialSymbol fill={false} weight={600} grade={0} size={32} icon="mail" />
      </MailIcon>
      <Title text="輸入電子信箱" />
      <SubTitle text="我們會將驗證碼寄送給您" />
      <EmailInput
        type="email"
        placeholder="example@mail.com"
        align={TextField.Align.CENTER}
        onSubmit={onSubmit}
        onChange={onChange}
        onBlur={onBlur}
        state={inputShowError ? TextField.State.ERROR : TextField.State.DEFAULT}
        message={inputShowError ? '電子信箱格式錯誤，請重新輸入' : ''}
        autoFocus
      />
      <ConfirmButton
        type={PillButton.Type.PRIMARY}
        style={PillButton.Style.DARK}
        size={PillButton.Size.L}
        text="確認"
        loading={isLoading}
        onClick={onSubmit}
      />
      <BackButton
        text={`其他${typeWording}方式`}
        size={TextButton.Size.L}
        style={TextButton.Style.LIGHT}
        leftIconComponent={<Arrow direction={Arrow.Direction.LEFT} releaseBranch={releaseBranch} />}
        onClick={backToDashboard}
      />
    </Container>
  )
}
SignInByEmail.propTypes = {
  typeWording: PropTypes.string,
  backToDashboard: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
}

export default SignInByEmail
