import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import supportConsts from '../../../../constants/support'

const CardIcons = styled.div`
  > img {
    margin-left: 3px;
    width: 37px;
  }
`

const SupportedCreditCards = ({ cardType = supportConsts.contribute.cardType.unknown }) => {
  return (
    <CardIcons>
      <img src={`/asset/icon-creditcard-mastercard${cardType === supportConsts.contribute.cardType.mastercard ? '' : '-grey'}.svg`} />
      <img src={`/asset/icon-creditcard-jcb${cardType === supportConsts.contribute.cardType.jcb ? '' : '-grey'}.svg`} />
      <img src={`/asset/icon-creditcard-visa${cardType === supportConsts.contribute.cardType.visa ? '' : '-grey'}.svg`} />
    </CardIcons> 
  )
}

SupportedCreditCards.propTypes = {
  cardType: PropTypes.string, 
}

export default SupportedCreditCards

