import { colorGrayscale, colorBrand, colorSupportive } from '@twreporter/core/lib/constants/color'

const basic = {
  gray50: '#f9f9f9',
  blue: '#2f3ba2',
  facebook: '#4267b2',
  line: '#1dc246',
}

const modal = {
  text: colorGrayscale.gray800,
  linkButton: {
    normal: colorGrayscale.gray500,
    hover: colorGrayscale.gray800,
  },
  secondaryButton: {
    normal: colorSupportive.heavy,
    hover: colorSupportive.heavy,
  },
}

const input = {
  default: {
    color: colorGrayscale.gray500,
    backgroundColor: basic.gray50,
    borderColor: colorGrayscale.gray300,
    placeholderColor: colorGrayscale.gray600,
  },
  hover: {
    color: colorGrayscale.gray500,
    backgroundColor: basic.gray50,
    borderColor: colorGrayscale.gray900,
    placeholderColor: colorGrayscale.gray500,
  },
  focus: {
    color: colorGrayscale.gray900,
    backgroundColor: basic.gray50,
    borderColor: colorGrayscale.gray900,
    placeholderColor: colorGrayscale.gray500,
  },
  disabled: {
    color: colorGrayscale.gray400,
    backgroundColor: basic.gray50,
    borderColor: colorGrayscale.gray300,
    placeholderColor: colorGrayscale.gray500,
  },
  active: {
    color: colorGrayscale.black,
    backgroundColor: basic.gray50,
    borderColor: colorGrayscale.gray300,
    placeholderColor: colorGrayscale.gray500,
  },
  invalid: {
    color: colorBrand.heavy,
    backgroundColor: basic.gray50,
    borderColor: colorBrand.heavy,
    placeholderColor: colorGrayscale.gray500,
  },
}

const select = input

export default {
  ...basic,
  modal,
  input,
  select,
}
