/* eslint no-console:0 */
import React from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { BrowserRouter, Route } from 'react-router-dom'
import reduxStore from './store'
import { Provider } from 'react-redux'
import globalEnv from './global-env'
import '@material-symbols/font-400/outlined.css'
import 'swiper/swiper.min.css'
import { HelmetProvider } from 'react-helmet-async'

// utils
import urlUtils from './utils/url'
// components
import App from './app'
import ScrollToTopAndSendPageView from './utils/scroll-to-top-and-send-page-view'
// @twreporter
import releaseBranchConsts from '@twreporter/core/lib/constants/release-branch'
// lodash
import get from 'lodash/get'
const _ = {
  get,
}

const { releaseBranch } = globalEnv
const tagManagerArgs = {
  [releaseBranchConsts.master]: {
    gtmId: 'GTM-PRMXBBN',
    auth: '2pJC7GotZqWa7HtmIgSFIg',
    preview: 'env-231',
  },
  [releaseBranchConsts.dev]: {
    gtmId: 'GTM-PRMXBBN',
    auth: 'XFsQ67nTp2wXWpJllmNBCQ',
    preview: 'env-229',
  },
  [releaseBranchConsts.staging]: {
    gtmId: 'GTM-PRMXBBN',
    auth: 'XFsQ67nTp2wXWpJllmNBCQ',
    preview: 'env-229',
  },
  [releaseBranchConsts.release]: {
    gtmId: 'GTM-PRMXBBN',
  },
}

let reduxState
if (window.__REDUX_STATE__) {
  reduxState = window.__REDUX_STATE__
}
const store = reduxStore.initializeStore(reduxState)

if (typeof window !== 'undefined') {
  // add Google Tag Manager
  TagManager.initialize(tagManagerArgs[releaseBranch])
}

function sendGtmUserId() {
  if (!store) {
    return null
  }
  const currentState = store.getState()
  const userId = _.get(currentState, [ 'auth', 'userInfo', 'user_id' ], '')
  if (userId) {
    TagManager.dataLayer({
      dataLayer: {
        userId,
      },
    })
  }
  return null
}

const jsx = (
  <HelmetProvider>
    <Provider store={store}>
      <BrowserRouter>
        <React.Fragment>
          <Route path="/" component={ScrollToTopAndSendPageView} />
          <Route path="/" component={sendGtmUserId} />
          <App subdomain={urlUtils.getSubdomain(window.location.hostname)} />
        </React.Fragment>
      </BrowserRouter>
    </Provider>
  </HelmetProvider>
)
const container = document.getElementById('app')
const root = createRoot(container)
// todo: use `hydrateRoot` after resolving react issue #418 & #423
root.render(jsx)
