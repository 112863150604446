import PropTypes from 'prop-types'
import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// actions
import { setFields } from '../../../actions/update-record'
// constants
import supportConsts from '../../../constants/support'
import wellDefinedPropTypes from '../../../constants/prop-types'
// components
import OrganizationForm from './render-organization-form'
import PersonForm from './render-person-form'
// lodash
import get from 'lodash/get'
const _ = {
  get,
}

const Form = ({
  frequency,
  userData,
  updateRecord,
  defaultValues = {
    first_name: '',
    last_name: '',
    is_anonymous: true,
    send_receipt: supportConsts.contribute.sendReceipt.no,
    phone_number: '',
    security_id: '',
    address: {
      country: '',
      state: '',
      city: '',
      elseString: '',
      zip_code: '',
    },
    receipt_header: '',
    receipt_security_id: '',
    receipt_address: {
      country: '',
      state: '',
      city: '',
      elseString: '',
      zip_code: '',
    },
    autofill_with_donor_info: false,
    nickname: '',
    gender: supportConsts.contribute.gender.female,
    age: supportConsts.contribute.age['less_than_18'],
    words_for_twreporter: '',
  },
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setFields(defaultValues))
  }, [])
  const role = useSelector((state) => _.get(state, 'updateForm.role'))
  const FormComponent = role === supportConsts.contribute.role.person ? PersonForm : OrganizationForm

  return (
    <Fragment>
      <FormComponent
        frequency={frequency}
        updateRecord={updateRecord}
        userData={userData}
      />
    </Fragment>
  )
}

Form.propTypes = {
  frequency: wellDefinedPropTypes.contributeFrequency.isRequired,
  updateRecord: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
}

export default Form
