import React, { useState, useMemo, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import env from '../../global-env'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
// utils
import { refreshAccessToken } from '../../utils/refresh-token'
// enum
import { SigninType } from '../../enum'
// components
import SimpleHeader from '../../components/simple-header'
// @twreporter
import {
  colorBrand,
  colorGrayscale,
} from '@twreporter/core/lib/constants/color'
import mq from '@twreporter/core/lib/utils/media-query'
import Divider from '@twreporter/react-components/lib/divider'
import { H4 } from '@twreporter/react-components/lib/text/headline'
import { P1, P2 } from '@twreporter/react-components/lib/text/paragraph'
import { Arrow, Loading } from '@twreporter/react-components/lib/icon'
import { Badge } from '@twreporter/react-components/lib/badge'
import { Checkbox } from '@twreporter/react-components/lib/checkbox'
import { PillButton, TextButton, LinkButton } from '@twreporter/react-components/lib/button'
import { READ_PREFERENCE_ORDER, READ_PREFERENCE, READ_PREFERENCE_ZH_TW } from '@twreporter/core/lib/constants/read-preference'
import twreporterRedux from '@twreporter/redux'
import {
  SnackBar,
  useSnackBar,
} from '@twreporter/react-components/lib/snack-bar'
import requestOrigin from '@twreporter/core/lib/constants/request-origins'
// lodash
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
const _ = {
  get,
  map,
  filter,
}
// enum
const Step = Object.freeze({ NEWSLETTER: 'newsletter', CATEGORY: 'category' })

const settings = {
  [Step.NEWSLETTER]: {
    title: { textL1: { [SigninType.REGISTER]: '註冊成功', [SigninType.LOGIN]: '登入成功' }, textL2: '現在訂閱電子報' },
    options: [
      {
        text: '報導者精選',
        desc:
          '由《報導者》編輯台精選近兩週的最新報導，和我們一起看見世界上正在發生的、重要的事。',
        label: '雙週',
      },
      {
        text: '採訪幕後故事',
        desc:
          '總是好奇記者們如何深入現場，採訪過程中又有哪些不為人知的故事嗎？我們會不定期分享給你。',
        label: '不定期',
      },
      {
        text: '報導者營運手記',
        desc:
          '一路走來，各個決策有什麼背後故事，團隊又是過著怎樣的工作日常？一起來開箱報導者團隊！',
        label: '不定期',
      },
    ],
    subscribeUrl: 'http://eepurl.com/djVwF9',
  },
  [Step.CATEGORY]: {
    title: {
      text: '你感興趣的議題',
      desc: '請勾選你感興趣的議題，讓我們更了解你',
    },
    options: _.map(READ_PREFERENCE_ORDER, key => {
      return {
        label: READ_PREFERENCE_ZH_TW[key],
        value: key,
      }
    }),
  },
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${colorGrayscale.gray100};
`

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${colorGrayscale.gray100};

  ${mq.tabletAndAbove`
    @media (min-height: 800px) {
      padding-top: calc(50vh - 312px - 64px);
    }
    padding-top: 24px;
    padding-bottom: 48px;
  `}

  ${mq.mobileOnly`
    padding: 24px 24px 48px 24px;
  `}
`

const Content = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid ${colorGrayscale.gray300};
  border-radius: 8px;
  ${mq.mobileOnly`
    width: calc(100% - 48px);
  `}
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 16px;
`

const NewsletterOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
  max-width: 312px;
`

const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 342px;
`

const OptionTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`

const CategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 294px;
  margin-top: 16px;
  margin-bottom: 40px;
`

const TripletContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
`

const CategoryOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
`

const CategoryAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  padding-left: 16px;
`

const Control = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
`

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  ${props => props.$disabled ? 'pointer-events: none;' : ''}
`

const H4Gray800 = styled(H4)`
  color: ${colorGrayscale.gray800};
`

const P1Gray600 = styled(P1)`
  color: ${colorGrayscale.gray600};
  padding-top: 8px;
`

const P1Gray800 = styled(P1)`
  color: ${colorGrayscale.gray800};
`

const P2Gray600 = styled(P2)`
  color: ${colorGrayscale.gray600};
`

const BadgeComponent = styled(Badge)`
  margin-left: 8px;
`

const SnackBarContainer = styled.div`
  z-index: 1;
  position: fixed;
  transition: opacity 100ms;
  opacity: ${props => (props.$showSnackBar ? 1 : 0)};
  max-width: 440px;
  width: 100%;
  left: 50%;
  ${mq.desktopAndAbove`
    bottom: 24px;
    transform: translateX(-50%);
  `}
  ${mq.tabletAndBelow`
    bottom: calc(env(safe-area-inset-bottom, 0) + 8px);
    transform: translateX(-50%);
    padding: 0 16px;
  `}
`

const SnackBarDiv = styled.div`
  display: flex;
  justify-content: center;
`

const LoadingBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

const spin = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`
const LoadingImg = styled(Loading)`
  margin-bottom: 24px;
  animation: ${spin} 1s linear infinite;
`

const SubscribeButton = styled(PillButton)`
  margin: 32px 0 16px 0;
  width: 182px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BackButton = styled(LinkButton)`
  color: ${colorGrayscale.gray600}!important;
`

const CategorySetting = ({
  isLoading = false,
  onFinish,
}) => {
  const [ categorySelections, setCategorySelections ] = useState(
    new Array(settings.category.options.length).fill(false)
  )
  const [ isAllCategoriesSelected, setIsAllCategoriesSelected ] = useState(false)
  const { title, options } = settings[Step.CATEGORY]

  const onClickCategorySelections = (index, value) => {
    const selections = [ ...categorySelections ]
    const newSelection = selections[index] ? false : value
    selections[index] = newSelection
    setCategorySelections(selections)
    setIsAllCategoriesSelected(newSelection && selections.every(isSelected => isSelected !== false))
  }

  const onClickAllCategorySelections = () => {
    const newIsAllCategoriesSelected = !isAllCategoriesSelected
    if (newIsAllCategoriesSelected) {
      setCategorySelections([ ...Object.values(READ_PREFERENCE) ])
    } else {
      setCategorySelections(new Array(settings.category.options.length).fill(false))
    }
    setIsAllCategoriesSelected(newIsAllCategoriesSelected)
  }

  // prepare options
  const checkboxArray = options.map((option, index) => (
    <Checkbox
      key={`category-option-${index}`}
      value={!!categorySelections[index]}
      label={option.label}
      onChange={() => onClickCategorySelections(index, option.value)}
      disabled={isLoading}
    />
  ))
  // Generate a [checkbox1, checkbox2, checkbox3] triplet for UI
  const chunk = 3
  const optionsJSX = []
  for (let i = 0; i < checkboxArray.length; i += chunk) {
    optionsJSX.push(
      <TripletContainer key={`triplet-row-${i}`}>
        {checkboxArray.slice(i, i + chunk)}
      </TripletContainer>
    )
  }

  const onSubmit = () => onFinish(categorySelections)

  return (
    <Content>
      <TitleContainer>
        <H4Gray800 text={title.text} />
        <P1Gray600 text={title.desc} />
      </TitleContainer>
      <Divider/>
      <Options>
        <CategoryContent>
          <CategoryOptionsContainer>
            {optionsJSX}
          </CategoryOptionsContainer>
          <CategoryAllContainer>
            <Checkbox
              value={isAllCategoriesSelected}
              label="以上皆是我感興趣的議題"
              onChange={onClickAllCategorySelections}
              disabled={isLoading}
            />
          </CategoryAllContainer>
        </CategoryContent>
      </Options>
      <Divider/>
      <Control>
        <StepContainer $disabled={true} />
        <StepContainer $disabled={isLoading} onClick={onSubmit}>
          <TextButton loading={isLoading} text="下一步" rightIconComponent={<Arrow direction={Arrow.Direction.RIGHT} releaseBranch={env.releaseBranch} />} size={TextButton.Size.L} theme={TextButton.THEME.normal} style={TextButton.Style.BRAND} />
        </StepContainer>
      </Control>
    </Content>
  )
}

const NewsletterSetting = ({ redirect, type }) => {
  const { title, options, subscribeUrl } = settings[Step.NEWSLETTER]
  const optionsJSX = options.map((option, index) => (
    <NewsletterOptionsContainer key={`newsletter-option-${index}`}>
      <OptionContent>
        <OptionTitle>
          <P1Gray800
            text={option.text}
            weight={P1.Weight.BOLD}
          />
          <BadgeComponent
            text={option.label}
            textColor={colorBrand.heavy}
            backgroundColor={colorGrayscale.white}
          />
        </OptionTitle>
        <P2Gray600 text={option.desc} />
      </OptionContent>
    </NewsletterOptionsContainer>
  ))
  const onSubscribe = () => {
    window.open(subscribeUrl, '_blank')
  }

  return (
    <FlexColumn>
      <Content>
        <TitleContainer>
          <H4Gray800 text={title.textL1[type]} />
          <H4Gray800 text={title.textL2} />
        </TitleContainer>
        <Divider/>
        <Options>{optionsJSX}</Options>
      </Content>
      <SubscribeButton size={PillButton.Size.L} type={PillButton.Type.PRIMARY} style={PillButton.Style.DARK} theme={PillButton.THEME.normal} text="前往訂閱" onClick={onSubscribe} />
      <BackButton link={{ to: redirect }} type={LinkButton.Type.UNDERLINE} text="不訂閱，回首頁" />
    </FlexColumn>
  )
}

const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [ search ])
}

const OnboardingPage = ({ jwt, userID, onboarding, activated }) => {
  const query = useQuery()
  const { showSnackBar, snackBarText, toastr } = useSnackBar()
  const [ step, setStep ] = useState(Step.CATEGORY)
  const [ isLoading, setIsLoading ] = useState(false)

  // redirect directly if activated
  useEffect(() => {
    if (activated) {
      const redirectUrl = query.get('destination') || requestOrigin.forClientSideRendering[env.releaseBranch].main
      window.location.href = redirectUrl
    }
  }, [])
  if (activated) {
    return (
      <Container>
        <SimpleHeader />
        <LoadingBox>
          <LoadingImg releaseBranch={env.releaseBranch} />
          <P2Gray600 text="請稍候" />
        </LoadingBox>
      </Container>

    )
  }

  const finishSetting = async (categorySelections) => {
    setIsLoading(true)
    const readPreference = _.filter(categorySelections, (key) => key !== false)
    try {
      await onboarding(jwt, userID, readPreference, [], query.get('destination'))
      await refreshAccessToken()
      setStep(Step.NEWSLETTER)
    } catch (err) {
      setIsLoading(false)
      toastr({ text: '很抱歉，發生了一點小問題，請再試一次' })
    }
  }

  const type = query.get('type') || SigninType.REGISTER
  const redirectUrl = query.get('destination') || requestOrigin.forClientSideRendering[env.releaseBranch].main
  const contentJSX = step === Step.NEWSLETTER ?
    <NewsletterSetting redirect={redirectUrl} type={type} /> :
    <CategorySetting isLoading={isLoading} onFinish={finishSetting} />

  return (
    <Container>
      <SimpleHeader />
      <Body>
        {contentJSX}
      </Body>
      <SnackBarContainer $showSnackBar={showSnackBar}>
        <SnackBarDiv>
          <SnackBar text={snackBarText} />
        </SnackBarDiv>
      </SnackBarContainer>
    </Container>
  )
}

const { actions, reduxStateFields } = twreporterRedux
const { onboarding } = actions

function mapStateToProps(state) {
  const jwt = _.get(state, [ reduxStateFields.auth, 'accessToken' ])
  const userID = _.get(state, [ reduxStateFields.auth, 'userInfo', 'user_id' ])
  const activated = _.get(state, [ reduxStateFields.auth, 'userInfo', 'activated' ])
  return {
    jwt,
    userID,
    activated,
  }
}

OnboardingPage.propTypes = {
  jwt: PropTypes.string,
  userID: PropTypes.number,
  activated: PropTypes.string,
  onboarding: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  { onboarding }
)(OnboardingPage)
