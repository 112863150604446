import React, { useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

// @twreporter
import { H3 } from '@twreporter/react-components/lib/text/headline'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import mq from '@twreporter/core/lib/utils/media-query'
import { P1 } from '@twreporter/react-components/lib/text/paragraph'
import { DesktopAndAbove, TabletAndBelow } from '@twreporter/react-components/lib/rwd'
import { Arrow } from '@twreporter/react-components/lib/icon'
import { IconButton } from '@twreporter/react-components/lib/button'

// components
import { Divider } from '../divider'
import Card from './card'

// utils
import { getImageUrl } from '../../../../utils/get-gcs-images'

const InfluenceContainer = styled.div`

`

const StyledH3 = styled(H3)`
  margin-bottom: 16px;
  color: ${colorGrayscale.gray800};
`

const ContentContainer = styled.div`
  margin-top: 24px;
  display: grid;
  ${mq.mobileOnly`
    grid-column-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  `}
  ${mq.tabletOnly`
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  `}
  ${mq.desktopAndAbove`
    grid-column-gap: 32px;
    grid-template-columns: repeat(9, 1fr);
  `}
`

const StyledP1 = styled(P1)`
  color: ${colorGrayscale.gray800};
  padding-bottom: 32px;
  ${mq.mobileOnly`
    grid-column: 1 / 5;
  `}
  ${mq.tabletOnly`
    grid-column: 1 / 10;
  `}
  ${mq.desktopAndAbove`
    grid-column: 1 / 6;
  `}
`

const CardsConatiner = styled.div`
  width: calc(100% + 40px);
  margin-left: -40px;
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  grid-column-gap: 16px;
  align-items: center;
  .swiper-container {
    width: 100%;
  }
`

const MobileCardsContainer = styled.div`
  width: 100vw;
  height: fit-content;
  margin-left: -24px;
  ${mq.tabletOnly`
    margin-left: -32px;
  `}
  .swiper-container {
    width: 100%;
  }
  .swiper-slide {
    width: 240px;
  }
`

const Influence = () => {
  const [ swiper, setSwiper ] = useState(null)
  const cards = [
    {
      title: '來台留學淪綁債學工：失序的私校國際招生',
      content: '促使教育部勒令中州科大停止招生；彰化地檢署以人口販運罪起訴中州科大副校長等10人。',
      imageUrl: getImageUrl('influence-illegal.jpg'),
      link: 'https://www.twreporter.org/topics/technological-and-vocational-college-foreign-students-become-cheap-labors',
    },
    {
      title: '血淚漁場：跨國直擊台灣遠洋漁業真相',
      content: '促使行政院院會加強遠洋漁船勞動檢查，屏東地檢署重啟印尼漁工死亡調查。',
      imageUrl: 'https://www.twreporter.org/images/20210129175854-8652653532ed389c04f2e8a77e0302c3-mobile.jpg',
      link: 'https://www.twreporter.org/topics/far-sea-fishing-investigative-report',
    },
    {
      title: '廢墟少年：兩萬名被遺忘的高風險家庭孩子們',
      content: '促使立法院召開公聽會，呼籲各界重視中綴生及脆弱家庭青少年處境。',
      imageUrl: 'https://www.twreporter.org/images/20171030144550-2096e789f6f0c73b226bd22f97b624c7-mobile.jpg',
      link: 'https://www.twreporter.org/topics/high-risk-youth-left-in-relic',
    },
    {
      title: '山頭上的掠奪：揭露全台原住民保留地流失亂象',
      content: '直接影響最高法院大法庭裁定「原保地假人頭買賣一律無效」，行政院院會通過原保地專法草案。',
      imageUrl: 'https://www.twreporter.org/images/20210816135933-03d98b61a3742ad5cfb80bce7d2493af-mobile.jpg',
      link: 'https://www.twreporter.org/topics/aboriginal-reserve-dispute',
    },
    {
      title: '六輕營運20年：科學戰爭下的環境難民',
      content: '促使監察院糾正環保署，並呼籲台塑集團為申報空汙數據造假向社會道歉。',
      imageUrl: 'https://www.twreporter.org/images/20180123175443-aea7a0d9adceea44182a74f3fdc93c38-mobile.jpeg',
      link: 'https://www.twreporter.org/topics/fpc-sixth-naphtha-cracker-20-years',
    },
    {
      title: '1年26萬公斤的工業笑氣，如何毒害年輕人？',
      content: '促使環保署公告將笑氣納入《毒性及關注化學物質管理法》進行管制，避免影響青少年身心健康。',
      imageUrl: 'https://www.twreporter.org/images/20200303092116-3424a123d896ea5de4936849b6bcd80b-mobile.jpg',
      link: 'https://www.twreporter.org/topics/laughing-gas-nitrous-oxid-substance-abuse',
    },
    {
      title: '亞洲森林浩劫——誰讓地球禿了頭',
      content: '促使環保署下令太魯閣國家公園內禁止採礦。',
      imageUrl: 'https://www.twreporter.org/images/20160813145922-5564b493ced090c67c90358c0f74a82c-mobile.jpg',
      link: 'https://www.twreporter.org/topics/asia-forest',
    },
    {
      title: '獨家揭露台鐵體檢報告',
      content: '促使政府公布台鐵體檢報告全文，台鐵首度在官網上開闢「台鐵總體檢」專頁並定期公布改善事項。',
      imageUrl: 'https://www.twreporter.org/images/20181026221446-8af685ef292339d11414b5f4f625ddc8-mobile.jpg',
      link: 'https://www.twreporter.org/topics/taiwan-railway-examination-report',
    },
    {
      title: '病床邊的照護危機',
      content: '促使衛福部承諾推動「住院友善醫院認證」。',
      imageUrl: 'https://www.twreporter.org/images/20181105222202-fe3b5b9b89da5152dc6c2f593cd1a53a-mobile.jpg',
      link: 'https://www.twreporter.org/topics/nursing-aide-crisis-of-taking-care',
    },
    {
      title: '每天我們失去5個孩子——搶救兒童高死亡率',
      content: '促使行政院公布最新「我國少子女化對策計畫」白皮書；讀者捐贈價值500萬元的兒童專用保溫箱給偏鄉醫院。',
      imageUrl: 'https://www.twreporter.org/images/20180331224333-692da7332947e5f7356fdfe5ee5fff77-mobile.jpg',
      link: 'https://www.twreporter.org/topics/child-health-care-taiwan',
    },
    {
      title: '看不見的線上博弈帝國',
      content: '促使檢調單位起訴涉嫌洗錢的易智博公司。',
      imageUrl: 'https://www.twreporter.org/images/20190716105838-9acafe08db8b16069bf7affcbe4db9f1-mobile.jpg',
      link: 'https://www.twreporter.org/topics/online-gambling-industry-asia',
    },
    {
      title: '俄烏戰爭國際採訪',
      content: '《報導者》採訪團隊兩度前往歐洲與戰地帶回台灣視野的第一手觀察，獲得各項重要新聞獎項肯定。',
      imageUrl: 'https://www.twreporter.org/images/20220316122328-7b9b4d7df88782829ade3545ab9e94f6-tablet.jpg',
      link: 'https://www.twreporter.org/topics/russian-invasion-of-ukraine-2022',
    },
  ]

  const handleArrowClick = (direction) => {
    if (direction === Arrow.Direction.LEFT) {
      swiper.slidePrev()
    } else {
      swiper.slideNext()
    }
  }
  return (
    <InfluenceContainer>
      <StyledH3 text={'你的捐款能發揮社會影響力'} />
      <Divider />
      <ContentContainer>
        <StyledP1 text={'深度調查報導需花費長時間、高成本製作，追蹤查證過程費時費力；你的捐款讓《報導者》持續產出優質新聞內容，實際發揮媒體第四權的功能。'} />
      </ContentContainer>
      <DesktopAndAbove>
        <CardsConatiner>
          <IconButton
            iconComponent={<Arrow direction={Arrow.Direction.LEFT} />}
            onClick={() => handleArrowClick(Arrow.Direction.LEFT)}>
          </IconButton>
          <Swiper
            slidesPerView={3}
            slidesPerGroup={3}
            loop={true}
            spaceBetween={16}
            onSwiper={(swiper) => setSwiper(swiper)}
          >
            {cards.map(({ title, content, imageUrl, link }, idx) => 
              <SwiperSlide key={idx}>
                <Card title={title} content={content} imageUrl={imageUrl} link={link} />
              </SwiperSlide>
            )}
          </Swiper>
          <IconButton
            iconComponent={<Arrow direction={Arrow.Direction.RIGHT} />}
            onClick={() => handleArrowClick(Arrow.Direction.RIGHT)}>
          </IconButton>
        </CardsConatiner>
      </DesktopAndAbove>
      <TabletAndBelow>
        <MobileCardsContainer>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={16}
            freeMode={true}
            slidesOffsetBefore={24}
            slidesOffsetAfter={24}
            breakpoints={{
              '768': {
                'slidesOffsetBefore': 32,
                'slidesOffsetAfter': 32,
              },
            }}
          >
            {cards.map(({ title, content, imageUrl, link }, idx) => 
              <SwiperSlide key={idx}>
                <Card title={title} content={content} imageUrl={imageUrl} link={link} />
              </SwiperSlide>
            )}
          </Swiper>
        </MobileCardsContainer>
      </TabletAndBelow>
    </InfluenceContainer>
  )
}

export default Influence
