/**
 * verify security id (身分證字號) and unique id (統一證號)
 *
 * @param {String} id - security id or unique id 
 */

export default function verifySecurityId(id) {
  // security id and uniquid id check rule:
  //   1. Must be a string with 10 characters
  //   2. The leading character is a capital letter (A-Z)
  // A234566333 -> valid
  // A23456633 -> invalid, less than 10 characters 
  // 123456633 -> invalid, leading character is not a English capital letter 
  if (id && id.length === 10) {
    const reg = /^[A-Z]/
    return reg.test(id)
  }
  return false
}
