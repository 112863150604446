import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
// constants
import colors from '../../../constants/colors'
// @twreporter
import { colorGrayscale, colorSupportive } from '@twreporter/core/lib/constants/color'

const mockup = {
  ratioOfInnerToOuterDiameter: 11 / 20,
}

const Input = styled.input.attrs({ type: 'checkbox' })`
  /* taken from https://css-tricks.com/inclusively-hidden/ */
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const CustomCheckmark = styled.div`
  position: relative;
  height: ${props => props.$diameter}px;
  width: ${props => props.$diameter}px;
  background: ${props => props.$isChecked ? colorSupportive.heavy : colorGrayscale.gray300};
  border-radius: 50%;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.$isChecked ? props.$diameter * mockup.ratioOfInnerToOuterDiameter : props.$diameter - 2}px;
    height: ${props => props.$isChecked ? props.$diameter * mockup.ratioOfInnerToOuterDiameter : props.$diameter - 2}px;
    border-radius: 50%;
    background: ${props => props.$isChecked ? colorGrayscale.white : colors.gray50};
  }
`

const Box = styled.div`
  position: relative;
  margin-left: 59px;
`

const VerticallyCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CircleCheckbox = ({
  handleClick = () => {},
  diameter = 30,
  isChecked = false,
  label = '',
  name,
}) =>  {
  const inputRef = useRef()

  useEffect(() => {
    // prevent browsers from remembering checkbox state
    inputRef.current.checked = false
  }, [])

  return (
    <label>
      <VerticallyCenter>
        <span>{label}</span>
        <Box>
          <CustomCheckmark
            $diameter={diameter}
            $isChecked={isChecked}
          />
        </Box>
      </VerticallyCenter>
      <Input
        ref={inputRef}
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={handleClick}
      />
    </label>
  )
}
CircleCheckbox.propTypes = {
  handleClick: PropTypes.func,
  diameter: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default CircleCheckbox
