import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import Footer from '@twreporter/react-components/lib/footer'
import { DONATION_LINK_ANCHOR } from '@twreporter/core/lib/constants/donation-link-anchor'

// components
import Header from './header'
import HeroImage from './hero-image'
import Concept from './concept'
import Influence from './influence'
import Roles from './roles'
import Funding from './funding'
import BottomBanner from './bottom-banner'
import FAQs from './faq'
import ContributeBox from './contribute-box'

// context
import { RoleContext } from '../../../contexts'

// utils
import { customizeSmoothScroll } from '../../../utils/customize-smooth-scroll'

// env
import env from '../../../global-env'

const IntroPageContainer = styled.div`
  background-color: ${colorGrayscale.gray100};
  ${mq.tabletAndBelow`
    overflow: hidden;
  `}
`

const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  display: grid;
  ${mq.tabletAndAbove`
    grid-template-columns: repeat(12, 1fr);
  `}
  ${mq.mobileOnly`
    padding-left: 24px;
    padding-right: 24px;
    grid-column-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  `}
  ${mq.tabletOnly`
    padding-left: 32px;
    padding-right: 32px;
    grid-column-gap: 24px;
  `}
  ${mq.desktopOnly`
    padding-left: 48px;
    padding-right: 48px;
    margin-top: 72px;
    grid-column-gap: 32px;
  `}
  ${mq.hdOnly`
    margin: auto;
    margin-top: 72px;
    grid-column-gap: 32px;
  `}
`

const ContributeBoxContainer = styled.div`
  ${mq.mobileOnly`
    grid-column: 1 / 5;
    order: -1;
    display: flex;
    justify-content: center;
    margin-top: 32px;
  `}
  ${mq.tabletOnly`
    grid-column: 1 / 13;
    order: -1;
    display: flex;
    justify-content: center;
    margin-top: -48px;
  `}
  ${mq.desktopAndAbove`
    grid-column: 10 / 13;
    position: sticky;
    top: 20%;
  `}
`

const FAQsContainer = styled.div`
  margin-bottom: 72px;
  ${mq.mobileOnly`
    grid-column: 1 / 5;
    margin-top: 40px;
  `}
  ${mq.tabletOnly`
    grid-column: 1 / 13;
    margin-top: 64px;
  `}
  ${mq.desktopAndAbove`
    grid-column: 1 / 10;
  `}
`

const ConceptContainer = styled.div`
  ${mq.mobileOnly`
    grid-column: 1 / 5;
    margin-top: 40px;
  `}
  ${mq.tabletOnly`
    grid-column: 1 / 13;
    margin-top: 48px;
  `}
  ${mq.desktopAndAbove`
    grid-column: 1 / 9;
  `}
`

const InfluenceContainer = styled.div`
  ${mq.mobileOnly`
    grid-column: 1 / 5;
    margin-top: 40px;
  `}
  ${mq.tabletOnly`
    grid-column: 1 / 13;
    margin-top: 64px;
  `}
  ${mq.desktopAndAbove`
    grid-column: 1 / 10;
    margin-top: 72px;
  `}
`

const RolesContainer = styled.div`
  ${mq.mobileOnly`
    grid-column: 1 / 5;
    margin-top: 40px;
  `}
  ${mq.tabletOnly`
    grid-column: 1 / 13;
    margin-top: 64px;
  `}
  ${mq.desktopAndAbove`
    grid-column: 1 / 10;
    margin-top: 72px;
  `}
`

const FundingContainer = styled.div`
  ${mq.mobileOnly`
    grid-column: 1 / 5;
    margin-top: 40px;
  `}
  ${mq.tabletOnly`
    grid-column: 1 / 13;
    margin-top: 64px;
  `}
  ${mq.desktopAndAbove`
    grid-column: 1 / 10;
    margin-top: 72px;
  `}
`

const BottomBannerContainer = styled.div`
  width: 100%;
  ${mq.mobileOnly`
    margin-top: 40px;
  `}
  ${mq.tabletOnly`
    margin-top: 64px;
  `}
  ${mq.desktopAndAbove`
    margin-top: 72px;
  `}
`

const IntroPage = () => {
  const [ tick, setTick ] = useState(false)
  const [ role, setRole ] = useState('')
  const [ amount, setAmount ] = useState(undefined)
  const [ frequency, setFrequency ] = useState('')
  const onRoleCardButtonClick = (roleKey) => {
    setTick(!tick)
    setRole(roleKey)
    if (document.body.clientWidth <= 1023) { // use clientWidth to avoid safari innerWidth issue
      const el = document.getElementById(DONATION_LINK_ANCHOR.contribute)
      customizeSmoothScroll(el)
    }
  }

  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location
      if (!hash) return
      const elementToScroll = document.getElementById(hash.replace('#', ''))
      if (!elementToScroll) return
      customizeSmoothScroll(elementToScroll)
    }

    scrollToHashElement()
    window.addEventListener('hashchange', scrollToHashElement)
    return () => {
      window.removeEventListener('hashchange', scrollToHashElement)
    }
  }, [])

  const contextValue = { tick, role, amount, setAmount, frequency, setFrequency }
  return (
    <IntroPageContainer>
      <Header />
      <HeroImage />
      <RoleContext.Provider value={contextValue} >
        <Content>
          <ConceptContainer id={DONATION_LINK_ANCHOR.value}>
            <Concept />
          </ConceptContainer>
          <ContributeBoxContainer id={DONATION_LINK_ANCHOR.contribute}>
            <ContributeBox />
          </ContributeBoxContainer>
          <InfluenceContainer id={DONATION_LINK_ANCHOR.impact}>
            <Influence />
          </InfluenceContainer>
          <RolesContainer id={DONATION_LINK_ANCHOR.plan}>
            <Roles onRoleCardButtonClick={onRoleCardButtonClick} />
          </RolesContainer>
          <FundingContainer>
            <Funding />
          </FundingContainer>
        </Content>
      </RoleContext.Provider>
      <BottomBannerContainer>
        <BottomBanner />
      </BottomBannerContainer>
      <Content>
        <FAQsContainer id={DONATION_LINK_ANCHOR.faq}>
          <FAQs />
        </FAQsContainer>
      </Content>
      <Footer releaseBranch={env.releaseBranch} />
    </IntroPageContainer>
  )
}

export default IntroPage
