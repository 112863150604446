import React from 'react'
import styled from 'styled-components'
// constants
import typography from '../../constants/typography'
// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

const Container = styled.div`
  > img {
    margin-right: 8px;
    vertical-align: middle;
  }

  > span {
    opacity: 0.5;
    font-size: 13px;
    font-weight: ${typography.font.weight.bold};
    color: ${colorGrayscale.black};
  }
`

export default class SecurityIcon extends React.PureComponent {
  render() {
    return (
      <Container>
        <img src="/asset/icon-creditcard-security.svg"></img>
        <span>安全</span>
      </Container>
    )
  }
}
