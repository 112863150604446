import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import TagManger from 'react-gtm-module'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import globalEnv from '../../../global-env'
import { useDispatch, useSelector } from 'react-redux'
// rtk apis
import {
  useContributeByCreditCardMutation,
  changeRenderedStep,
  errorStep,
  updateOrderNumber,
} from '../../../actions/contribute'
//utils
import log from '../../../utils/logging'
import { refreshAccessToken } from '../../../utils/refresh-token'
// constants
import mockup from '../../../constants/mockup'
import supportConsts from '../../../constants/support'
import typography from '../../../constants/typography'
import wellDefinedPropTypes from '../../../constants/prop-types'
import colors from '../../../constants/colors'
// components
import SecurityIcon from '../security-icon'
import Steps from '../steps'
import bsc from '../../basic-styled-components'
import Checkbox from '../../styled-checkbox'
import DirectPayForm from './tappay/direct-pay'
import Layout from '../layout-redesign'
import LinePay from './tappay/line-pay'
import LinkToTermsAndConditions from '../terms-and-conditions'
import { PayByCreditCardNextStep, PayByLinePayNextStep } from './next-step'
import OneTimeContribute from './one-time-contribute'
import PeriodicContribute from './periodic-contribute'
import { MaintenanceNotifyBox } from '../../notify-box'
import Loading from '../../loading'
// @twreporter
import mediaQuery from '@twreporter/core/lib/utils/media-query'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
// lodash
import get from 'lodash/get'
import assign from 'lodash/assign'
const _ = {
  get,
  assign,
}

// global var
const currentStep = 2

const renderedStepConsts = supportConsts.contribute.renderedStep
const { title, desc } = globalEnv.maintenanceInfo

const Section = styled(bsc.CenteredBoxModel).attrs({ as: 'section' })`
  width: 100%;
  padding: 40px ${35/mockup.mobile.width*100}% 40px ${35/mockup.mobile.width*100}%;
  background-color: ${colors.gray50};
  font-weight: ${typography.font.weight.bold};

  ${mediaQuery.tabletOnly`
    max-width: 620px;
    padding: 60px 30px 40px 30px;
  `}

  ${mediaQuery.desktopAndAbove`
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
    padding: 60px 60px 40px 60px;
  `}
`

const PlanRow = styled.div`
  position: relative;
  margin-bottom: 30px;

  span {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 16px;
    color: ${colors.gray475};
  }
`

const HeaderOne = styled.h1`
  font-size: 32px;
  color: ${colorGrayscale.black};
  padding: 0;
  margin: 0;
  font-weight: bold;
  letter-spacing: 2px;
`

const PlanInfo = styled.div`
  background-color: ${colorGrayscale.white};
  padding: 40px 0px 40px 30px;

  div:first-child {
    font-size: 16px;
    color: ${colorGrayscale.black};
    font-weight: ${typography.font.weight.normal};
    margin-bottom: 5px;
    opacity: 0.87;
    letter-spacing: 1px;
  }

  div:last-child {
    font-size: 32px;
    color: ${colorGrayscale.black};
    letter-spacing: 1px;
    line-height: 1.22;
  }

  ${mediaQuery.tabletAndAbove`
    padding-left: 30px;

    > div {
      display: inline-block;
      vertical-align: middle;
    }

    > div:first-child {
      font-size: 18px;
      letter-spacing: 1.13px;
      margin-right: 30px;
      margin-bottom: 0;
      opacity: 1;
    }
  `}
`

const TermsAndConditions = styled.div`
  display: inline-block;
  font-weight: ${typography.font.weight.normal};
  color: ${colorGrayscale.gray900};
  line-height: 1.57;
  margin: 17px 0 0 0;
  font-size: 18px;
  letter-spacing: 1.13px;
  ${mediaQuery.mobileOnly`
    margin: -5px 0 0 0;
    line-height: 1.5;
  `}
`

const EmptyBox = styled.div`
  border: solid 2px ${colorGrayscale.gray100};
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
`

const PaymentWrapper = styled.div`
  ${mediaQuery.tabletAndAbove`
    margin-bottom: 40px;
  `}
`

const TitleRow = styled.div`
  position: relative;
  margin-bottom: 60px;
`

const Security = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`

const Terms = styled.div`
  ${mediaQuery.mobileOnly`
    display: flex;
    ${CheckboxContainer} {
      margin-left: 2.5px;
    }
  `} 
`

const StepsContainer = styled.div`
  margin-bottom: 72px;
  ${mediaQuery.mobileOnly`
    margin-bottom: 48px;
  `} 
`
const getFrequencyString = (frequency) => {
  switch(frequency) {
    case supportConsts.contribute.frequency.yearly:
      return '每年定額'
    case supportConsts.contribute.frequency.monthly:
      return '每月定額'
    case supportConsts.contribute.frequency.oneTime:
      return '單筆贊助'
    default:
      return '贊助'
  }
}

const Contribute = ({
  currencyStr = supportConsts.contribute.currencyStr.ntd,
  uri =  {
    pathname: '',
    search: '',
  },
  frequency,
  value,
}) => {
  const userInfo = useSelector((state) => _.get(state, 'auth.userInfo', {}))
  const jwt = useSelector((state) => _.get(state, 'auth.accessToken', ''))
  const userData = useMemo(() => _.assign({}, userInfo, { jwt }), [ userInfo, jwt ])
  const email = _.get(userData, 'email', '')
  const [ canGetPrime, setCanGetPrime ] = useState(false)
  const [ areTermsChecked, setAreTermsChecked ] = useState(false)
  const [ payMethod, setPayMethod ] = useState(supportConsts.contribute.payMethod.creditCard)
  const [ cardType, setCardType ] = useState(supportConsts.contribute.cardType.unknown)
  const frequencyStr = useMemo(() => getFrequencyString(frequency), [ frequency ])
  const _directPay = useRef()
  const _linePay = useRef()
  const [ contributeByCreditCard, { isLoading, isError, isSuccess, error, data } ] = useContributeByCreditCardMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isError) {
      dispatch(errorStep(error))
    }
  }, [ isError ])

  useEffect(() => {
    if (!isSuccess) {
      return
    }
    dispatch(updateOrderNumber(_.get(data, 'data.order_number')))
    if (payMethod === supportConsts.contribute.payMethod.line) {
      onLinepaySuccess(data)
    } else {
      onCreditCardSuccess()
    }
    refreshAccessToken()
  }, [ isSuccess ])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      TagManger.dataLayer({
        dataLayer: {
          event: 'add_payment_info',
        },
      })
    }
  }, [])

  const onLinepaySuccess = async (data) => {
    const paymentUrl = _.get(data, 'data.payment_url')
    dispatch(changeRenderedStep(renderedStepConsts.redirectOnContribute))
    try {
      await _linePay.current.redirect(paymentUrl)
    } catch (err) {
      log({ email, error: err })
      errorStep(err)
    }
  }

  const onCreditCardSuccess = () => {
    dispatch(changeRenderedStep(renderedStepConsts.updateContributeRecordForm))
  }
  
  const toggleTermsChecked = () => {
    setAreTermsChecked(!areTermsChecked)
  }

  const getPaymentRefNode = () => {
    if (payMethod === supportConsts.contribute.payMethod.creditCard) {
      return _directPay.current
    }
    return _linePay.current
  }

  const onContribute = async () => {
    const paymentNode = getPaymentRefNode()
    try {
      const prime = await paymentNode.getPrime()
      contributeByCreditCard({ prime, amount: value, userData, frequency, payment: payMethod })
    } catch (err) {
      log({ email, error: err })
      errorStep(err)
    }
  }
  
  const renderPaymentMethods = () => {
    const directPayFormJSX = (
      <DirectPayForm
        ref={_directPay}
        onCanGetPrime={setCanGetPrime}
        onCardTypeChange={setCardType}
      /> 
    )

    const linePayJSX = (
      <LinePay
        ref={_linePay}
      />
    )

    const oneTimeContributeJSX = (
      <OneTimeContribute
        cardType={cardType}
        onPaymentChange={setPayMethod}
        payMethod={payMethod}
        directPay={directPayFormJSX}
        linePay={linePayJSX}
      />
    )

    const periodicContributeJSX = (
      <PeriodicContribute
        cardType={cardType}
        directPay={directPayFormJSX}
      />
    )

    return (
      <PaymentWrapper>
        <TitleRow>
          <Security><SecurityIcon /></Security>
          <HeaderOne>
            付款資訊
          </HeaderOne>
        </TitleRow>
        {
          frequency === supportConsts.contribute.frequency.oneTime ? 
            oneTimeContributeJSX : periodicContributeJSX
        }
      </PaymentWrapper>
    )
  }
 
  const renderTerms = () => ( 
    <Terms>
      <CheckboxContainer>
        <Checkbox
          height="21"
          handleClick={toggleTermsChecked}
          width="21"
          isChecked={areTermsChecked}
        />
      </CheckboxContainer>
      <TermsAndConditions>我同意<LinkToTermsAndConditions>贊助者服務條款與隱私權政策</LinkToTermsAndConditions></TermsAndConditions>
    </Terms>
  )

  const NextStepJSX = payMethod === supportConsts.contribute.payMethod.line ?
    <PayByLinePayNextStep disabled={!areTermsChecked} onConfirm={onContribute} /> :
    <PayByCreditCardNextStep disabled={!canGetPrime || !areTermsChecked} onConfirm={onContribute} />

  return (
    <div>
      <MaintenanceNotifyBox
        title={title}
        desc={desc}
      />
      <Layout>
        <StepsContainer>
          <Steps currentStep={currentStep} />
        </StepsContainer>
        <Section>
          <PlanRow>
            <HeaderOne>贊助方案</HeaderOne>
            <Link 
              to={{
                pathname: '/intro',
                search: _.get(uri, 'search', ''),
              }} 
            >
              <span>更改方案</span>
            </Link>
          </PlanRow>
          <PlanInfo>
            <div>{frequencyStr}</div>
            <div>{currencyStr} ${value}</div>
          </PlanInfo>
        </Section>
        <EmptyBox />
        <Section>
          {renderPaymentMethods()}
          <bsc.MobileDisplayNone>
            {renderTerms()}
          </bsc.MobileDisplayNone>
        </Section>
        <EmptyBox />
        <bsc.OnlyMobileDisplay>
          <Section>
            {renderTerms()}
          </Section>
        </bsc.OnlyMobileDisplay>
        {NextStepJSX}
      </Layout>
      <Loading show={isLoading} />
    </div>
  )
}
Contribute.propTypes = {
  currencyStr: PropTypes.string,
  frequency: wellDefinedPropTypes.contributeFrequency.isRequired,
  uri: wellDefinedPropTypes.location,
  value: PropTypes.number.isRequired,
}

export default Contribute
