import React from 'react'
import styled from 'styled-components'

// @twreporter
import { H3 } from '@twreporter/react-components/lib/text/headline'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

// components
import ExpansionPanel from '../../expansion-panel'

const faqs =  [ {
  question: '未來有打算改為「付費才能閱讀報導內容」的運作模式嗎？',
  answer: '《報導者》期待以深度報導作為起點，進而喚起社會對於議題的意識、產生結構面的實質影響，因此我們不會築起付費牆，也邀請您在社群平台轉發我們的內容，讓重要的議題有機會被更多人看見。',
}, {
  question: '如果停止贊助，我的身分等級會變動嗎？',
  answer: '如果您原本是每月定期定額捐款 500 元以上的「開創者」，當您停止贊助時，系統會自動將您的身分調整為「行動者」。不過請放心，只要曾經贊助過《報導者》，不論金額，「行動者」的身分都是永久有效的。我們非常感謝您對《報導者》的信賴與支持。',
}, {
  question: '定期定額贊助會在每月幾號進行扣款？',
  answer: '首次定額贊助會於贊助當天進行扣款，自次月起，定額贊助將於每月 20 ~ 22 日期間進行扣款，如遇金流系統維護日等事宜將於 20 日左右提前或延後扣款。',
}, {
  question: '如果我想更換其他信用卡贊助，或更改贊助額度，該怎麼做？（含掛失、卡片過期）',
  answer: '因安全性問題，我們無法直接為您變更信用卡資訊或贊助額度，請來信至 events@twreporter.org 信箱，告知贊助者姓名、email，我們將為您取消原先的贊助，後續您可以使用其他信用卡於本頁面重新建立贊助資訊。',
}, {
  question: '贊助後如何更改通訊地址、電話等個人資料？',
  answer: '您可以至贊助時所留的信箱收信，透過贊助成功時所收到的信件（主旨：感謝您成為報導者的贊助夥伴）至信件中點開『專屬連結』，修改您欲調整個人資料（需登入會員）。',
}, {
  question: '贊助《報導者》可以抵稅嗎？該怎麼做？',
  answer: '可以。我們已向國稅局申請「捐贈扣除額單據電子化」，若您在贊助時確實填寫「身分證字號」並勾選「我同意由報導者上傳此筆捐款至國稅局」，申報時無需檢附紙本收據，國稅局即可直接調閱相關紀錄。',
}, {
  question: '我可以指定贊助特定議題、記者嗎？',
  answer: '《報導者》相信每個議題都很重要，因此無開放指定贊助特定議題、記者。但歡迎您於贊助的備註或 email 至 events＠twreporter.org 信箱，分享您的建議、告訴我們您受哪一篇報導而感動、希望能有哪些議題的後續，或者想給哪位記者鼓勵。您的鼓勵與想法都會被編輯部看見。',
}, {
  question: '中途想更改贊助額度或暫停贊助，我應該怎麼處理？',
  answer: '請來信至 events@twreporter.org 信箱，告知贊助者姓名、email，將由專人為您處理。',
}, {
  question: '我的贊助資料會被公開嗎？',
  answer: '根據《財團法人法》，我們需定期辦理公開徵信，您可在填寫贊助者資料時，勾選公告時是否匿名。',
}, {
  question: '如果透過傳真或匯款方式捐款，我應該怎麼做才能取得「行動者」或「開創者」身分？',
  answer: '請您在《報導者》網站註冊成為「探索者」，並來信 events@twreporter.org 告訴我們您的姓名、捐款方式（傳真或匯款）、贊助金額，以及在網站註冊時所使用的 email，工程師將會在系統調整您的身分，完成後您即可立即在網站個人專區看到新的身分別。',
}, {
  question: '「行動者」與「開創者」可以如何獲得《報導者》新書優惠呢？',
  answer: '《報導者》推出新書時，我們將主動透過 email 詢問贊助者購買意願，並提供贊助者專屬購買管道；透過此方式購買即可獲得「行動者 75 折」、「開創者 6 折」的優惠。由於團隊人力有限，每次購買意願調查皆有截止期限，敬請見諒。',
}, {
  question: '「開創者」會如何獲得國家兩廳院的「廳院人」會籍呢？',
  answer: '當您贊助成為「開創者」後，您會收到「開創者」身分通知信，信中將提供國家兩廳院的「廳院人」會籍申請表單，依照表單填寫後，國家兩廳院將根據流程提供會籍。（請注意：「廳院人」會籍僅能提供給「開創者」本人，不可轉換）',
}, {
  question: '除了贊助，是否還有其他支持《報導者》的方式？',
  answer: [
    '除了以線上、ATM 或傳真等方式實質贊助《報導者》，您也可選擇捐出您的消費發票。《報導者》的電子發票捐贈碼為「2929」。您只要在結帳時口述捐贈碼「2929」，即能將消費發票捐給《報導者》。',
    '適用範圍：各大超商、超市、賣場、加油站等開立「電子發票」的實體店家，各大線上購物網站也有提供此服務。',
  ],
}, {
  question: '還有其他問題？',
  answer: (
    <p>
      歡迎以 email 聯繫我們：events@twreporter.org，或透過
      <a href="https://m.me/1646675415580324" target="_blank">臉書私訊</a>
      ，由小編為您服務。
    </p>
  ),
} ]

const FAQsItems = faqs.map((faq, index) => {
  const { answer, question } = faq
  const isFirstFaq = index === 0
  return (
    <ExpansionPanel
      key={'faq-' + index}
      active={isFirstFaq}
      index={index+1}
      text={answer}
      title={question}
      showBorderTop={isFirstFaq}
    />
  )
})

const FAQsContainer = styled.div``

const StyledH3 = styled(H3)`
  margin-bottom: 32px;
  color: ${colorGrayscale.gray800};
`

const FAQs = () => {
  return (
    <FAQsContainer>
      <StyledH3 text={'常見問題'} />
      {FAQsItems}
    </FAQsContainer>
  )
}

export default FAQs
