import React from 'react'
// env
import env from '../../global-env'
// @twreporter
import EmptyState from  '@twreporter/react-components/lib/empty-state'

export const BannedErrorPage = () => {
  return (
    <EmptyState
      style={EmptyState.Style.DEFAULT}
      title="此帳號已被鎖定"
      guide="驗證碼錯誤已達上限，請一小時後再嘗試。"
      showGuide={true}  
      showButton={false}
      releaseBranch={env.releaseBranch}
    />
  )
}
