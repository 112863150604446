import apiOrigin from '../constants/api-origin'
import subdomain from '../constants/subdomain'

/**
 * Parse search string to return the query object
 *
 * @param {string} search
 */
function parseSearch(search) {
  if (typeof search !== 'string') {
    console.warn('Search to be parsed must be a string but get:', search, '. Return an empty object.') // eslint-disable-line no-console
    return {}
  }
  return new URLSearchParams((search[0] === '?' ? search.slice(1) : search))
}

export function getSubdomain(hostname) {
  const supportHosts = [
    'support.twreporter.org',
    'staging-support.twreporter.org',
    'dev-support.twreporter.org',
  ]
  const accountsHosts = [
    'accounts.twreporter.org',
    'staging-accounts.twreporter.org',
    'dev-accounts.twreporter.org',
  ]
  switch (true) {
    case hostname === 'localhost':
      return subdomain.localhost
    case supportHosts.indexOf(hostname) !== -1:
      return subdomain.support
    case accountsHosts.indexOf(hostname) !== -1:
      return subdomain.accounts
    default:
      return ''
  }
}

export default {
  formAPIURL: function (version='v1', path='', params={}) {
    const query = new URLSearchParams(params).toString()
    return `${apiOrigin}/${version}${path}${query ? '?' + query : ''}`
  },
  parseSearch,
  getSubdomain,
}
