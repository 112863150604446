import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { colorOpacity, colorGrayscale } from '@twreporter/core/lib/constants/color'
import { H5 } from '@twreporter/react-components/lib/text/headline'
import { P2 } from '@twreporter/react-components/lib/text/paragraph'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${colorOpacity['white_0.5']};
  ${mq.mobileOnly`
    padding: 32px 24px;
    gap: 8px;
  `}
  ${mq.tabletAndAbove`
    height: 240px;
    padding: 32px 72px;
    gap: 16px;
  `}
`

const Icon = styled.img`
  height: 40px;
  width: 40px;
`

const StyledH5 = styled(H5)`
  color: ${colorGrayscale.gray800};
`

const StyledP2 = styled(P2)`
  color: ${colorGrayscale.gray800};
  max-width: 400px;
`

const Box = ({ iconUrl, title, content }) => {
  return (
    <Container>
      <Icon src={iconUrl} />
      <StyledH5 text={title} />
      <StyledP2 text={content} />
    </Container>
  )
}

Box.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  iconUrl: PropTypes.string,
}

export default Box

