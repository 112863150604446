export const envConsts = {
  development: 'development',
  production: 'production',
}

export default {
  nodeEnv: process.env.NODE_ENV || envConsts.development,
  releaseBranch: process.env.RELEASE_BRANCH || 'master' ,
  port: parseInt(process.env.PORT) || 3000,
  // Specify maintenance info to notify users 
  maintenanceInfo: {
    title: '',
    desc: '',
  },
}
