import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createSlice } from '@reduxjs/toolkit'
// utils
import urlUtils from '../utils/url'
// constants
import supportConsts from '../constants/support'
// lodash
import assign from 'lodash/assign'
import get from 'lodash/get'
const _ = {
  assign,
  get,
}

const version1 = 'v1'
const endpointPath = {
  periodic: 'periodic-donations',
  oneTime: 'donations/prime',
}

/**
 *  @param {string} frequency - contribute frequency, 'yearly', 'monthly' or 'one-time'
 *  @returns {string} - emdpoint path
 */
function selectEndpointPathByFrequency(frequency) {
  switch (frequency) {
    case supportConsts.contribute.frequency.oneTime: {
      return endpointPath.oneTime
    }
    case supportConsts.contribute.frequency.monthly:
    case supportConsts.contribute.frequency.yearly: {
      return endpointPath.periodic
    }
    default:
      return
  }
}

/*
 *  contributeApi: apis for contribute
 */

export const contributeApi = createApi({
  reducerPath: 'contributeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urlUtils.formAPIURL(version1),
    timeout: supportConsts.apiTimeout,
    prepareHeaders: (headers, { extra }) => {
      const cookie = _.get(extra, 'defaults.headers.cookie')
      if (cookie) {
        headers.set('cookie', cookie)
      }
      return headers
    },
  }),
  tagTypes: [ 'Donation' ],
  endpoints: (builder) => ({
    contributeByCreditCard: builder.mutation({
      query: ({ prime, amount, userData, frequency, payment = supportConsts.contribute.payMethod.creditCard }) => ({
        url: `/${selectEndpointPathByFrequency(frequency)}`,
        method: 'POST',
        body: {
          prime,
          amount,
          currency: supportConsts.contribute.currency.twd,
          merchant_id: supportConsts.contribute.merchantID[payment],
          donor: {
            email: _.get(userData, 'email', ''),
          },
          frequency,
          user_id: _.get(userData, 'user_id'),
          pay_method: payment,
        },
        withCredentials: true,
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${_.get(userData, 'jwt', '')}`,
        },
      }),
    }),
    updateContributeRecord: builder.mutation({
      query: ({ userData, orderNum, updates, frequency }) => ({
        url: `/${selectEndpointPathByFrequency(frequency)}/orders/${orderNum}`,
        method: 'PATCH',
        body: _.assign({}, updates, { user_id: _.get(userData, 'user_id') }),
        withCredentials: true,
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${_.get(userData, 'jwt', '')}`,
        },
      }),
      invalidatesTags: [ 'Donation' ],
    }),
    fetchOneDonationRecord: builder.query({
      query: ({ userData, orderNum, frequency }) => ({
        url: `/${selectEndpointPathByFrequency(frequency)}/orders/${orderNum}`,
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${_.get(userData, 'jwt', '')}`,
        },
      }),
      providesTags: [ 'Donation' ],
    }),
    fetchLinePayVerificationInformation: builder.query({
      query: ({ userData, orderNum, frequency }) => ({
        url: `/${selectEndpointPathByFrequency(frequency)}/orders/${orderNum}/transaction_verification`,
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${_.get(userData, 'jwt', '')}`,
        },
        timeout: 10000,
      }),
    }),
    fetchTappayRecord: builder.query({
      query: ({ userData, filter }) => ({
        url: '/tappay_query',
        method: 'POST',
        body: {
          records_per_page: 1,
          filters: filter,
        },
        headers: {
          Authorization: `Bearer ${_.get(userData, 'jwt', '')}`,
        },
        withCredentials: true,
        credentials: 'include',
        timeout: 45000 + 5000, // go-api timeout 45000 + response time 5000
      }),
    }),
  }),
})

export const {
  useContributeByCreditCardMutation,
  useFetchLinePayVerificationInformationQuery,
  useFetchOneDonationRecordQuery,
  useLazyFetchOneDonationRecordQuery,
  useFetchTappayRecordQuery,
  useUpdateContributeRecordMutation,
} = contributeApi

/*
 *  contributeSlice: state for contribute
 */

const contributeSlice = createSlice({
  name: 'contribute',
  initialState: {},
  reducers: {
    changeRenderedStep(state, action) {
      state.step = action.payload
    },
    errorStep(state, action) {
      state.step = supportConsts.contribute.renderedStep.errorMessage
      state.error = action.payload
    },
    resetContributeState(state) {
      state.step = supportConsts.contribute.renderedStep.contribute
    },
    updateOrderNumber(state, action) {
      state.orderNum = action.payload
    },
    updateRecord(state, action) {
      state.record = action.payload
    },
  },
})

export const { changeRenderedStep, errorStep, resetContributeState, updateOrderNumber, updateRecord } = contributeSlice.actions
export default contributeSlice.reducer
