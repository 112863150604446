import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// components
import StructuredValues from './structured-values'
// constants
import predefinedPropTypes from '../../../constants/prop-types'
import supportConsts from '../../../constants/support'
import typography from '../../../constants/typography'
import colors from '../../../constants/colors'
// utils
import {
  prepareOrganizationSubmittingValues,
  preparePersonalSubmittingValues,
} from './prepare-submitting-values'
// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
// lodash
import get from 'lodash/get'
const _ = {
  get,
}

const color = {
  title: colorGrayscale.gray800,
  content: colorGrayscale.gray900,
  background: colors.gray50,
  horiLine: colorGrayscale.gray300,
}

const horizontalMargin = 25

const Container = styled.div`
  background: ${color.background};
  margin: 40px auto;
`

const MetaItem = styled.div`
  width: calc(100% - ${horizontalMargin * 2}px);
  display: flex;
  margin: 0 ${horizontalMargin}px 11px ${horizontalMargin}px;
  &:last-child {
    margin-bottom: 0;
  }
  &>div:first-child {
    color: ${color.title};
    flex: 0 0 5em;
    letter-spacing: 0.8px;
    font-size: 12px;
    font-weight: ${typography.font.weight.normal};
  }
  &>div:last-child {
    color: ${color.content};
    flex: 1 1 auto;
    letter-spacing: 0.8px;
    font-size: 12px;
    font-weight: ${typography.font.weight.normal};
  }
`

const Title = styled.div`
  color: ${color.title};
  margin-bottom: 5px;
  letter-spacing: 0.8px;
  font-size: 16px;
  font-weight: ${typography.font.weight.bold};
`

const Label = styled.div`
  color: ${color.title};
  margin-bottom: 5px;
  letter-spacing: 0.8px;
  font-size: 12px;
  font-weight: ${typography.font.weight.normal};
`

const Value = styled.div`
  color: ${color.content};
  line-height: 1.25;
  letter-spacing: 1.1px;
  font-size: 16px;
  font-weight: ${typography.font.weight.normal};
`

const Item = styled.div`
  width: calc(100% - ${horizontalMargin * 2}px);
  margin: 0 ${horizontalMargin}px 20px ${horizontalMargin}px;
  &:last-child {
    margin-bottom: 0;
  }
`

const Section = styled.div`
  border-bottom: 1px solid ${color.horiLine};
  padding: 30px 0;
  &:first-child {
    border-top: 1px solid ${color.horiLine};
  }
`

const renameKeyName = (obj, oldName, newName) => {
  const { [oldName]: value, ...remainingObj } = obj
  return {
    ...remainingObj,
    [newName]: value,
  }
}

const UpdatedRecord = ({
  record,
}) => {
  const getFrequencyLabel = (frequency) => {
    switch(frequency) {
      case supportConsts.contribute.frequency.oneTime:
        return supportConsts.contribute.frequencyLabel.oneTime
      case supportConsts.contribute.frequency.monthly:
        return supportConsts.contribute.frequencyLabel.monthly
      default:
        console.error('The value of `frequency` is invalid', frequency) // eslint-disable-line no-console
        return ''
    }
  }

  const getPayMethodLabel = (payMethod) => {
    switch(payMethod) {
      case supportConsts.contribute.payMethod.creditCard:
        return supportConsts.contribute.payMethodLabel.creditCard
      case supportConsts.contribute.payMethod.line:
        return supportConsts.contribute.payMethodLabel.line
      default:
        console.error('The value of `payMethod` is invalid', payMethod) // eslint-disable-line no-console
        return ''
    }
  }

  const prepareStructuredValues = (record) => {
    const orgLegalName = _.get(record, 'donor.legal_name', '')
    if (orgLegalName) {
      return prepareOrganizationSubmittingValues(record)
    } else {
      return preparePersonalSubmittingValues(record) 
    }
  }

  const {
    order_number,
    frequency,
    amount,
    currency,
    pay_method,
    ...restObj
  } = renameKeyName(record, 'cardholder', 'donor')

  return (
    <Container>
      <Section>
        <MetaItem>
          <div>贊助編號</div>
          <div>{order_number}</div>
        </MetaItem>
      </Section>
      <Section>
        <Item>
          <Label>方案</Label>
          <Value>{`${getFrequencyLabel(frequency)} ${currency} $${amount}`}</Value>
        </Item>
        <Item>
          <Label>付款方式</Label>
          <Value>{getPayMethodLabel(pay_method)}支付</Value>
        </Item>
      </Section>
      <Section>
        <StructuredValues
          values={prepareStructuredValues(restObj)}
          components={{
            Title,
            Label,
            Value, 
            Item,
          }}
        />
      </Section>
    </Container>
  )
}
UpdatedRecord.propTypes = {
  record: PropTypes.shape({
    order_number: PropTypes.string.isRequired,
    frequency: predefinedPropTypes.contributeFrequency.isRequired,
    currency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    pay_method: predefinedPropTypes.contribute.payMethod.isRequired,
    cardholder: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      phone_number: PropTypes.string,
      security_id: PropTypes.string,
      address_country: PropTypes.string,
      address_state: PropTypes.string,
      address_city: PropTypes.string,
      address_detail: PropTypes.string,
      address_zip_code: PropTypes.string,
      nickname: PropTypes.string,
      gender: PropTypes.string,
      age_range: PropTypes.string,
      read_preference: PropTypes.string,
      words_for_twreporter: PropTypes.string,
    }),
    receipt: PropTypes.shape({
      header: PropTypes.string,
      security_id: PropTypes.string,
      email: PropTypes.string,
      address_country: PropTypes.string,
      address_state: PropTypes.string,
      address_city: PropTypes.string,
      address_detail: PropTypes.string,
      address_zip_code: PropTypes.string,
    }),
    send_receipt: predefinedPropTypes.contribute.sendReceipt.isRequired,
    is_anonymous: PropTypes.bool.isRequired,
  }),
}

export default UpdatedRecord
