import { configureStore } from '@reduxjs/toolkit'
import axios from 'axios'
// rtk api
import { signInApi } from './actions/sign-in-by-email'
import contributeReducer, { contributeApi } from './actions/contribute'
import formReducer from './actions/update-record'
// reducers
import globalEnv from './global-env'
import twreporterRedux from '@twreporter/redux'

/**
 *
 * Create axios instances to server and client respectively.
 * On client side: to bring cookie with the request, withCredentials property should be set to true before making the axios XMLHTTPRequest since axios requests doesn't bring cookie by default setting.
 * On server side: get cookie from client request and that cookie would be append to header of request send from server to API endpoint.
 *
 * @param {Object} initialState
 * @param {string} cookie
 * @returns {Object}
 */
function initializeStore(initialState = {}, cookie) {
  const httpClientWithCookie = cookie
    ? // server side instance
    axios.create({
      headers: { cookie },
    })
    : // client side instance
    axios.create({
      withCredentials: true,
    })

  return configureStore({
    reducer: {
      [signInApi.reducerPath]: signInApi.reducer,
      [contributeApi.reducerPath]: contributeApi.reducer,
      contribute: contributeReducer,
      updateForm: formReducer,
      auth: twreporterRedux.reducers.auth,
      origins: twreporterRedux.reducers.origins,
      globalEnv: (state=globalEnv) => {
        return state
      },
    },
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: httpClientWithCookie,
          cookie,
        },
      })
        .concat(signInApi.middleware)
        .concat(contributeApi.middleware),
    devTools: process.env.NODE_ENV !== 'production',
  })
}

export default {
  initializeStore,
}
