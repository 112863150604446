import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import typography from '../../../constants/typography'
import { colorBrand } from '@twreporter/core/lib/constants/color'

const Div = styled.div`
  text-align: left;
  font-size: 14px;
  font-weight: ${typography.font.weight.bold};
  color: ${colorBrand.heavy};
`

const InvalidMessage = ({
  message = '',
  show = false,
}) => (
  show && message ? <Div>{message}</Div> : null
)

InvalidMessage.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
}

export default InvalidMessage
