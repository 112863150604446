import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
// constants
import supportConsts from '../../constants/support'
import wellDefinedPropTypes from '../../constants/prop-types'
// components
import Authenticate from '../../components/support/authenticate-page'
import Steps from '../../components/support/steps'
import Layout from '../../components/support/layout-redesign'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
// lodash
import get from 'lodash/get'
import assign from 'lodash/assign'
const _ = {
  assign,
  get,
}
// global var
const currentStep = 1

const Container = styled.div`
  background-color: ${colorGrayscale.gray100};
  min-height: 100vh;
`

const StepsBox = styled(Steps)`
  margin-bottom: 72px;
  ${mq.mobileOnly`
    margin-bottom: 48px;
  `}
`

const AuthenticatePage = ({
  // Props below given by react-router
  location,
  // history is provided by `BrowserRouter` of react-router-dom
  history,
}) => {
  const userInfo = useSelector((state) => _.get(state, 'auth.userInfo', {}))
  const jwt = useSelector((state) => _.get(state, 'auth.accessToken', ''))
  const userData = _.assign({}, userInfo, { jwt })
  const search = _.get(location, 'search', '')
  const redirectToAfterAuth = `${supportConsts.env.app.url}/contribute${search}`
  return (
    <Container>
      <Layout>
        <StepsBox currentStep={currentStep} />
        <Authenticate
          email={_.get(userData, 'email', '')}
          destination={redirectToAfterAuth}
          goToNextStep={() => history.push({
            pathname: '/contribute',
            search,
          })}
        />
      </Layout>
    </Container>
  )
}
AuthenticatePage.propTypes = {
  // Props below given by react-router
  location: wellDefinedPropTypes.location.isRequired,
  // history is provided by `BrowserRouter` of react-router-dom
  history: PropTypes.object.isRequired,
}

export default withRouter(AuthenticatePage)
