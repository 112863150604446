'use strict'
import { Switch, Route } from 'react-router-dom'
import AppErrorBoundary from './components/app-error-boundary'
import getRoutes from './utils/get-routes'
import PropTypes from 'prop-types'
import React from 'react'

const App = ({ subdomain }) => {
  const routes = getRoutes(subdomain)
  return (
    <AppErrorBoundary>
      <Switch>
        {
          routes.map((route, routeIndex) => (
            <Route key={`route-${routeIndex}`} {...route} />
          ))
        }
      </Switch>
    </AppErrorBoundary>
  )
}
App.propTypes = {
  subdomain: PropTypes.string.isRequired,
}

export default App
