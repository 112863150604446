import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// component
import bsc from '../basic-styled-components'
// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import { H5, H4 } from '@twreporter/react-components/lib/text/headline'
import { P1 } from '@twreporter/react-components/lib/text/paragraph'

const Box = styled(bsc.CenteredBoxModel)`
  max-width: 275px;
  min-height: 50vh;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled(H5)`
  color: ${colorGrayscale.gray800};
`

const Subtitle = styled(H4)`
  margin-top: 15px;
  word-break: break-word;
  color: ${colorGrayscale.gray800};
`

const Message = styled(P1)`
  margin-top: 20px;
  word-break: break-word;
  text-align: center;
  color: ${colorGrayscale.gray800};
`

const ErrorInform = ({
  title = '',
  subtitle = '',
  message = '',
  ...props
}) => (
  <Box {...props}>
    {title && <Title text={title} />}
    {subtitle && <Subtitle text={subtitle} />}
    {message && <Message text={message} />}
  </Box>
)
ErrorInform.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  message: PropTypes.string,
}

export default ErrorInform
