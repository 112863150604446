import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'

// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'
import { TextButton } from '@twreporter/react-components/lib/button'
import Divider from '@twreporter/react-components/lib/divider'

// components
import PayByOnline from './pay-by-online'
import PayByATMnFax from './pay-by-atm-and-fax'
import PayByGlobal from './pay-by-global'

// constants
import { PAY_METHODS } from './constants'

// context
import { RoleContext } from '../../../../contexts'

// lodash
import throttle from 'lodash/throttle'

const _ = {
  throttle,
}

const Container = styled.div`
  width: 100%;
  max-width: 356px;
  background-color: ${colorGrayscale.white};
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: ${props => props.$paddingX}px;
  padding-right: ${props => props.$paddingX}px;
`

const Tab = styled.div`
  width: 100%;
  display: flex;
  ${props => props.$containerWidth <= 279 ? `
    justify-content: space-between;
  ` : `
    gap: 24px;
    justify-content: center;
  `}
`

const DividerContainer = styled(Divider)`
  margin-top: 16px;
  margin-bottom: 32px;
`

const PayByBlockContainer = styled.div`
  height: max-content;
`

const ContributeBox = () => {
  const [ method, setMethod ] = useState(PAY_METHODS.online.value)
  const [ containerWidth, setContainerWidth ] = useState(0)
  const [ containerPaddingX, setContainerPaddingX ] = useState(16)
  const ContainerRef = useRef(null)
  const { role, tick } = useContext(RoleContext)

  useEffect(() => {
    const handleResize = _.throttle(() => {
      const offsetWidth = ContainerRef.current.offsetWidth
      setContainerWidth(offsetWidth)
      if (offsetWidth <= 219) {
        setContainerPaddingX(8)
      } else if (offsetWidth >= 220 && offsetWidth <= 279) {
        setContainerPaddingX(16)
      } else if (offsetWidth >= 280 && offsetWidth <= 355) {
        setContainerPaddingX(16)
      } else {
        setContainerPaddingX(32)
      }
    }, 150)
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (method !== PAY_METHODS.online.value) {
      setMethod(PAY_METHODS.online.value)
    }
  }, [ role, tick ])

  let PayByBlockJSX

  switch (method) {
    case PAY_METHODS.atmAndFax.value:
      PayByBlockJSX = PayByATMnFax
      break
    case PAY_METHODS.global.value:
      PayByBlockJSX = PayByGlobal
      break
    default:
      PayByBlockJSX = PayByOnline
      break
  }

  return (
    <Container ref={ContainerRef} $paddingX={containerPaddingX}>
      <Tab $containerWidth={containerWidth}>
        {Object.keys(PAY_METHODS).map((key) => {
          return <TextButton
            key={key}
            active={method === PAY_METHODS[key].value}
            text={PAY_METHODS[key].text}
            onClick={() => setMethod(PAY_METHODS[key].value)} 
            style={TextButton.Style.LIGHT}
          />
        })}
      </Tab>
      <DividerContainer />
      <PayByBlockContainer>
        <PayByBlockJSX />
      </PayByBlockContainer>
    </Container>
  )
}

export default ContributeBox
