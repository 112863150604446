import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
// actions
import { useUpdateContributeRecordMutation, useLazyFetchOneDonationRecordQuery, changeRenderedStep, errorStep, resetContributeState, updateRecord, contributeApi } from '../../actions/contribute'
// utils
import shouldRedirectToLoginPage from '../../utils/should-redirect-to-login-page'
import urlUtils from '../../utils/url'
// constants
import accountsConst from '../../constants/accounts'
import supportConsts from '../../constants/support'
import wellDefinedPropTypes from '../../constants/prop-types'
// components
import AfterContribute from '../../components/support/after-contribute'
import Loading from '../../components/loading'
import Message from '../../components/support/message-page'
import NavigationPrompt from '../../components/support/navigation-prompt'
import RedirectMessage from '../../components/support/redirect-message'
import UpdateDonationRecordPage from '../../components/support/update-donation-record-page'
import UpdatedRecord from '../../components/support/update-donation-record-page/updated-record'
// @twreporter
import { P2 } from '@twreporter/react-components/lib/text/paragraph'
// lodash
import assign from 'lodash/assign'
import get from 'lodash/get'
import merge from 'lodash/merge'
const _ = {
  assign,
  get,
  merge,
}

export const loadTheContributeRecord = (store, match) => {
  const frequency = _.get(match, 'params.frequency')
  const orderNum = _.get(match, 'params.orderNum')
  const authState = _.get(store.getState(), 'auth', {})
  const { userInfo={} , accessToken='' } = authState
  const userData = _.assign({}, userInfo, { jwt: accessToken } )
  const callback = ({ data, isError }) => {
    if (isError) {
      store.dispatch(changeRenderedStep(renderedStepConsts.errorMessage))
      return
    }
    store.dispatch(updateRecord(data.data))
    store.dispatch(changeRenderedStep(renderedStepConsts.initiateUpdateContributeRecordForm))
  }

  return store.dispatch(contributeApi.endpoints.fetchOneDonationRecord.initiate({ userData, orderNum, frequency }))
    .then(callback)

}

const Guide = styled(P2)`
  display: block;
`

const renderedStepConsts = supportConsts.contribute.renderedStep
const UpdateDonationRecord = ({
  match,
  location,
  history,
}) => {
  // redux state selector
  const authState = (state) => _.get(state, 'auth')
  const contributeState = (state) => _.get(state, 'contribute')

  const userInfoSelector = createSelector(authState, (auth) => ({
    userInfo: _.get(auth, 'userInfo', {}),
    jwt: _.get(auth, 'accessToken', ''),
  }))
  const contributeDetailSelector = createSelector(contributeState, (contribute) => ({
    renderedStep: _.get(contribute, 'step', ''),
    errorType: _.get(contribute, 'error'),
    record: _.get(contribute, 'record', {}),
  }))
  const { userInfo, jwt } = useSelector(userInfoSelector)
  const { renderedStep, errorType, record } = useSelector(contributeDetailSelector)

  const userData = useMemo(() => (_.assign({}, userInfo, { jwt })), [ userInfo, jwt ])
  const toShowRedirectMessage = useMemo(() => shouldRedirectToLoginPage(userData), [ userData ])
  const canUserLeave = useMemo(() => (renderedStep === renderedStepConsts.errorMessage ||
    renderedStep === renderedStepConsts.successMessage ||
    toShowRedirectMessage), [ renderedStep, toShowRedirectMessage ])
  const search = useMemo(() => _.get(location, 'search', ''), [ location ])
  const pathname = useMemo(() => _.get(location, 'pathname', ''), [ location ])
  const frequency = useMemo(() => _.get(match, 'params.frequency'), [ match ])
  const orderNum = useMemo(() => _.get(match, 'params.orderNum'), [ match ])
  const dispatch = useDispatch()
  const [ fetchDonationRecord, fetchResult ] = useLazyFetchOneDonationRecordQuery()
  const [ updateContributeRecord, updateResult ] = useUpdateContributeRecordMutation()

  useEffect(() => {
    if (fetchResult.isError) {
      dispatch(errorStep(supportConsts.contribute.errorType.getContributeRecord))
    }
  }, [ fetchResult.isError ])

  useEffect(() => {
    if (!updateResult.isSuccess) {
      return
    }

    // fetch latest donation record after updates
    fetchDonationRecord({ userData, orderNum, frequency })

  }, [ updateResult.isSuccess ])

  useEffect(() => {
    // fetch donation record successfully
    if (fetchResult.isSuccess) {
      // update redux store the latest donation record
      dispatch(updateRecord(fetchResult.data?.data))
      // re-render the page
      dispatch(changeRenderedStep(renderedStepConsts.successMessage))
    }
  }, [ fetchResult ])

  useEffect(() => {
    if (updateResult.isError) {
      dispatch(errorStep(supportConsts.contribute.errorType.updateContributeRecord))
    }
  }, [ updateResult.isError ])

  const renderErrorPage = () => {
    switch (errorType) {
      case supportConsts.contribute.errorType.getContributeRecord: {
        const email = _.get(userData, 'email', '')
        const loginHref = `${accountsConst.env.app.url}?${new URLSearchParams({
          destination: supportConsts.env.app.url + pathname + search,
        }).toString()}`
        const logoutHref = urlUtils.formAPIURL('v2', '/auth/logout', {
          destination: loginHref,
        })
        const onButtonClick = () => {
          window.open(logoutHref, '_self')
        }
        const guide = (
          <Guide>
            <span>{`很抱歉，您所查詢的贊助紀錄不存在，或您目前的登入帳號${ email ? `（${email}）` : ''}並非此筆贊助的贊助者，因此沒有權限讀取，請登出後再嘗試。若再次嘗試仍遇到相同狀況，請`}</span>
            <a href="mailto:events@twreporter.org">聯絡我們</a>
            <span>為您檢查問題，謝謝。</span>
          </Guide>
        )
        return (
          <Message
            title="您無權限讀取贊助紀錄"
            guide={guide}
            buttonText="登出帳號"
            buttonOnclick={onButtonClick}
          />
        )
      }
      case supportConsts.contribute.errorType.updateContributeRecordPreRequestValidation: {
        const onButtonClick = () => {
          window.open('mailto:events@twreporter.org', '_blank')
        }
        return (
          <Message
            style={Message.Style.PENCIL}
            title="更新資料失敗"
            guide="很抱歉，資料在驗證時出現問題，請重新整理後再嘗試一次。若再次嘗試仍無法解決，請聯絡我們為您檢查問題並更新您的資料，謝謝。"
            buttonText="聯絡我們"
            buttonOnclick={onButtonClick}
          />
        )
      }
      case supportConsts.contribute.errorType.updateContributeRecord: {
        const onButtonClick = () => {
          window.open('mailto:events@twreporter.org', '_blank')
        }
        return (
          <Message
            style={Message.Style.PENCIL}
            title="更新資料失敗"
            guide="很抱歉，更新資料時出現問題，請重新整理後再嘗試一次。若再次嘗試仍無法解決，請聯絡我們為您檢查問題並更新您的資料，謝謝。"
            buttonText="聯絡我們"
            buttonOnclick={onButtonClick}
          />
        )
      }
      default: {
        const onButtonClick = () => {
          window.open('/intro', '_self')
        }
        const guide = (
          <Guide>
            <span>如果您重複遇到這個問題，請過一段時間再重新嘗試連到我們的網站，或可以</span>
            <a href="mailto:events@twreporter.org">聯絡我們</a>
            <span>為您檢查問題，謝謝！</span>
          </Guide>
        )
        return (
          <Message
            title="發生未知的錯誤"
            guide={guide}
            buttonText="回到贊助頁"
            buttonOnclick={onButtonClick}
          />
        )
      }
    }
  }

  const renderPageContent = () => {
    switch(renderedStep) {
      case renderedStepConsts.errorMessage: {
        return renderErrorPage()
      }
      case renderedStepConsts.successMessage: {
        return (
          <AfterContribute
            handleReturnButtonClicked={() => dispatch(changeRenderedStep(renderedStepConsts.updateContributeRecordForm))}
          >
            <UpdatedRecord record={record} />
          </AfterContribute>
        )
      }
      case renderedStepConsts.updateContributeRecordForm:
      default: {
        // Only if users from contrubute step (step 2), a step property has been set in the location.state object.
        // For users who came from other places, for example: email, there are no such step setting.
        // By utilizing the difference, we can distinguish users and provides them with different page content
        const fromContributeStep = _.get(history, 'location.state.step') === renderedStepConsts.updateContributeRecordForm
        const updateRecord = (updates) => {
          // validate update record
          if (!_.get(userData, 'user_id')) {
            return dispatch(errorStep(supportConsts.contribute.errorType.updateContributeRecordPreRequestValidation))
          }
          updateContributeRecord({ userData, orderNum, updates, frequency })
        }

        return (
          <UpdateDonationRecordPage
            updateRecord={updateRecord}
            userData={userData}
            formData={record}
            frequency={frequency}
            fromContributeStep={fromContributeStep}
            pageTitle="感謝你支持報導者！"
            pageSubtitle="就差最後一哩路"
          />
        )
      }
    }
  }
  const renderRedirectMessage = () => {
    return (
      <RedirectMessage
        history={history}
        toShow={toShowRedirectMessage}
        message="為了後續與您聯繫，請先登入報導者網站。您可以選擇 Facebook、Google 或是電子信箱（擇其一）的方式登入報導者網站。登入資訊僅作為報導者與您聯絡時使用"
      />
    )
  }

  return (
    <React.Fragment>
      {renderRedirectMessage()}
      <NavigationPrompt
        toPrompt={() => !canUserLeave}
        actionBeforeUnmount={() => dispatch(resetContributeState())}
        redirectToAfterConfirm={{
          pathname: '/intro',
          search,
        }}
      />
      {renderPageContent()}
      <Loading show={fetchResult.isLoading} />
    </React.Fragment>
  )
}

UpdateDonationRecord.propTypes = {
  // Props below given by react-router
  match: wellDefinedPropTypes.match.isRequired,
  location: wellDefinedPropTypes.location.isRequired,
  // history is provided by `BrowserRouter` of react-router-dom
  history: PropTypes.object.isRequired,
}

export default withRouter(UpdateDonationRecord)
