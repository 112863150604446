import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// components
import Tooltip from '../tooltip'
// constants
import typography from '../../../constants/typography'
// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'
import { COLOR_SEMANTIC } from '@twreporter/core/lib/constants/color'

const Title = styled.div`
  line-height: 1.31;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: ${typography.font.weight.bold};
  ${mq.mobileOnly`
    margin-top: 30px;
  `}
  &:first-child {
    margin-top: 0;
  }
  ${props => (!props.$required ? '' : `&::after { content: '*'; color: ${COLOR_SEMANTIC.danger}; margin-left: .6em; }`)}
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`

const FieldLabel = ({
  children,
  tooltipMessage = '',
  required = false,
}) => (
  <Container>
    <Title $required={required}>{children}</Title>
    {tooltipMessage ? <Tooltip message={tooltipMessage} /> : null}
  </Container>
)
FieldLabel.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipMessage: PropTypes.string,
  required: PropTypes.bool,
}

export default FieldLabel
