import styled from 'styled-components'
import typography from '../../../../constants/typography'

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  &:first-of-type {
    margin-top: 40px;
  }
  label {
    font-weight: ${props => props.$active ? typography.font.weight.bold : typography.font.weight.normal};
  }
`

export default {
  Option,
}
