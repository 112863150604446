import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// components
import Shared from './shared/styled-components'
import SupportedCreditCards from './shared/supported-credit-cards'
// constants
import supportConsts from '../../../constants/support'
import typography from '../../../constants/typography'
// @twreporter
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

const OptionWithMargin = styled(Shared.Option)`
  margin-bottom: 30px;
`

const Label = styled.p`
  display: block;
  color: ${colorGrayscale.gray900};
  font-weight: ${typography.font.weight.bold};
  font-size: 18px;
  line-height: 1.17;
`

export default class PeriodicContribute extends React.PureComponent {
  static defaultProps = {
    cardType: supportConsts.contribute.cardType.unknown,
  }

  static propTypes = {
    cardType: PropTypes.string,
    directPay: PropTypes.node.isRequired,
  }
 
  render() {
    const {
      cardType,
      directPay,
    } = this.props

    return (
      <React.Fragment>
        <OptionWithMargin>
          <Label>{supportConsts.contribute.payMethodLabel.creditCard}</Label>
          <SupportedCreditCards cardType={cardType} />
        </OptionWithMargin>
        {directPay}
      </React.Fragment>
    )
  }
}
