import globalEnv from '../global-env'
// @twreporter
import releaseBranchConst from '@twreporter/core/lib/constants/release-branch'

export default {
  [releaseBranchConst.master]: {
    main: {
      url: 'https://www.twreporter.org',
    },
    support: {
      url: 'https://support.twreporter.org',
    },
    accounts: {
      url: 'https://accounts.twreporter.org',
    },
  },
  [releaseBranchConst.dev]: {
    main: {
      url: 'https://dev.twreporter.org',
    },
    support: {
      url: 'https://dev-support.twreporter.org',
    },
    accounts: {
      url: 'https://dev-accounts.twreporter.org',
    },
  },
  [releaseBranchConst.staging]: {
    main: {
      url: 'https://staging.twreporter.org',
    },
    support: {
      url: 'https://staging-support.twreporter.org',
    },
    accounts: {
      url: 'https://staging-accounts.twreporter.org',
    },
  },
  [releaseBranchConst.release]: {
    main: {
      url: 'https://www.twreporter.org',
    },
    support: {
      url: 'https://support.twreporter.org',
    },
    accounts: {
      url: 'https://accounts.twreporter.org',
    },
  },
}[globalEnv.releaseBranch]
