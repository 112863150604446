import React, { useState } from 'react'
import styled from 'styled-components'

// @twreporter
import { colorGrayscale, colorSupportive, COLOR_SEMANTIC } from '@twreporter/core/lib/constants/color'
import { P1, P2, P3 } from '@twreporter/react-components/lib/text/paragraph'
import { LinkButton } from '@twreporter/react-components/lib/button'

const Block = styled.div`
  height: ${props => props.$height}px;
`

const Container = styled.div`
  width: 100%;
`

const AccountInfo = styled.div`
  border-left: 1px solid ${colorGrayscale.gray800};
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`

const P2Gray600 = styled(P2)`
  color: ${colorGrayscale.gray600};
`

const P3Gray600 = styled(P3)`
  color: ${colorGrayscale.gray600};
`

const P1Gray800 = styled(P1)`
  color: ${colorGrayscale.gray800};
`

const P1SupportiveHeavy = styled(P1)`
  color: ${colorSupportive.heavy};
`

const CopyButton = styled(P3)`
  color: ${colorSupportive.heavy};
  cursor: pointer;
  border-radius: 3px;
  border: solid 0.5px ${colorSupportive.heavy};
  font-size: 10px;
  line-height: 2;
  margin-left: 8px;
  padding-left: 5px;
  padding-right: 5px;
`

const BlueLink = styled(LinkButton)`
  color: ${COLOR_SEMANTIC.info} !important;
`

const PayByATMnFax = () => {
  const [ isCopied, setIsCopied ] = useState(false)
  const emailAddress = 'fenc@twreporter.org'
  const copyText = isCopied ? '已複製' : '複製'
  const copyToClipboard = () => {
    navigator.clipboard.writeText(emailAddress)
    setIsCopied(true)
  }
  return (
    <Container>
      <P2Gray600 text={'銀行轉帳'} weight={P2.Weight.BOLD} />
      <Block $height={16} />
      <AccountInfo>
        <P3Gray600 text={'戶名'} />
        <Block $height={4} />
        <P1Gray800 text={'財團法人報導者文化基金會'} />
        <Block $height={8} />
        <P3Gray600 text={'帳號資訊'} />
        <Block $height={4} />
        <P1Gray800 text={'中國信託 城北分行'} />
        <P1Gray800 text={'(822) 200-540-206-876'} weight={P1.Weight.BOLD} />
      </AccountInfo>
      <Block $height={16} />
      <P1Gray800 text={'為開立收據，請轉帳後來信提供：匯款日期、金額、帳號末五碼、姓名、地址、聯絡電話。'} />
      <P1Gray800 text={'收件人：行政鄭小姐'} />
      <P1SupportiveHeavy weight={P1.Weight.BOLD} text={emailAddress}>
        <CopyButton weight={P3.Weight.BOLD} onClick={copyToClipboard}>{copyText}</CopyButton>
      </P1SupportiveHeavy>
      <Block $height={24} />
      <P2Gray600 text={'傳真刷卡'} weight={P2.Weight.BOLD} />
      <Block $height={16} />
      <BlueLink text={'點此下載信用卡授權書'} href="https://drive.google.com/file/d/1tbkHyjhGlUoZ0Gqwavxdwu9aXo74aDnO/view?usp=sharing" target="_blank"/>
    </Container>
  )
}

export default PayByATMnFax
