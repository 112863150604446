import React from 'react'
import styled from 'styled-components'
// env
import env from '../global-env'
// constants
import siteMap from '../constants/site-map'
// @twreporter
import Link from '@twreporter/react-components/lib/customized-link'
import { SimpleHeader } from '@twreporter/react-components/lib/simple-header'
import { colorGrayscale } from '@twreporter/core/lib/constants/color'

const StyledSimpleHeader = styled(SimpleHeader)`
  background-color: ${colorGrayscale.gray100};
`

const Header = () => {
  const link = { to: siteMap.main.url, isExternal: true }
  return (
    <Link {...link}>
      <StyledSimpleHeader releaseBranch={env.releaseBranch} />
    </Link>
  )
}

export default Header
