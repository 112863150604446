import { createContext } from 'react'
import PropTypes from 'prop-types'
// constant
import siteMap from '../constants/site-map'
// @twreporter
import { MEMBER_ROLE } from '@twreporter/core/lib/constants/member-role'
import { BRANCH, BRANCH_PROP_TYPES } from '@twreporter/core/lib/constants/release-branch'

// constant
import { FREQUENCY } from '../components/support/intro-page/contribute-box/constants'

export const RoleContext = createContext({
  propTypes: {
    tick: PropTypes.bool,
    role: PropTypes.oneOf(Object.values(MEMBER_ROLE)),
    amount: PropTypes.number,
    setAmount: PropTypes.func,
    frequency: PropTypes.oneOf(Object.values(FREQUENCY)),
    setFrequency: PropTypes.func,
  },
})

export const AuthContext = createContext({
  propTypes: {
    destination: PropTypes.string,
    releaseBranch: BRANCH_PROP_TYPES,
    withOnboarding: PropTypes.bool,
  },
  defaultProps: {
    destination: siteMap.main.url,
    releaseBranch: BRANCH.master,
    withOnboarding: false,
  },
})
