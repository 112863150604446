import { Spring } from 'react-spring/renderprops.cjs' 
import PropTypes from 'prop-types'
import Radio from '../radio-group'
import React from 'react'
import Shared from './shared/styled-components'
import SupportedCreditCards from './shared/supported-credit-cards'
import bsc from '../../basic-styled-components'
import mediaQuery from '@twreporter/core/lib/utils/media-query'
import styled from 'styled-components'
import supportConsts from '../../../constants/support'

const DirectPayWrapper = styled.div.attrs(props => ({
  style: {
    height: props.$height,
    marginTop: props.$marginTop,
    marginBottom: props.$marginBottom,
  },
}))`
  overflow: hidden;
`

const LineIcon = styled(bsc.FloatRightBoxModel)` 
  > img {
    width: 60px;
  }
  ${mediaQuery.mobileOnly`
    > img {
      width: 63.5px;
    }
  `}
`

export default class OneTimeContribute extends React.PureComponent {
  static defaultProps = {
    payMethod: supportConsts.contribute.payMethod.creditCard,
    cardType: supportConsts.contribute.cardType.unknown,
  }

  static propTypes = {
    cardType: PropTypes.string,
    onPaymentChange: PropTypes.func.isRequired,
    payMethod: PropTypes.string,
    directPay: PropTypes.node.isRequired,
    linePay: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      payMethod: supportConsts.contribute.payMethod.creditCard,
    }
  }
 
  handleChange = (e) => {
    this.props.onPaymentChange(e.target.value)
  }
  
  render() {
    const {
      cardType,
      directPay,
      linePay,
      payMethod,
    } = this.props

    const payByCard = payMethod === supportConsts.contribute.payMethod.creditCard
 
    const animatedDirectPay = ( 
      <Spring
        from={{ 
          height: payByCard ? 0 : 'auto',
          marginTop: payByCard ? 0 : 30,
          marginBottom: payByCard ? 0 : 30,
        }}
        to={{ 
          height: payByCard ? 'auto' : 0, 
          marginTop: payByCard ? 30 : 0, 
          marginBottom: payByCard ? 30 : 0,
        }}
      >
        {
          props => (
            <DirectPayWrapper 
              $height={props.height} 
              $marginTop={props.marginTop} 
              $marginBottom={props.marginBottom}
            >
              {directPay}
            </DirectPayWrapper>
          )
        }
      </Spring>
    )

    return (
      <React.Fragment>
        <Shared.Option
          $active={payMethod === supportConsts.contribute.payMethod.creditCard}
        >
          <Radio
            handleChange={this.handleChange}
            options={[
              { 
                label: supportConsts.contribute.payMethodLabel.creditCard, 
                value: supportConsts.contribute.payMethod.creditCard, 
              },
            ]}
            name={`payment-${supportConsts.contribute.payMethodLabel[supportConsts.contribute.payMethod.creditCard]}`}
            checkedValue={payMethod}
            diameter={{
              desktop: 20,
              mobile: 26,
            }}
          />
          <SupportedCreditCards cardType={cardType} />
        </Shared.Option>
        {animatedDirectPay}
        <Shared.Option
          $active={payMethod === supportConsts.contribute.payMethod.line}
        >
          <Radio
            handleChange={this.handleChange}
            options={[
              { 
                label: supportConsts.contribute.payMethodLabel.line, 
                value: supportConsts.contribute.payMethod.line, 
              },
            ]}
            name={`payment-${supportConsts.contribute.payMethodLabel[supportConsts.contribute.payMethod.line]}`}
            checkedValue={payMethod}
            diameter={{
              desktop: 20,
              mobile: 26,
            }}
          />
          <LineIcon><img src={`/asset/icon-linepay${payMethod === supportConsts.contribute.payMethod.line ? '' : '-grey'}.svg`} /></LineIcon>
          {payMethod === supportConsts.contribute.payMethod.line ? linePay : null}
        </Shared.Option>
      </React.Fragment>
    )
  }
}
