/* global __DEVELOPMENT__ */
import subdomainConst from '../constants/subdomain'
import routes from '../routes'

export default function getRoutes(subdomain) {
  if (__DEVELOPMENT__) {
    return routes.all
  }
  switch (subdomain) {
    case subdomainConst.accounts:
      return routes.accounts
    case subdomainConst.support:
      return routes.support
    default:
      console.error('You must specify a subdomain but is', subdomain) // eslint-disable-line no-console
      return []
  }
}
