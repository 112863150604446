import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import TagManger from 'react-gtm-module'
// actions
import { setRole } from '../../../actions/update-record'
// constants
import supportConsts from '../../../constants/support'
import wellDefinedPropTypes from '../../../constants/prop-types'
// components
import Form from './form'
import LayoutRedesign from '../layout-redesign'
import Steps from '../steps'
// @twreporter
import mediaQuery from '@twreporter/core/lib/utils/media-query'
// lodash
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
const _ = {
  get,
  isNil,
  map,
}

// global const
const currentStep = 3

const StepsContainer = styled.div`
  margin-bottom: 65px;
  ${mediaQuery.mobileOnly`
    margin-bottom: 89px;
  `} 
`

const UpdateDonationRecordPage = ({
  frequency,
  updateRecord,
  userData,
  formData,
  fromContributeStep,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      TagManger.dataLayer({
        dataLayer: {
          event: 'add_shipping_info',
        },
      })
      window.scrollTo({ top: 0 })
    }
  }, [])

  const getRole = (cardholder) => {
    const orgLegalName = _.get(cardholder, 'legal_name', '')
    if (orgLegalName) {
      return supportConsts.contribute.role.organization
    } else {
      return supportConsts.contribute.role.person
    }
  }

  const getFormValue = (obj, path, defaultValue='') => {
    if (!obj) {
      return
    }
    const value = _.get(obj, path)
    return _.isNil(value) ? defaultValue : value
  }

  const getReadPreference = (obj) => {
    let readPreference = {}
    const readPreferenceString = getFormValue(obj, 'read_preference', '')
    if (readPreferenceString) {
      _.map(readPreferenceString.split(','), (preference) => {
        readPreference[preference] = true
      })
    }
    return readPreference
  }

  const getSecurityId = ({ role, cardholder, receipt }) => {
    const securityId = getFormValue(cardholder, 'security_id', '')
    const receiptSecurityId = getFormValue(receipt, 'security_id', '')

    switch(role) {
      case supportConsts.contribute.role.organization:
        return {
          organization_security_id: securityId,
          organization_receipt_security_id: receiptSecurityId,
        }
      case supportConsts.contribute.role.person:
      default:
        return {
          person_security_id: securityId,
          person_receipt_security_id: receiptSecurityId,
        }
    }
  }

  const getFormValuesByProps = (formData) => {
    const cardholder = _.get(formData, 'cardholder') || {}
    const receipt = _.get(formData, 'receipt') || {}
    const role = getRole(cardholder)
    dispatch(setRole(role))
    const securityId = getSecurityId({ role, cardholder, receipt })

    return {
      role,
      legal_name: getFormValue(cardholder, 'legal_name', ''),
      name: getFormValue(cardholder, 'name', ''),
      title: getFormValue(cardholder, 'title', ''),
      first_name: getFormValue(cardholder, 'first_name', ''),
      last_name: getFormValue(cardholder, 'last_name', ''),
      is_anonymous: getFormValue(formData, 'is_anonymous', false),
      send_receipt: getFormValue(formData, 'send_receipt', supportConsts.contribute.sendReceipt.no),
      phone_number: getFormValue(cardholder, 'phone_number', ''),
      address: {
        country: getFormValue(cardholder, 'address_country'),
        state: getFormValue(cardholder, 'address_state'),
        city: getFormValue(cardholder, 'address_city'),
        elseString: getFormValue(cardholder, 'address_detail', ''),
        zip_code: getFormValue(cardholder, 'address_zip_code'),
      },
      receipt_header: getFormValue(receipt, 'header', ''),
      receipt_address: {
        country: getFormValue(receipt, 'address_country'),
        state: getFormValue(receipt, 'address_state'),
        city: getFormValue(receipt, 'address_city'),
        elseString: getFormValue(receipt, 'address_detail', ''),
        zip_code: getFormValue(receipt, 'address_zip_code'),
      },
      receipt_email: userData.email,
      nickname: getFormValue(cardholder, 'nickname', ''),
      gender: getFormValue(cardholder, 'gender', supportConsts.contribute.gender.unselected),
      age: getFormValue(cardholder, 'age_range', supportConsts.contribute.age['unselected']),
      words_for_twreporter: getFormValue(cardholder, 'words_for_twreporter', ''),
      read_preference: getReadPreference(cardholder),
      ...securityId,
    }
  }

  let stepsJSX
  if (fromContributeStep) {
    stepsJSX = (
      <StepsContainer>
        <Steps currentStep={currentStep} />
      </StepsContainer>
    )
  }
  const formValues = useMemo(() => getFormValuesByProps(formData), [ formData ])

  return (
    <LayoutRedesign>
      {stepsJSX}
      <Form
        userData={userData}
        frequency={frequency} 
        updateRecord={updateRecord}
        defaultValues={formValues} 
      />
    </LayoutRedesign>
  )
}
UpdateDonationRecordPage.propTypes = {
  frequency: wellDefinedPropTypes.contributeFrequency.isRequired,
  updateRecord: PropTypes.func.isRequired,
  // The schema of resopnse data from backend API
  formData: PropTypes.shape({
    cardholder: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      phone_number: PropTypes.string,
      security_id: PropTypes.string,
      zip_code: PropTypes.string,
      address_country: PropTypes.string,
      address_state: PropTypes.string,
      address_city: PropTypes.string,
      address_detail: PropTypes.string,
      address_zip_code: PropTypes.string,
      nickname: PropTypes.string,
      gender: PropTypes.string,
      age_range: PropTypes.string,
      read_preference: PropTypes.string,
      words_for_twreporter: PropTypes.string,
    }),
    receipt: PropTypes.shape({
      header: PropTypes.string,
      security_id: PropTypes.string,
      email: PropTypes.string,
      address_country: PropTypes.string,
      address_state: PropTypes.string,
      address_city: PropTypes.string,
      address_detail: PropTypes.string,
      address_zip_code: PropTypes.string,
    }),
    send_receipt: wellDefinedPropTypes.contribute.sendReceipt,
    is_anonymous: PropTypes.bool,    
  }),
  fromContributeStep: PropTypes.bool.isRequired,
}

export default UpdateDonationRecordPage
