import React from 'react'
import styled from 'styled-components'

// @twreporter
import mq from '@twreporter/core/lib/utils/media-query'

// utils
import { getImageUrl } from '../../../../utils/get-gcs-images'

const FirstImageUrl = getImageUrl('reporter-work-1.png')
const SecondImageUrl = getImageUrl('reporter-work-2.png')
const ThirdImageUrl = getImageUrl('reporter-work-3.png')

const FirstImage = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center; 
`

const SecondImage = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: left center; 
`

const ThirdImage = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center; 
`

const ImagesConatiner = styled.div`
  height: 100%;
  ${mq.mobileOnly`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    img {
      height: 120px;
    }
  `}
  ${mq.tabletAndAbove`
    img {
      height: 33.33%;
    }
  `}
`

const Images = () => {
  return(
    <ImagesConatiner>
      <FirstImage src={FirstImageUrl} />
      <SecondImage src={SecondImageUrl} />
      <ThirdImage src={ThirdImageUrl} />
    </ImagesConatiner>
  )
}

export default Images
