import Bsc from './basic-styled-components'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const spinnerDataUri = '/asset/spinner-logo.gif'

const Spinner = styled.img`
  position: absolute;
  display: block;
  width: 71px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Loading = ({ show }) => (
  <Bsc.Dimmer $show={show} $light>
    <Spinner src={spinnerDataUri} />
  </Bsc.Dimmer>
)

Loading.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default Loading
