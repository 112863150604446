/* global TPDirect */
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// components
import Tooltip from '../../tooltip'
import bsc from '../../../basic-styled-components'
// constants
import supportConsts from '../../../../constants/support'
import tappayConf from '../../../../constants/tappay'
// @twreporter
import mediaQuery from '@twreporter/core/lib/utils/media-query'
import { colorGrayscale, colorSupportive } from '@twreporter/core/lib/constants/color'
// lodash
import get from 'lodash/get'
const _ = {
  get,
}

const Box = styled.div`
  background-color: ${colorGrayscale.white};
  ${mediaQuery.tabletOnly`
    padding: 0 30px 30px 40px;
  `}
  ${mediaQuery.desktopAndAbove`
    padding: 0 30px 30px 30px;
  `}
`

const Group = styled.div`
  padding-top: 30px;

  label {
    padding-bottom: 15px;
  }
  ${mediaQuery.mobileOnly`
    padding: 20px 15px;
  `}
`

const MobileExcludedFlexGroups = styled.div`
  ${mediaQuery.tabletAndAbove`
    display: flex;
    justify-content: space-between;

    > div {
      width: 46%;
    }
  `}
`

const Label = styled.label`
  color: ${colorGrayscale.gray900};
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  display: block;
`

const TappayInput = styled.div`
  position: relative;
  padding-left: 20px;
  width: 100%;
  height: 65px;
  border: solid 1px ${props => props.$borderColor};
  border-radius: 2px;
  &:hover {
    border-color: ${colorSupportive.heavy};
  }

  &.tappay-field-focus {
    border-color: ${colorGrayscale.gray300};
  }
`

const TappyCardNumberInput = styled(TappayInput)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  ${mediaQuery.tabletAndAbove`
    width: calc(100% - 65px);
    display: inline-block;
  `}
`

const Checkbox = styled(bsc.MobileDisplayNone)`
  height: 65px;
  width: 65px;
  position: relative;
  background-color: ${colorGrayscale.gray100};
  ${mediaQuery.tabletAndAbove`
    display: inline-block;
  `}
  >svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const WithTooltip = styled.div`
  display: flex;
  justify-content: space-between;
  > label {
    margin-right: 10px;
  }
`

class DirectPay extends React.PureComponent {
  static propTypes = {
    onCanGetPrime: PropTypes.func.isRequired,
    onCardTypeChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.cardType = supportConsts.contribute.cardType.unknown 
    this.state = {
      isNumberActive: false,
      isExpiryActive: false,
      isCvcActive: false,
    }
    this.checkCardType = this.checkCardType.bind(this)
    this.getPrime = this.getPrime.bind(this)
  }

  componentDidMount() {
    const { 
      onCanGetPrime,
    } = this.props

    if (window.TPDirect) {
      TPDirect.setupSDK(tappayConf.appID, tappayConf.appKey, tappayConf.serverType)
      TPDirect.card.setup({
        fields: {
          number: {
            element: '#tappay-card-number',
            placeholder: '0000 0000 0000 0000',
          },
          expirationDate: {
            element: '#tappay-expiration-date',
            placeholder: 'MM / YY',
          },
          ccv: {
            element: '#tappay-card-cvc',
            placeholder: 'CVV',
          },
        },
        styles: {
          'input': {
            'color': colorGrayscale.gray400,
            'font-size': '18px',
            'font-weight': 'bold',
            'letter-spacing': '1.13px',
          },
          'input:hover': {
            'color': colorGrayscale.gray400,
          },
          'input:focus': {
            'color': colorGrayscale.gray900,
          },
          'input.valid': {
            'color': colorGrayscale.gray900,
          },
        },
      })

      TPDirect.card.onUpdate((update) => {
        let isNumberActive = false
        let isExpiryActive = false
        let isCvcActive = false
        if (_.get(update, 'status.number') === 0) {
          isNumberActive = true
        }

        if (_.get(update, 'status.expiry') === 0) {
          isExpiryActive = true
        }

        if (_.get(update, 'status.cvc') === 0) {
          isCvcActive = true
        }

        onCanGetPrime(update.canGetPrime)

        this.setState({
          isNumberActive,
          isExpiryActive,
          isCvcActive,
        })

        this.checkCardType(update)
      })
    }
  }

  getPrime() {
    return new Promise((resolve, reject) => {
      if (window.TPDirect) {
        return TPDirect.card.getPrime((result) => {
          if (result.status !== 0) {
            return reject(result.msg)
          }
          return resolve(result.card.prime)
        })
      }
      return reject('cannot get prime, TPDirect is not initialized')
    })
  }

  checkCardType(update) {
    const { onCardTypeChange } = this.props
    const newCardType = _.get(update, 'cardType', supportConsts.contribute.cardType.unknown)
    if (newCardType !== this.cardType) {
      this.cardType = newCardType
      onCardTypeChange(newCardType)
    }
    return
  }

  render() {
    const { isNumberActive, isExpiryActive, isCvcActive } = this.state
    return (
      <Box>
        <Group>
          <Label>卡號</Label>
          <TappyCardNumberInput
            id="tappay-card-number"
            $borderColor={isNumberActive ? colorGrayscale.gray600 : colorGrayscale.gray300}
          />
          <Checkbox>
            <svg width="24px" height="24px">
              <g fill="none" fillRule="evenodd" stroke="none">
                <path
                  opacity={isNumberActive ? 1 : 0.3}
                  fill={isNumberActive ? colorSupportive.heavy : colorGrayscale.gray500}
                  fillRule="nonzero"
                  d="M19.4030099,5.29063763 C19.79478,4.90136307 20.4279417,4.90338605 20.8172163,5.29515609 C21.2064908,5.68692613 21.2044679,6.32008787 20.8126978,6.70936243 L9.42796551,18.0215769 C9.0317156,18.4153028 8.38975054,18.4080693 8.00247287,18.0055149 L3.27935247,13.0960837 C2.89645323,12.6980805 2.90869748,12.0650339 3.30670077,11.6821346 C3.70470406,11.2992354 4.33775064,11.3114797 4.72064987,11.7094829 L8.73918566,15.886536 L19.4030099,5.29063763 Z"
                />
              </g>
            </svg>
          </Checkbox>
        </Group>
        <MobileExcludedFlexGroups>
          <Group>
            <Label>有效月年</Label>
            <TappayInput
              id="tappay-expiration-date"
              $borderColor={isExpiryActive ? colorGrayscale.gray600 : colorGrayscale.gray300}
            />
          </Group>
          <Group>
            <WithTooltip>
              <Label>驗證碼</Label>
              <Tooltip message="後三碼" />
            </WithTooltip>
            <TappayInput
              id="tappay-card-cvc"
              $borderColor={isCvcActive ? colorGrayscale.gray600 : colorGrayscale.gray300}
            />
          </Group>
        </MobileExcludedFlexGroups>
      </Box>
    )
  }
}

export default DirectPay
