import React from 'react'

// @twreporter
import { colorBrand } from '@twreporter/core/lib/constants/color'

export const Divider = () => 
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="2" fill="none">
    <path d="M0 1 H4096" stroke={colorBrand.heavy} strokeWidth="2"/>
  </svg>

