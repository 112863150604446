import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// @twreporter
import { P1, P2 } from '@twreporter/react-components/lib/text/paragraph'
import { H4 } from '@twreporter/react-components/lib/text/headline'
import { colorGrayscale, colorSupportive, colorBrand, colorOpacity } from '@twreporter/core/lib/constants/color'
import { MEMBER_ROLE } from '@twreporter/core/lib/constants/member-role'
import { PillButton } from '@twreporter/react-components/lib/button'
import { fontWeight, fontFamily } from '@twreporter/core/lib/constants/font'
import mq from '@twreporter/core/lib/utils/media-query'
import { LinkButton } from '@twreporter/react-components/lib/button'

// utils
import { getImageUrl } from '../../../../utils/get-gcs-images'

// lodash
import throttle from 'lodash/throttle'

const _ = {
  throttle,
}

const RoleStyle = {
  [MEMBER_ROLE.explorer]: {
    topSectionBgColor: `linear-gradient(180deg, transparent 0%, ${colorOpacity['black_0.05']} 100%), ${colorGrayscale.white}`,
    conditionTextColor: colorGrayscale.gray800,
    roleTitleTextColor: colorGrayscale.gray800,
    roleKeyTextColor: colorGrayscale.gray500,
    sloganTextColor: colorGrayscale.gray800,
  },
  [MEMBER_ROLE.action_taker]: {
    topSectionBgColor: colorGrayscale.gray200,
    conditionTextColor: colorGrayscale.gray800,
    roleTitleTextColor: colorGrayscale.gray800,
    roleKeyTextColor: colorSupportive.heavy,
    sloganTextColor: colorGrayscale.gray800,
  },
  [MEMBER_ROLE.trailblazer]: {
    topSectionBgColor: colorGrayscale.gray900,
    conditionTextColor: colorGrayscale.white,
    roleTitleTextColor: colorGrayscale.white,
    roleKeyTextColor: colorSupportive.pastel,
    sloganTextColor: colorGrayscale.white,
  },
}

const RoleCardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  ${mq.desktopAndAbove`
    ${props => props.$isActive && `
      box-shadow: 4px 4px 24px ${colorOpacity['black_0.1']};
      margin: -4px;
    `}
  `}
`

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px ${props => props.$paddingRight}px 24px ${props => props.$paddingLeft}px;
  background: ${props => RoleStyle[props.$roleKey].topSectionBgColor};
`

const ConditionText = styled(P1)`
  color: ${props => RoleStyle[props.$roleKey].conditionTextColor};
`

const RoleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RoleTitle = styled(H4)`
  color: ${props => RoleStyle[props.$roleKey].roleTitleTextColor};
`

const RoleKey = styled(P2)`
  color: ${props => RoleStyle[props.$roleKey].roleKeyTextColor};
`

const Slogan = styled(P2)`
  color: ${props => RoleStyle[props.$roleKey].sloganTextColor};
`

const BottomSection = styled.div`
  padding: 24px ${props => props.$paddingRight}px 24px ${props => props.$paddingLeft}px;
  background: ${colorGrayscale.white};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`

const ListsContainer = styled.div``

const Icon = ({ isActive = false }) => {
  const StyledIcon = styled.div`
    flex: 0 0 24px;
    height: 24px;
    width: 24px;
    background-color: ${isActive ? colorBrand.heavy : colorGrayscale.gray400};
    mask: url(${isActive ? getImageUrl('check.svg') : getImageUrl('minus.svg')}) no-repeat center;
  `
  return (
    <StyledIcon />
  )
}

Icon.propTypes = {
  isActive: PropTypes.bool,
}

const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.$isActive ? colorGrayscale.gray800 : colorGrayscale.gray400};
  gap: 4px;
`

const LinkWithBadge = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const LinkItem = styled(LinkButton)`
  ${props => props.$isActive ? '' : `
    &, &:link, &:visited {
      color: ${colorGrayscale.gray400};
      text-decoration: none !important;
      text-decoration-line: none !important;
      cursor: default;
    }
  `}
`

const Block = styled.div`
  height: ${props => props.$height}px;
`

const Button = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const P1WithBadge = styled.div`
  font-weight: ${fontWeight.normal};
  font-family: ${fontFamily.default};
  line-height: 150%;
  font-size: 16px;
  color: ${props => props.$isActive ? colorGrayscale.gray800 : colorGrayscale.gray400};
`

const CustomBadge = styled.span`
  font-weight: ${fontWeight.normal};
  font-family: ${fontFamily.default};
  line-height: 150%;
  font-size: 14px;
  border-radius: 2px;
  padding: 2px 4px;
  white-space: nowrap;
`

const RedBadge = styled(CustomBadge)`
  margin-left: 8px;
  border: 1px solid ${colorBrand.heavy};
  background-color: ${colorGrayscale.white};
  color: ${colorBrand.heavy};
`

const GrayBadge = styled(CustomBadge)`
  margin-left: 8px;
  background-color: ${colorGrayscale.gray100};
  color: ${colorGrayscale.gray800};
`


const RoleCard = ({ isActive,  roleKey, conditionText, roleTitle, roleKeyText, slogan, buttonText, onButtonClick }) => {
  const [ cardPaddingLeft, setCardPaddingLeft ] = useState(0)
  const [ cardPaddingRight, setCardPaddingRight ] = useState(0)
  const RoleCardContainerRef = useRef(null)

  useEffect(() => {
    const handleResize = _.throttle(() => {
      const offsetWidth = RoleCardContainerRef.current.offsetWidth
      if (offsetWidth < 239) {
        setCardPaddingLeft(16)
        setCardPaddingRight(16)
      } else {
        setCardPaddingLeft(24)
        setCardPaddingRight(24)
      }
    }, 150)
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  

  return (
    <RoleCardContainer $isActive={isActive} ref={RoleCardContainerRef}>
      <TopSection $roleKey={roleKey} $paddingLeft={cardPaddingLeft} $paddingRight={cardPaddingRight}>
        <ConditionText $roleKey={roleKey} weight={P1.Weight.BOLD} text={conditionText}/>
        <RoleTextContainer>
          <RoleTitle $roleKey={roleKey} text={roleTitle} />
          <RoleKey $roleKey={roleKey} weight={P2.Weight.BOLD} text={roleKeyText} />
        </RoleTextContainer>
        <Slogan $roleKey={roleKey} text={slogan} />
      </TopSection>
      <BottomSection $paddingLeft={cardPaddingLeft} $paddingRight={cardPaddingRight}>
        <ListsContainer>
          <ListTitle $isActive>
            <Icon isActive />
            <P1 text={'免費閱讀所有報導'} />
          </ListTitle>
          <Block $height={16} />
          <ListTitle $isActive>
            <Icon isActive />
            <P1 text={'網站進階閱讀功能'} />
          </ListTitle>
          <Block $height={16} />
          <ListTitle $isActive>
            <Icon isActive />
            <P1 text={'報導者電子報'} />
          </ListTitle>
          <Block $height={16} />
          <ListTitle $isActive={roleKey !== MEMBER_ROLE.explorer}>
            <Icon isActive={roleKey !== MEMBER_ROLE.explorer} />
            <P1 text={'基金會工作報告'} />
          </ListTitle>
          <Block $height={16} />
          <ListTitle $isActive={roleKey !== MEMBER_ROLE.explorer}>
            <Icon isActive={roleKey !== MEMBER_ROLE.explorer} />
            <P1WithBadge $isActive={roleKey !== MEMBER_ROLE.explorer}>
              報導者付費講座優惠
              {roleKey === MEMBER_ROLE.action_taker && <GrayBadge>5 折</GrayBadge>}
              {roleKey === MEMBER_ROLE.trailblazer && <RedBadge>免費</RedBadge>}
            </P1WithBadge>
          </ListTitle>
          <Block $height={16} />
          <ListTitle $isActive={roleKey !== MEMBER_ROLE.explorer}>
            <Icon isActive={roleKey !== MEMBER_ROLE.explorer} />
            <P1WithBadge $isActive={roleKey !== MEMBER_ROLE.explorer}>
              報導者新書優惠
              {roleKey === MEMBER_ROLE.action_taker && <GrayBadge>75 折</GrayBadge>}
              {roleKey === MEMBER_ROLE.trailblazer && <RedBadge>7 折</RedBadge>}
            </P1WithBadge>
          </ListTitle>
          <Block $height={16} />
          <ListTitle $isActive={roleKey !== MEMBER_ROLE.explorer}>
            <Icon isActive={roleKey !== MEMBER_ROLE.explorer} />
            <LinkWithBadge>
              <LinkItem $isActive={roleKey !== MEMBER_ROLE.explorer} text={'報導者周邊優惠'} type={LinkButton.Type.UNDERLINE} textComponent={P1} link={{ to: 'https://twreporter.waca.ec', target: '_blank' }} />
              {roleKey === MEMBER_ROLE.action_taker && <GrayBadge>9 折</GrayBadge>}
              {roleKey === MEMBER_ROLE.trailblazer && <RedBadge>85 折</RedBadge>}
            </LinkWithBadge>
          </ListTitle>
          <Block $height={16} />
          <ListTitle $isActive={roleKey === MEMBER_ROLE.trailblazer}>
            <Icon isActive={roleKey === MEMBER_ROLE.trailblazer} />
            <P1 text={'國家兩廳院「廳院人」會籍'} />
          </ListTitle>
          <Block $height={16} />
          <ListTitle $isActive={roleKey === MEMBER_ROLE.trailblazer}>
            <Icon isActive={roleKey === MEMBER_ROLE.trailblazer} />
            <P1 text={'藝文活動贈票抽獎'} />
          </ListTitle>
          <Block $height={16} />
          <ListTitle>
            <Icon isActive={roleKey === MEMBER_ROLE.trailblazer} />
            <LinkItem $isActive={roleKey === MEMBER_ROLE.trailblazer} text={'獨立書店特約優惠'} type={LinkButton.Type.UNDERLINE} textComponent={P1} link={{ to: 'https://www.twreporter.org/a/bookstore-partnership', target: '_blank' }} />
          </ListTitle>
          <Block $height={32} />
        </ListsContainer>
        <Button>
          <PillButton type={PillButton.Type.SECONDARY} style={PillButton.Style.DARK}  text={buttonText} onClick={onButtonClick} />
        </Button>
      </BottomSection>
    </RoleCardContainer>
  )
}

RoleCard.propTypes = {
  isActive: PropTypes.bool,
  roleKey: PropTypes.string,
  conditionText: PropTypes.string,
  roleTitle: PropTypes.string,
  roleKeyText: PropTypes.string,
  slogan: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
}

export default RoleCard
