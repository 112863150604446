import PropTypes from 'prop-types'
import supportConsts from './support'
import statusCodeConsts from './status-code'
import values from 'lodash/values'

const _ = {
  values,
}

const statusCode = PropTypes.oneOf(_.values(statusCodeConsts))

const location = PropTypes.shape({
  key: PropTypes.string,
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  state: PropTypes.object,
})

const history = PropTypes.shape({
  location,
  push: PropTypes.func,
  replace: PropTypes.func,
  go: PropTypes.func,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  canGo: PropTypes.func,
})

const match = PropTypes.shape({
  params: PropTypes.object.isRequired,
})

const contributeFrequency = PropTypes.oneOf([
  supportConsts.contribute.frequency.yearly,
  supportConsts.contribute.frequency.monthly,
  supportConsts.contribute.frequency.oneTime,
])

const userData = PropTypes.shape({
  jwt: PropTypes.string,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
})

const signIn = PropTypes.shape({
  type: PropTypes.string,
  url: PropTypes.string,
  data: PropTypes.object,
  payload: PropTypes.object,
  message: PropTypes.string,
})

export default {
  location,
  history,
  match,
  contributeFrequency,
  userData,
  statusCode,
  signIn,
  contribute: {
    payMethod: PropTypes.oneOf([
      supportConsts.contribute.payMethod.creditCard,
      supportConsts.contribute.payMethod.line,
    ]),
    sendReceipt: PropTypes.oneOf([
      supportConsts.contribute.sendReceipt.no,
      supportConsts.contribute.sendReceipt.yearly,
      supportConsts.contribute.sendReceipt.monthly,
      supportConsts.contribute.sendReceipt.digital,
    ]),
  },
}
