/* global TPDirect */
import React from 'react'
import tappayConf from '../../../../constants/tappay'

class LinePay extends React.PureComponent { 
  constructor(props) {
    super(props)
    this.getPrime = this.getPrime.bind(this)
    this.redirect = this.redirect.bind(this)
  }

  componentDidMount() {
    if (window.TPDirect) {
      TPDirect.setupSDK(tappayConf.appID, tappayConf.appKey, tappayConf.serverType)
    }
  }

  getPrime() {
    return new Promise((resolve, reject) => {
      if (window.TPDirect) {
        return TPDirect.linePay.getPrime((result) => {
          if (result.status !== 0) {
            return reject(result.msg)
          }
          return resolve(result.prime)
        })
      }
      return reject('cannot get prime, TPDirect is not initialized')
    })
  }

  redirect(url) {
    if (typeof window !== 'undefined' && window.TPDirect) {
      TPDirect.redirect(url)
    }
  }

  render() {
    return null
  }
}

export default LinePay 
