import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TagManger from 'react-gtm-module'
// constant
import siteMap from '../../../constants/site-map'
// components
import ConfirmEmail from './confirm-email'
import Auth from '../../auth'

const Container = styled.div`
  max-width: 400px;
  padding: 0 24px;
  margin: auto;
`

const Authenticate = ({
  email,
  destination = siteMap.main.url,
  goToNextStep,
}) => {
  useEffect(() => {
    TagManger.dataLayer({
      dataLayer: {
        event: 'begin_checkout',
      },
    })
  }, [])
  const [ showConfirmEmail, setShowConfirmEmail ] = useState(!!email)
  if (showConfirmEmail) {
    const onSwitchMethod = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setShowConfirmEmail(false)
    }
    return (
      <Container>
        <ConfirmEmail email={email} onConfirm={goToNextStep} onSwitchMethod={onSwitchMethod} />
      </Container>
    )
  }

  const dashboardProps = {
    title: '驗證您的身分',
    subtitle: [ '我們將使用此 email 寄送通知、建立帳號，建議以常用的 email 驗證。' ],
    withOnboarding: false,
  }
  const emailProps = {
    typeWording: '驗證',
  }
  return (
    <Container>
      <Auth
        destination={destination}
        headerType={Auth.HeaderType.NONE}
        dashboardProps={dashboardProps}
        emailProps={emailProps}
      />
    </Container>
  )
}
Authenticate.propTypes = {
  email: PropTypes.string.isRequired,
  destination: PropTypes.string,
  goToNextStep: PropTypes.func.isRequired,
}

export default Authenticate
