import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// context
import { AuthContext } from '../../contexts'
// constant
import siteMap from '../../constants/site-map'
// components
import Dashboard from './dashboard'
import Email from './email'
import Otp from './otp'
import SimpleHeader from '../../components/simple-header'
import { BannedErrorPage } from './banned-page'
// @twreporter
import zIndex from '@twreporter/core/lib/constants/z-index'
import mq from '@twreporter/core/lib/utils/media-query'
import { BRANCH } from '@twreporter/core/lib/constants/release-branch'
import { Header } from '@twreporter/universal-header/lib/index'
// lodash
import get from 'lodash/get'
const _ = {
  get,
}

const HeaderBox = styled.div`
  position: sticky;
  top: 0;
  z-index: ${zIndex.header};
`

const ContentBox = styled.div`
  width: 280px;
  margin: auto;
  ${props => props.$showPadding ?`
    padding: 96px 0;
    ${mq.tabletOnly`
      padding: 120px 0;
    `}
    ${mq.mobileOnly`
      padding: 64px 120px;
    `}
  ` : ''}
  ${props => props.$verticleCenter ?`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  ` : ''}
`

const BannedErrorPageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 72px;
  padding-bottom: 120px;
`

const Page = Object.freeze({
  DASHBOARD: 'dashboard',
  EMAIL: 'email',
  OTP: 'otp',
})
const HeaderType = Object.freeze({
  UNIVERSAL: 'universal',
  SIMPLE: 'simple',
  NONE: 'none',
})
const Auth = ({
  onboardingUrl = '',
  destination = siteMap.main.url,
  withOnboarding = false,
  dashboardProps = {},
  emailProps = {},
  headerType = HeaderType.UNIVERSAL,
}) => {
  const [ page, setPage ] = useState(Page.DASHBOARD)
  const [ signInEmail, setSignInEmail ] = useState('')
  const [ isBanned, setIsBanned ] = useState(false)
  const releaseBranch = useSelector((state) => _.get(state, 'globalEnv.releaseBranch', BRANCH.master))
  const contextValue = { destination, releaseBranch, withOnboarding, setIsBanned, onboardingUrl }
  const useUniversalHeader = (page === Page.DASHBOARD) && (headerType === HeaderType.UNIVERSAL)
  const useSimpleHeader = (headerType !== HeaderType.NONE) && !useUniversalHeader
  const getHeaderJSX = () => {
    if (headerType === HeaderType.NONE) {
      return null
    }
    if (useUniversalHeader) {
      return (
        <HeaderBox>
          <Header releaseBranch={releaseBranch} isLinkExternal={true} />
        </HeaderBox>
      )
    }
    return <SimpleHeader />
  }
  const headerJSX = getHeaderJSX()
  const prepareOtpPage = (email) => {
    setSignInEmail(email)
    setPage(Page.OTP)
  }
  let contentJSX
  if (page === Page.DASHBOARD) {
    contentJSX = (
      <Dashboard
        showSignInByEmailPage={() => setPage(Page.EMAIL)}
        {...dashboardProps}
      />
    )
  } else if (page === Page.EMAIL) {
    contentJSX = (
      <Email
        backToDashboard={() => setPage(Page.DASHBOARD)}
        onSuccess={prepareOtpPage}
        {...emailProps}
      />
    )
  } else if (page === Page.OTP) {
    contentJSX = (
      <Otp
        email={signInEmail}
      />
    )
  }

  if (isBanned) {
    return (
      <div>
        {headerJSX}
        <BannedErrorPageContainer>
          <BannedErrorPage />
        </BannedErrorPageContainer>
      </div>
    )
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {headerJSX}
      <ContentBox $showPadding={useUniversalHeader} $verticleCenter={useSimpleHeader}>
        {contentJSX}
      </ContentBox>
    </AuthContext.Provider>
  )
}
Auth.propTypes = {
  destination: PropTypes.string,
  headerType: PropTypes.oneOf(Object.values(HeaderType)),
  withOnboarding: PropTypes.bool,
}
Auth.HeaderType = HeaderType

export default Auth
